import Api from '@/services/Api';

export default {
  getAllDeviceMeasurements(params) {
    return Api().get('/api/device-measurements', { params });
  },
  getAllDeviceTypes() {
    return Api().get('/api/device-types', {});
  },
};
