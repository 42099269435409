import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import AlarmService from '@/services/Vainu/AlarmService.js';
import FeedbackService from '@/services/Tieto/FeedbackService.js';
Vue.use(Vuex);
/**
 * Vuex store
 */
const getDefaultState = () => {
  return {
    token: null,
    user: null,
    isAuthenticated: false,
    discussionId: null,
    discussionCommentId: null,
    queryId: null,
    qrCode: '',
    activeAlarms: { error: null, data: [] },
    userActiveTarget: null,
    isPasswordChange: true,
    feedbackMessages: { errro: null, data: [] },
  };
};
export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getDiscussionId: (state) => {
      return state.discussionId;
    },
    getDiscussionCommentId: (state) => {
      return state.discussionCommentId;
    },
    getToken: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    getQueryId: (state) => {
      return state.queryId;
    },
    getQrCode: (state) => {
      return state.qrCode;
    },
    activeAlarms(state) {
      return state.activeAlarms;
    },
    getUserActiveTarget(state) {
      return state.userActiveTarget;
    },
    getFeedbackMessages(state) {
      return state.feedbackMessages;
    },
  },
  mutations: {
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    setQrCode(state, qrCode) {
      state.qrCode = qrCode;
    },
    setDiscussionId(state, discussionId) {
      state.discussionId = discussionId;
    },
    setDiscussionCommentId(state, discussionCommentId) {
      state.discussionCommentId = discussionCommentId;
    },
    setQueryId(state, queryId) {
      state.queryId = queryId;
    },
    setActiveAlarms(state, { data, error }) {
      state.activeAlarms = { data, error };
    },
    setUserActiveTarget(state, userActiveTarget) {
      state.userActiveTarget = userActiveTarget;
    },
    setFeedbackMessages(state, { data, error }) {
      state.feedbackMessages = { data, error };
    },
  },
  actions: {
    logout: ({ commit }) => {
      commit('RESET', '');
    },

    setToken({ commit }, token) {
      commit('setToken', token);
    },

    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setQrCode({ commit }, qrCode) {
      commit('setQrCode', qrCode);
    },
    setDiscussionId({ commit }, discussionId) {
      commit('setDiscussionId', discussionId);
    },
    setDiscussionCommentId({ commit }, discussionCommentId) {
      commit('setDiscussionCommentId', discussionCommentId);
    },
    setQueryId({ commit }, queryId) {
      commit('setQueryId', queryId);
    },
    setUserActiveTarget({ commit }, userActiveTarget) {
      commit('setUserActiveTarget', userActiveTarget);
    },
    async fetchActiveAlarms({ commit }) {
      try {
        const res = await AlarmService.getActiveAlarms();
        commit('setActiveAlarms', { data: res.data.alarms });
      } catch (err) {
        console.error(err);
        commit('setActiveAlarms', { data: [], error: true });
      }
    },

    async showFeedbackMessages({ commit }) {
      try {
        const feedbacks = await FeedbackService.getAllNewFeedbacks({
          feedbackOrganizationId: this.state.user.Organization.organizationId,
          feedbackTargetId: this.state.userActiveTarget,
        });
        commit('setFeedbackMessages', { data: feedbacks.data.feedbacks });
      } catch (error) {
        commit('setFeedbackMessages', { data: [], error: true });
      }
    },
  },
});
