<!-- Show users data-table -->
<template>
  <v-data-table
    :headers="headers"
    :items="measurements"
    sort-by="measurements"
    class="elevation-1"
  >
    <!-- Show success/error messages -->
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Mittaukset</v-toolbar-title>

        <v-spacer/>
        <!-- Edit measurement modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editMeasurements.measurementName"
                      :label="$t('showMeasurements.measurementName')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editMeasurements.measurementType"
                      :label="$t('showMeasurements.measurementType')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editMeasurements.measurementSizeInBytes"
                      :label="$t('showMeasurements.measurementSizeInBytes')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editMeasurements.measurementBitNumber"
                      :label="$t('showMeasurements.measurementBitNumber')"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer/>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import MeasurementService from '@/services/Vainu/MeasurementService.js';

export default {
  data() {
    return {
      measurements: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: this.$t('showMeasurements.measurementTitle'),
      headers: [
        {
          text: this.$t('showMeasurements.measurementName'),
          value: 'measurementName',
        },
        {
          text: this.$t('showMeasurements.measurementType'),
          value: 'measurementType',
        },
        {
          text: this.$t('showMeasurements.measurementSizeInBytes'),
          value: 'measurementSizeInBytes',
        },
        {
          text: this.$t('showMeasurements.measurementBitNumber'),
          value: 'measurementBitNumber',
        },
      ],

      editMeasurements: {
        measurementName: '',
        measurementType: '',
        measurementSizeInBytes: '',
        measurementBitNumber: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show measurements when page is loaded
   */
  async created() {
    const response = await MeasurementService.getAllMeasurements();
    console.log(response.data);
    this.measurements = response.data.measurements;
  },

  methods: {
    // Add items to editMeasurement when pencil icon is clicked
    editItem(item) {
      this.editMeasurements = Object.assign({}, item);
      this.dialog = true;
    },
    // close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editMeasurements = Object.assign({}, this.measurements);
      });
    },
    /**
     * Save button is clicked from modal
     */
    async save() {
      try {
        const response = await MeasurementService.saveMeasurementModification(
          this.editMeasurements
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const measurements = await MeasurementService.getAllMeasurements();
        this.measurements = measurements.data.measurements;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
