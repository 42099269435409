import Api from '@/services/Api';
//import store from '@/store';
//User api cals
export default {
  getDiscussionByDiscussionId(params) {
    return Api().get('/api/tieto/discussion/id', {
      params,
    });
  },
  getAllDiscussions(params) {
    return Api().get('/api/tieto/discussions/', { params });
  },
  getAllDiscussionsByTargets(params) {
    return Api().get('/api/tieto/discussions-by-targets/', { params });
  },
  getAllDiscussionsByOrganization(params) {
    return Api().get('/api/tieto/discussions/organization', { params });
  },
  getAllDiscussionsByOrganizationDashboard(params) {
    return Api().get('/api/tieto/discussions/organization-dashboard', {
      params,
    });
  },
  saveDiscussionModification(credintials) {
    return Api().patch('/api/tieto/discussion', credintials, {});
  },
  addNewDiscussion(credintials) {
    return Api().post('/api/tieto/discussion/', credintials, {});
  },
  getQueryByCustomerId(credintials) {
    return Api().post('/api/tieto/query-customer-id/', credintials, {});
  },
};
