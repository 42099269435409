<!-- Top and sidebar template -->
<template>
  <nav>
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      max-heigth="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Tietoa sivustosta
        </v-card-title>
        <v-card-text>
          <embed
            src="pdf/info.pdf"
            frameborder="0"
            width="100%"
            height="600px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeModal">Sulje</v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar color="#45a1e6" app class="white--text">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        large
        class="white--text"
      />
      <!-- <h3>
        {{ `${user.Organization.organizationName}` }}
      </h3> -->

      <v-spacer />
      <v-tab
        @click="showMessages"
        v-if="
          user.userRoles === 'Pääylläpitäjä' || user.userRoles === 'Pääkäyttäjä'
        "
      >
        Viestit

        <v-badge color="red" :content="getMessages" v-if="getMessages" />
      </v-tab>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"/></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text color="#95e600" @click="showInfo">
        <v-icon large> mdi-information-outline</v-icon>
      </v-btn>

      <v-btn text @click="logout">
        <v-icon class="white--text " large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer
      v-model="drawer"
      app
      color="#45a1e6"
      width="300px"
      dark
    >
      <v-layout column>
        <v-flex class="mt-5">
          <p class="white--text subheading   text-center">
            {{
              `${user &&
                user.Organization &&
                user.Organization.organizationName}`
            }}
          </p>
          <p class="white--text subheading   text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-1 mb-2">
          <v-divider color="white" />
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <v-list-item to="/tieto/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Etusivu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/tieto/discussions">
          <v-list-item-icon>
            <v-icon>mdi-voice</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Keskustelut</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/tieto/show-survey-form">
          <v-list-item-icon>
            <v-icon>mdi-account-question</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kyselyt</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/tieto/show-materialbank-files">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Materiaalipankki</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/tieto/add-feedback">
          <v-list-item-icon>
            <v-icon>mdi-message-alert-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Yhteydenotto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          color="white"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä' ||
              user.userRoles === 'Pääkäyttäjä'
          "
        />
        <!--Home link end -->
        <!--User group -->
        <v-list-group
          :value="false"
          prepend-icon="mdi-voice"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä' ||
              user.userRoles === 'Pääkäyttäjä'
          "
        >
          <template v-slot:activator>
            <v-list-item-title>Keskustelut</v-list-item-title>
          </template>
          <v-list-item
            v-for="([title, icon, route], i) in discussions"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          prepend-icon="mdi-help"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä' ||
              user.userRoles === 'Pääkäyttäjä'
          "
        >
          <template v-slot:activator>
            <v-list-item-title>Kysymykset</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in questionLinks"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--User group end -->
        <!--Targets group -->

        <v-list-group
          :value="false"
          prepend-icon="mdi-account-question"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä' ||
              user.userRoles === 'Pääkäyttäjä'
          "
        >
          <template v-slot:activator>
            <v-list-item-title>Kyselyt</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in queries"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          to="/tieto/show-feedbacks"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä' ||
              user.userRoles === 'Pääkäyttäjä'
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-message-alert-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Yhteydenotot</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--queries group end -->

      <template v-slot:append>
        <v-divider color="white" />
        <v-list class="bottom">
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sovellukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/vainu/dashboard"
            v-if="user.userPermissions.includes('2')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vainu</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/materiaalipankki/dashboard"
            v-if="user.userPermissions.includes('3')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Materiaalipankki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/admin/dashboard"
            v-if="user.userPermissions.includes('4')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hallinta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
import FeedbackService from '@/services/Tieto/FeedbackService';
import TargetService from '@/services/Tieto/TargetService';
export default {
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    feedbacks: [],
    infoDialog: false,
    userTargets: '',
    //editUserDialog: false,
    targets: [],

    users: [
      ['Vaihda salasana', 'mdi-lock', '/tieto/change-password'],
      ['Muokkaa tietoja', 'mdi-account', '/tieto/show-user'],
    ],
    queries: [
      ['Lisää uusi kysely', '', '/tieto/add-new-query'],
      ['Näytä kyselyt', '', '/tieto/show-queries'],
    ],

    questionLinks: [
      ['Lisää uusi kysymysryhmä', '', '/tieto/add-new-question-group'],
      ['Näytä kysymysryhmät', '', '/tieto/show-question-groups'],
      ['Lisää uusi kysymys', '', '/tieto/add-new-question'],
      ['Näytä kysymykset', '', '/tieto/show-questions'],
    ],
    materialBank: [
      ['Lisää uusi tiedosto', '', '/tieto/add-materialbank-file'],
      ['Näytä tiedostot', '', '/tieto/show-materialbank-files'],
    ],
    discussions: [
      ['Lisää uusi keskustelu', '', '/tieto/add-discussion'],
      ['Näytä keskustelut', '', '/tieto/show-discussions'],
    ],
  }),
  computed: {
    getMessages() {
      let message = '';

      if (this.$store.getters.getFeedbackMessages.data.length > 0) {
        message = this.$store.getters.getFeedbackMessages.data.length;
        return message;
      } else {
        return (message = 0);
      }
    },
    showTargetName() {
      let url = window.location.href;
      let targets = url.includes('/tieto/discussions');

      if (targets) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  //When page is loaded
  created() {
    this.user = this.$store.getters.getUser;
    setInterval(() => {
      this.$store.dispatch('showFeedbackMessages');
    }, 60000);
  },
  async mounted() {
    try {
      const feedbacks = await FeedbackService.getAllNewFeedbacks({
        feedbackOrganizationId: this.user.Organization.organizationId,
        feedbackTargetId: this.$store.getters.getUserActiveTarget,
      });
      this.feedbacks = feedbacks.data.feedbacks;
    } catch (error) {
      this.error = error;
    }

    try {
      const targets = await TargetService.getAllUserTargets({
        userId: this.user.uuid,
      });

      this.targets = targets.data.targets;
      await this.$store.dispatch(
        'setUserActiveTarget',
        this.targets[0].targetId
      );
      this.userTargets = this.$store.getters.getUserActiveTarget;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    // Close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.users);
      });
    },
    async changeTargets() {
      await this.$store.dispatch('setUserActiveTarget', this.userTargets);
    },

    showInfo() {
      this.infoDialog = true;
    },
    closeModal() {
      this.infoDialog = false;
    },
    // closeUserModal() {
    //   this.editUserDialog = false;
    // },
    showMessages() {
      this.$router.push({
        name: 'show-feedbacks',
      });
    },
    logout() {
      this.$store.dispatch('logout');

      this.$router.push({
        name: 'login',
      });
    },
    save() {
      console.log('talleta');
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
.bottom {
  margin-bottom: 10%;
  width: 100%;
}
</style>
