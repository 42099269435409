<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="750px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Kommentti"
                  prepend-icon="mdi-information-variant"
                  v-model="discussionComment.comment"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close">
            Peruuta
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Talleta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row dense>
      <v-col cols="12">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title class="headline">
                {{ discussion[0].discussionTitle }}
              </v-card-title>

              <v-card-subtitle>{{
                discussion[0].discussionDescription
              }}</v-card-subtitle>
              <v-card-subtitle>{{
                showDateAndTime(discussion[0].createdAt)
              }}</v-card-subtitle>

              <v-card-actions>
                <v-btn @click="addNewDiscussionComment()">
                  Lisää kommentti
                </v-btn>
                <v-btn @click="backToComments()">
                  Takaisin
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-for="(comment, index) in comments" :key="index">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-2 pa-2">
              <v-card-title>
                <v-avatar :color="showColor(comment.userFirstName)" size="40">
                  <span class="white--text ">{{
                    showNameLetters(comment.userFirstName, comment.userLastName)
                  }}</span>
                </v-avatar>
                <span class="subtitle-2 pl-2">{{
                  showDateAndTime(comment.createdAt)
                }}</span>

                <v-spacer />
                <v-card-actions
                  v-if="
                    user.userRoles === 'Pääylläpitäjä' ||
                      user.userRoles === 'Ylläpitäjä' ||
                      user.userRoles === 'Pääkäyttäjä' ||
                      user.uuid === discussion[0].discussionModerator
                  "
                >
                  <v-icon
                    @click="
                      modifyCurrentComment(
                        comment.discussionComment,
                        comment.discussionCommentId
                      )
                    "
                  >
                    mdi-pencil
                  </v-icon>
                </v-card-actions>
              </v-card-title>
              <v-card-text v-if="comment.discussionComment.length <= 500">
                {{ comment.discussionComment }}
              </v-card-text>
              <v-card-text
                v-if="!comment.Flag && comment.discussionComment.length > 500"
              >
                {{ comment.discussionComment | summary }}

                <a @click="toggler(comment, true)">Lue lisää</a>
              </v-card-text>
              <v-card-text v-show="comment.Flag">
                {{ comment.discussionComment }}

                <div class="button">
                  <a @click="toggler(comment, false)">Näytä vähemmän</a>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        showFirtsNameLastName(
                          comment.userFirstName,
                          comment.userLastName
                        )
                      }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <v-btn
                      text
                      color="#45a1e6"
                      @click="showSubComments(comment.discussionCommentId)"
                      v-if="showSubCommentIcon(comment.count)"
                    >
                      Katso kommentit ({{ comment.count }})
                    </v-btn>
                    <v-btn
                      text
                      color="#45a1e6"
                      @click="
                        addNewDiscussionSubComment(comment.discussionCommentId)
                      "
                      v-else
                    >
                      Lisää kommentti
                    </v-btn>

                    <span class="subheading" />
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiscussionService from '@/services/Tieto/DiscussionService';
import DiscussionCommentService from '@/services/Tieto/DiscussionCommentService';
import moment from 'moment';

export default {
  filters: {
    summary: function(text) {
      return text.substring(0, 500) + ' ...';
    },
  },
  data() {
    return {
      comments: [
        {
          discussionId: '',
          discussionCommentId: '',
          discussionCommentSubId: '',
          discussionComment: '',
          createdAt: '',

          User: [
            {
              userLastName: '',
              userFirstName: '',
            },
          ],
        },
      ],
      discussion: [
        {
          id: '',
          discussionDescription: '',
          discussionId: '',
          discussionTitle: '',
          createdAt: '',
          discussionComment: '',
        },
      ],
      item: '',
      discussionComment: {},
      dialog: false,
      user: {},
    };
  },

  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const discussion = await DiscussionService.getDiscussionByDiscussionId({
        discussionId: this.$store.getters.getDiscussionId,
      });

      this.discussion = discussion.data.discussion;
    } catch (error) {
      this.error = error;
    }
    try {
      const discussionComments = await DiscussionCommentService.getDiscussionCommentsByDiscussionId(
        {
          discussionId: this.$store.getters.getDiscussionId,
        }
      );
      this.comments = discussionComments.data.comments;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    toggler(obj, flag) {
      this.$set(obj, 'Flag', flag);
    },
    modifyCurrentComment(comment, id) {
      this.discussionComment.comment = comment;
      this.discussionComment.id = id;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    limitToDisplay(value) {
      if (!value) return '';
      return value.substring(0, 150);
    },

    showAll(value) {
      return value;
    },
    showColor(name) {
      if (name !== '') {
        return 'green';
      }
    },
    showSubCommentIcon(count) {
      if (count) return true;
      return false;
    },
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm');
    },
    showNameLetters(firstName, lastName) {
      if (firstName && lastName) {
        return firstName.substring(0, 1) + lastName.substring(0, 1);
      }
    },
    showFirtsNameLastName(firstName, lastName) {
      return firstName + ' ' + lastName;
    },
    showSubComments(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'tieto/sub-comments',
      });
    },

    addNewDiscussionComment() {
      this.$router.push({
        name: 'tieto/add-new-comment',
        //params: { discussionId: this.discussion.discussionId },
      });
    },
    backToComments() {
      this.$router.push({
        name: 'tieto/main-discussions',
      });
    },
    addNewDiscussionSubComment(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'tieto/add-new-sub-comment',
        // params: { discussionId: this.discussion.discussionId },
      });
    },
    async save() {
      this.dialog = false;
      try {
        const response = await DiscussionCommentService.saveDiscussionCommentModification(
          this.discussionComment
        );
        const discussionComments = await DiscussionCommentService.getDiscussionCommentsByDiscussionId(
          {
            discussionId: this.$store.getters.getDiscussionId,
          }
        );
        this.comments = discussionComments.data.comments;
        this.message = response.data.message;
        this.error = response.error;

        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
.button {
  display: block;
  margin-top: 10px;
}
</style>
