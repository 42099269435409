<!-- Add new materialbank file template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" enctype="multipart/form-data" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left>
                <h4>Lisää uusi dokumentti</h4>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Otsikko"
                  prepend-icon=""
                  :rules="required"
                  v-model="materialbankFile.title"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon=""
                  v-model="materialbankFile.organizationId"
                  :items="materialbankFileOrganizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  label="Organisaatio"
                  placeholder="Valitse..."
                  :rules="required"
                  @change="showTargets"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon=""
                  v-model="materialbankFile.targets"
                  :items="materialbankFileTargets"
                  item-text="targetName"
                  item-value="targetId"
                  label="Kohdeet"
                  placeholder="Valitse..."
                  :rules="option"
                  chips
                  multiple
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon=""
                  v-model="materialbankFile.permissions"
                  :items="materialbankFilePermissions"
                  item-text="permissionName"
                  item-value="permissionId"
                  label="Oikeudet"
                  placeholder="Valitse..."
                  :rules="option"
                  chips
                  multiple
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon=""
                  v-model="materialbankFile.applications"
                  :items="materialbankFileApplications"
                  item-text="applicationName"
                  item-value="applicationId"
                  label="Sovellukset"
                  placeholder="Valitse..."
                  :rules="option"
                  chips
                  multiple
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-file-input
                  label="Valitse tiedosto"
                  v-model="selectedFile"
                  :rules="fileRules"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon=""
                  v-model="materialbankFile.state"
                  :items="states"
                  item-text="name"
                  item-value="value"
                  label="Tila"
                  placeholder="Valitse..."
                  :rules="required"
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-textarea
                  label="Info"
                  prepend-icon=""
                  v-model="materialbankFile.info"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewMaterialbankFile"
                >
                  Lisää tiedosto
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TargetService from '@/services/TargetService.js';
import OrganizationController from '@/services/OrganizationService.js';
import MaterialbankService from '@/services/Materialbank/MaterialbankService.js';
import ApplicationController from '@/services/ApplicationService.js';
export default {
  data() {
    return {
      states: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],

      materialbankFileTargets: [],
      materialbankFileOrganizations: [],
      user: {},
      targetOrganizationId: null,

      materialbankFileApplications: [],
      materialbankFilePermissions: [
        {
          permissionId: 'Käyttäjä',
          permissionName: 'Käyttäjä',
        },
        {
          permissionId: 'Ylläpitäjä',
          permissionName: 'Ylläpitäjä',
        },
        {
          permissionId: 'Pääkäyttäjä',
          permissionName: 'Pääkäyttäjä',
        },

        {
          permissionId: 'Pääylläpitäjä',
          permissionName: 'Pääylläpitäjä',
        },
      ],
      materialbankFile: {},
      selectedFile: null,
      message: null,
      error: null,
      required: [(v) => !!v || 'Pakollinen kenttä'],
      fileRules: [(v) => !!v || 'Tiedosto pitää valita'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    try {
      this.user = this.$store.getters.getUser;
      const response = await OrganizationController.getAllOrganizations();
      this.materialbankFileOrganizations = response.data.organizations;
      const applications = await ApplicationController.getAllApplications();
      this.materialbankFileApplications = applications.data.applications;
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.user.Organization.organizationId,
      });
      this.materialbankFileTargets = targets.data.targets;
    } catch (error) {
      this.error = error.response.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },
  methods: {
    async showTargets() {
      try {
        const targets = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.materialbankFile.organizationId,
        });
        this.materialbankFileTargets = targets.data.targets;
        this.message = targets.data.message;
        this.error = targets.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async addNewMaterialbankFile() {
      if (this.$refs.form.validate()) {
        if (this.user.UserRoles !== 'Pääylläpitäjä') {
          this.materialbankFile.organizationId = this.user.Organization.organizationId;
        }
        let fileExtension = this.selectedFile.name.split('.');
        const formData = new FormData();
        formData.append('file', this.selectedFile, this.selectedFile.name);
        formData.append('title', this.materialbankFile.title);
        formData.append('organizationId', this.materialbankFile.organizationId);
        formData.append('targets', this.materialbankFile.targets);
        formData.append('permissions', this.materialbankFile.permissions);
        formData.append('applications', this.materialbankFile.applications);
        formData.append('fileExtension', fileExtension[1]);
        formData.append('info', this.materialbankFile.info);
        formData.append('state', this.materialbankFile.state);

        try {
          const materialbankFile = await MaterialbankService.addNewMaterialbankFile(
            formData
          );

          this.message = materialbankFile.data.message;
          this.error = materialbankFile.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 30000);
        }
      }
    },
  },
};
</script>
<style scoped></style>
