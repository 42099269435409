<!-- Footer template -->
<template>
  <v-footer padless app inset>
    <v-card class="flex" flat tile color="#45a1e6" :elevation="10">
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} - <strong>ITKonsultit</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
