<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" v-if="tieto">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-2"
              link
              to="tieto/dashboard"
            >
              <v-card-text>
                <div>
                  <h1 class="text-color">TIETO</h1>
                </div>

                <div class="text--primary">
                  <h3>
                    Klikkaa itsesi tietoon, pääset omassa käyttäjäprofiilissasi
                    oleviin keskusteluihin, kyselyihin tai tiedostoihin.
                  </h3>
                </div>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn text class="text-color" to="powerguard">
                  Tieto
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-if="vainu">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-2"
              link
              to="vainu/dashboard"
            >
              <v-card-text>
                <div>
                  <h1 class="text-color">VAINU</h1>
                </div>

                <div class="text--primary">
                  <h3>
                    Klikkaa itsesi vainuun, pääset katsomaan kiinteistö- ja
                    laitetietojasi.
                  </h3>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-if="hallinta">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-2"
              link
              to="admin/dashboard"
            >
              <v-card-text>
                <div>
                  <h1 class="text-color">HALLINTA</h1>
                </div>

                <div class="text--primary">
                  <h3>
                    Klikkaa itsesi hallinnoimaan käyttäjiä, organisaatioita ja
                    tietoja.
                  </h3>
                </div>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn text class="text-colors" to="Ylläpitäjä">
                  Hallinta
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-if="materiaalipankki">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 24 : 6"
              class="mb-4 pa-2"
              link
              to="materiaalipankki/dashboard"
            >
              <v-card-text>
                <div>
                  <h1 class="text-color">MATERIAALIPANKKI</h1>
                </div>

                <div class="text--primary">
                  <h3>
                    Klikkaa itsesi lisäämään materiaaleja
                  </h3>
                </div>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn text class="text-colors" to="Ylläpitäjä">
                  Hallinta
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//import AuthService from '@/services/AuthenticationService';
export default {
  data() {
    return {
      tieto: false,
      vainu: false,
      hallinta: false,
      materiaalipankki: false,
    };
  },
  created() {
    this.checkUserPermissions(this.$store.state.user.userPermissions);
  },
  methods: {
    checkUserPermissions(permissions) {
      let permissionsTable = permissions.split(',');
      if (permissionsTable.length === 1) {
        switch (permissionsTable[0]) {
          case '1': {
            this.$router.push('/tieto/dashboard');
            break;
          }
          case '2': {
            this.$router.push('/vainu/dashboard');
            break;
          }
          case '3': {
            this.$router.push('/materiaalipankki/dashboard');
            break;
          }
          case '4': {
            this.$router.push('/admin/dashboard');
            break;
          }
        }
      } else {
        for (let i = 0; i < permissionsTable.length; i++) {
          switch (permissionsTable[i]) {
            case '1': {
              this.tieto = true;
              break;
            }
            case '2': {
              this.vainu = true;
              break;
            }
            case '3': {
              this.materiaalipankki = true;
              break;
            }
            case '4': {
              this.hallinta = true;
              break;
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.text-color {
  color: #95e600;
}
</style>
