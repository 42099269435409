<!-- Register template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12>
                <h2>{{ $t('register.title') }}</h2>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('register.email')"
                  prepend-icon="mdi-email"
                  :rules="emailRules"
                  v-model="registerValues.userEmail"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.password')"
                  prepend-icon="mdi-lock"
                  :rules="passwordRules"
                  v-model="registerValues.userPassword"
                  autocomplete="off"
                  :type="passwordShow ? 'text' : 'password'"
                  @click:append="passwordShow = !passwordShow"
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.password_repeat')"
                  prepend-icon="mdi-lock"
                  :rules="confirmPasswordRules"
                  v-model="userPasswordRepeat"
                  autocomplete="off"
                  @click:append="reapeatPasswordShow = !reapeatPasswordShow"
                  :append-icon="reapeatPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="reapeatPasswordShow ? 'text' : 'password'"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.firstName')"
                  prepend-icon="mdi-account"
                  :rules="required"
                  v-model="registerValues.userFirstName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.lastName')"
                  prepend-icon="mdi-account"
                  :rules="required"
                  v-model="registerValues.userLastName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.phoneNumber')"
                  prepend-icon="mdi-cellphone "
                  :rules="required"
                  v-model="registerValues.userPhoneNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('register.jobTitle')"
                  prepend-icon="mdi-account-tie "
                  :rules="required"
                  v-model="registerValues.userJobTitle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="registerValues.userOrganization"
                  :items="organizations"
                  :label="$t('register.organization')"
                  placeholder="Valitse..."
                  item-text="organizationName"
                  item-value="organizationId"
                  :rules="required"
                  @change="showApplications"
                />
              </v-flex>
              <!-- <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-home-city"
                  v-model="registerValues.userTargets"
                  :items="targets"
                  :label="$t('register.targets')"
                  placeholder="Valitse..."
                  item-text="targetName"
                  item-value="targetId"
                  :rules="option"
                  @change="showTargets"
                  multiple
                  no-data-text="Tietoa ei saatavilla"
                />
              </v-flex> -->
              <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="registerValues.userRoles"
                  :items="rolesAdmin"
                  :label="$t('register.roles')"
                  placeholder="Valitse..."
                  item-text="name"
                  item-value="value"
                  :rules="required"
                />
              </v-flex>
              <v-flex xs12 md6 v-if="user.userRoles !== 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="registerValues.userRoles"
                  :items="roles"
                  :label="$t('register.roles')"
                  placeholder="Valitse..."
                  item-text="name"
                  item-value="value"
                  :rules="required"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="registerValues.userState"
                  :items="userState"
                  item-text="name"
                  item-value="value"
                  :label="$t('register.state')"
                  placeholder="Valitse..."
                  :rules="required"
                  chips
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="registerValues.userPermissions"
                  :items="applications"
                  item-text="Application.applicationName"
                  item-value="Application.applicationId"
                  :label="$t('register.userPermissions')"
                  placeholder="Valitse..."
                  required
                  multiple
                  chips
                  :rules="option"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('register.description')"
                  prepend-icon="mdi-information-variant"
                  v-model="registerValues.userDescription"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('register.information')"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="registerValues.userInformation"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="register"
                >
                  {{ $t('register.register-button') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
import OrganizationService from '@/services/OrganizationService';
//import TargetService from '@/services/TargetService';
import ApplicationService from '@/services/ApplicationService.js';
export default {
  data() {
    return {
      rolesAdmin: [
        { name: this.$t('register.rolesUser'), value: 'Käyttäjä' },
        { name: this.$t('register.rolesMainUser'), value: 'Pääkäyttäjä' },
        { name: this.$t('register.rolesAdministrator'), value: 'Ylläpitäjä' },
        {
          name: this.$t('register.rolesMainAdministrator'),
          value: 'Pääylläpitäjä',
        },
      ],
      roles: [
        { name: this.$t('register.rolesUser'), value: 'Käyttäjä' },
        { name: this.$t('register.rolesMainUser'), value: 'Pääkäyttäjä' },
        { name: this.$t('register.rolesAdministrator'), value: 'Ylläpitäjä' },
      ],
      userState: [
        { name: this.$t('register.state-active'), value: 'Aktiivinen' },
        { name: this.$t('register.state-passive'), value: 'Passiivinen' },
      ],
      userPermissions: [],

      registerValues: {},
      applications: [],
      userPasswordRepeat: '',
      message: null,
      organizations: [],
      organizationId: '',
      targets: [],
      user: {},
      passwordShow: false,
      reapeatPasswordShow: false,
      error: null,
      emailRules: [
        (v) => !!v || 'Sähköposti on pakollinen tieto',
        (v) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(v) || 'Sähköposti ei ole kelvollinen';
        },
      ],
      passwordRules: [
        (v) => !!v || 'Salasana on pakollinen',
        (v) => (v && v.length >= 8) || 'Salasanan oltava vähintään 8 merkkiä',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Salasana on pakollinen',
        (v) =>
          v === this.registerValues.userPassword || 'Salasanat eivät täsmää',
      ],

      required: [(v) => !!v || 'Pakollinen kenttä'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const response = await OrganizationService.getAllOrganizations();
      this.organizations = response.data.organizations;
      // this.message = response.data.message;
      this.error = response.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
    try {
      const applications = await ApplicationService.getAllOrganizationApplications(
        {
          organizationId: this.user.Organization.organizationId,
        }
      );
      this.applications = applications.data.applications;
      this.error = applications.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },

  methods: {
    async showApplications() {
      try {
        const applications = await ApplicationService.getAllOrganizationApplications(
          {
            organizationId: this.registerValues.userOrganization,
          }
        );
        this.applications = applications.data.applications;
        this.error = applications.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async register() {
      if (this.$refs.form.validate()) {
        try {
          if (
            this.user.userRoles === 'Ylläpitäjä' ||
            this.user.userRoles === 'Pääkäyttäjä'
          ) {
            this.registerValues.userOrganization = this.user.Organization.organizationId;
          }
          if (this.registerValues.userPermissions) {
            let permissions = '';
            let permissionString = '';
            for (
              let i = 0;
              i < this.registerValues.userPermissions.length;
              i++
            ) {
              permissions += this.registerValues.userPermissions[i] + ',';
            }
            permissionString = permissions.slice(0, -1);
            this.registerValues.userPermissions = permissionString;
          }
          const response = await AuthenticationService.register(
            this.registerValues
          );
          //    this.registerValues = {};
          this.message = response.data.message;
          this.error = response.data.error;
          if (this.message) {
            this.$refs.form.reset();
          }
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
