import Api from '@/services/Api';

//User api cals
export default {
  getAllQueries() {
    return Api().get('/api/tieto/queries/', {});
  },
  saveQueryModification(credintials) {
    return Api().patch('/api/tieto/query', credintials, {});
  },
  addNewQuery(credintials) {
    return Api().post('/api/tieto/query/', credintials, {});
  },
  getAllQueriesByCustomerId(params) {
    return Api().get('/api/tieto/query-customer-id/', { params });
  },
  getQueryQuestions(params) {
    return Api().get('/api/tieto/query-questions/', { params });
  },
  getAllQueriesByOrganization(params) {
    return Api().get('/api/tieto/all-queries-by-organization/', { params });
  },
  getAllQueriesByTargets(params) {
    return Api().get('/api/tieto/all-queries-by-targets/', { params });
  },
};
