<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää uusi kysymys</h4></v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kysymys"
                  prepend-icon="mdi-information-variant"
                  v-model="addQuestionValues.questionDescription"
                  autocomplete="off"
                  :rules="questionRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQuestionValues.questionGroup"
                  :items="questionGroups"
                  item-text="queryQuestionGroupName"
                  item-value="id"
                  label="Kysymys ryhmä"
                  placeholder="Valitse"
                  :rules="questionGroupRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQuestionValues.questionState"
                  :items="questionState"
                  item-text="name"
                  item-value="value"
                  label="Kysymyksen tila"
                  placeholder="Valitse"
                  :rules="questionStateRules"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewQuestion"
                >
                  Lisää uusi kysymys
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QuestionService from '@/services/Tieto/QuestionService.js';
import QuestionGroupService from '@/services/Tieto/QuestionGroupService.js';
export default {
  data() {
    return {
      questionState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],
      addQuestionValues: {},
      questionGroups: [],
      message: null,
      error: null,
      questionRules: [
        (v) => !!v || 'Kysymys on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Kysymyksen pitää olla ainakin 3 merkkiä',
      ],
      questionGroupRules: [(v) => !!v || 'Ryhmä pitää valita'],
      questionStateRules: [(v) => !!v || 'Tila pitää valita'],
    };
  },
  async created() {
    try {
      const response = await QuestionGroupService.getAllQuestionGroups();
      this.questionGroups = response.data.groups;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async addNewQuestion() {
      if (this.$refs.form.validate()) {
        try {
          const response = await QuestionService.addNewQuestion(
            this.addQuestionValues
          );
          this.addQuestionValues = {};
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
