<template>
  <v-dialog v-model="dialog" max-width="750px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userEmail"
                :label="$t('editUser.userEmail')"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userFirstName"
                :label="$t('editUser.userFirstName')"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userLastName"
                :label="$t('editUser.userLastName')"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userPhoneNumber"
                :label="$t('editUser.userPhoneNumber')"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userJobTitle"
                :label="$t('editUser.userJobTitle')"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                prepend-icon="mdi-account-supervisor"
                v-model="editedItem.userOrganization"
                :items="users"
                :label="$t('editUser.userOrganization')"
                placeholder="Valitse..."
                item-text="Organization.organizationName"
                item-value="Organization.organizationId"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                prepend-icon="mdi-account-supervisor"
                v-model="editedItem.userRoles"
                :items="roles"
                :label="$t('editUser.userRoles')"
                placeholder="Valitse..."
                item-text="name"
                item-value="value"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                prepend-icon="mdi-account-supervisor"
                v-model="editedItem.userPermissions"
                :items="permissions"
                :label="$t('editUser.userPermissions')"
                placeholder="Valitse..."
                item-text="name"
                item-value="value"
                chips
                multiple
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                prepend-icon="mdi-account-supervisor"
                v-model="editedItem.userState"
                :items="states"
                :label="$t('editUser.userState')"
                placeholder="Valitse..."
                item-text="name"
                item-value="value"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="editedItem.userInformation"
                :label="$t('editUser.userInformation')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                :label="$t('editUser.userDescription')"
                v-model="editedItem.userDescription"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">
          Sulje
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Talleta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UserService from '@/services/UserService.js';

export default {
  data() {
    return {
      users: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: 'Muokkaa',
      headers: [
        { text: this.$t('showUsers.userEmail'), value: 'userEmail' },
        { text: this.$t('showUsers.userFirstName'), value: 'userFirstName' },
        { text: this.$t('showUsers.userLastName'), value: 'userLastName' },
        {
          text: this.$t('showUsers.userPhoneNumber'),
          value: 'userPhoneNumber',
        },
        { text: this.$t('showUsers.userJobTitle'), value: 'userJobTitle' },
        { text: this.$t('showUsers.userRoles'), value: 'userRoles' },
        { text: this.$t('showUsers.userState'), value: 'userState' },
        {
          text: this.$t('showUsers.userActions'),
          value: 'actions',
          sortable: false,
        },
      ],
      roles: [
        { name: 'Käyttäjä', value: 'Käyttäjä' },
        { name: 'Pääkäyttäjä', value: 'Pääkäuttäjä' },
        { name: 'Ylläpitäjä', value: 'Ylläpitäjä' },
        { name: 'Pääylläpitäjä', value: 'Pääylläpitäjä' },
      ],
      permissions: [
        { name: this.$t('showUsers.powerGuard'), value: '1' },
        { name: this.$t('showUsers.indoorAir'), value: '2' },
        { name: this.$t('showUsers.users'), value: '3' },
      ],
      states: [
        {
          name: this.$t('showUsers.userStateItems.active'),
          value: 'Aktiivinen',
        },
        {
          name: this.$t('showUsers.userStateItems.passive'),
          value: 'Passiivinen',
        },
      ],
      editedItem: {
        userEmail: '',
        userFirstName: '',
        userLastName: '',
        userPhoneNumber: '',
        userJobTitle: '',
        userRoles: '',
        userState: '',
        userOrganization: '',
        userInformation: '',
        userId: '',
        userPermissions: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show user when page is loaded
   */
  async created() {
    const response = await UserService.getAllUsers();
    this.users = response.data.users;
    for (let i = 0; i < this.users.length; i++) {
      this.users[i].userPermissions = this.users[i].userPermissions.split(',');
    }
  },
  //Edit pencil is pressed
  methods: {
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    // Close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.users);
      });
    },
    /**
     * Is save button is pressed. Save modification information of users to database
     */
    async save() {
      try {
        if (this.editedItem.userPermissions) {
          let permissions = '';
          let permissionString = '';
          for (let i = 0; i < this.editedItem.userPermissions.length; i++) {
            permissions += this.editedItem.userPermissions[i] + ',';
          }
          permissionString = permissions.slice(0, -1);
          this.editedItem.userPermissions = permissionString;
        }
        const response = await UserService.saveUserModification(
          this.editedItem
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const users = await UserService.getAllUsers();
        this.users = users.data.users;
        for (let i = 0; i < this.users.length; i++) {
          this.users[i].userPermissions = this.users[i].userPermissions.split(
            ','
          );
        }

        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
