import Api from '@/services/Api';

//User api cals
export default {
  getDiscussionCommentsByDiscussionId(params) {
    return Api().get('/api/tieto/discussions-comments', {
      params,
    });
  },
  getDiscussionCommentsByCommentId(params) {
    return Api().get('/api/tieto/discussions-comment-id', {
      params,
    });
  },
  saveDiscussionCommentModification(credintials) {
    return Api().patch(
      '/api/tieto/current-comment-modification',
      credintials,
      {}
    );
  },
  addNewComment(credintials) {
    return Api().post('/api/tieto/discussion/comment', credintials, {});
  },
};
