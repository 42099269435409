<!-- Top and sidebar template -->
<template>
  <nav>
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      max-heigth="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Tietoa sivustosta
        </v-card-title>
        <v-card-text>
          <embed
            src="pdf/info.pdf"
            frameborder="0"
            width="100%"
            height="600px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeModal">Sulje</v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar color="#45a1e6">
      <v-toolbar-title class="ml-5">
        <h5 class="white--text">{{ user.userEmail }}</h5>
      </v-toolbar-title>

      <v-spacer />
      <h3 class="white--text mr-5">
        {{
          `${user.Organization.organizationPostalDistrict}` +
            ' ' +
            `${user.Organization.organizationName}`
        }}
      </h3>
      <v-btn text color="#95e600" @click="showInfo">
        <v-icon large> mdi-information-outline</v-icon>
      </v-btn>
      <v-btn text @click="logout" color="white">
        <v-icon right large>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
export default {
  data: () => ({
    user: [],
    name: 'navbar',
    infoDialog: false,
  }),
  //When page is loaded
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    /**
     * User click logout button
     * Set token null
     * Set user null
     * */
    showInfo() {
      this.infoDialog = true;
    },
    closeModal() {
      this.infoDialog = false;
    },
    getInfo() {
      return 'Voitte valita palveluista, mihin teillä on oikeudet. ';
    },
    logout() {
      this.$store.dispatch('logout');
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>
<style scoped>
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
.bottom {
  margin-bottom: 10%;
  width: 100%;
}
</style>
