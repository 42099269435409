<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää keskustelu</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Otsikko"
                  prepend-icon="mdi-account"
                  :rules="titleRules"
                  v-model="addDiscussionValues.discussionTitle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus keskustelusta"
                  prepend-icon="mdi-information-variant"
                  v-model="addDiscussionValues.discussionDescription"
                  autocomplete="off"
                  :rules="descriptionRules"
                />
              </v-flex>
              <v-flex xs12 md12 v-if="user.userRoles === 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionOrganizationId"
                  :items="organizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  label="Organisaatio"
                  placeholder="Valitse"
                  :rules="organizationRules"
                  @change="showUsers"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionModerator"
                  :items="users"
                  item-text="fullName"
                  item-value="uuid"
                  label="Valitse moderaattori"
                  placeholder="Valitse"
                  :rules="moderatorRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionTargetId"
                  :items="targets"
                  item-text="targetName"
                  item-value="targetId"
                  label="Valitse kohde"
                  placeholder="Valitse"
                  :rules="targetRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addDiscussionValues.discussionState"
                  :items="queryState"
                  item-text="name"
                  item-value="value"
                  label="Keskustelun tila"
                  placeholder="Valitse"
                  :rules="stateRules"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewDiscussion"
                >
                  Lisää uusi keskustelu
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService.js';
import TargetService from '@/services/TargetService.js';
import OrganizationService from '@/services/OrganizationService.js';
import DiscussionService from '@/services/Tieto/DiscussionService.js';
export default {
  data() {
    return {
      queryState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],
      organizations: [],
      user: {},
      users: [],
      targets: [],
      addDiscussionValues: {},
      message: null,
      error: null,
      titleRules: [
        (v) => !!v || 'Otsikko on pakollinen tieto',
        (v) => (v && v.length >= 3) || 'Otsikossa pitää olla ainakin 3 merkkiä',
      ],
      descriptionRules: [
        (v) => !!v || 'Kuvaus keskustelusta on pakollinen tieto',
        (v) => (v && v.length >= 3) || 'Otsikossa pitää olla ainakin 3 merkkiä',
      ],
      moderatorRules: [(v) => !!v || 'Moderaattori on pakollinen tieto'],
      targetRules: [(v) => !!v || 'Kohde on pakollinen tieto'],
      organizationRules: [(v) => !!v || 'Organisaatio on pakollinen tieto'],
      stateRules: [(v) => !!v || 'Kyselyn tila on pakollinen tieto'],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.user.userRoles == 'Pääylläpitäjä') {
      try {
        const organizatios = await OrganizationService.getAllOrganizations();
        this.organizations = organizatios.data.organizations;
      } catch (error) {
        this.error = error;
      }
    } else {
      try {
        const users = await UserService.getAllOrganizationUsers({
          organizationId: this.user.Organization.organizationId,
        });
        this.users = users.data.users;
      } catch (error) {
        this.error = error;
      }
      try {
        const targets = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.user.Organization.organizationId,
        });
        this.targets = targets.data.targets;
      } catch (error) {
        this.error = error;
      }
    }
  },

  methods: {
    async showUsers() {
      try {
        const users = await UserService.getAllOrganizationUsers({
          organizationId: this.addDiscussionValues.discussionOrganizationId,
        });
        this.users = users.data.users;
      } catch (error) {
        this.error = error;
      }
      try {
        const targets = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.addDiscussionValues
            .discussionOrganizationId,
        });
        this.targets = targets.data.targets;
      } catch (error) {
        this.error = error;
      }
    },
    async addNewDiscussion() {
      if (this.$refs.form.validate()) {
        if (this.user.userRoles !== 'Pääylläpitäjä') {
          this.addDiscussionValues.discussionOrganizationId = this.user.Organization.organizationId;
        }
        try {
          const response = await DiscussionService.addNewDiscussion(
            this.addDiscussionValues
          );
          this.addDiscussionValues = {};
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 30000);
        }
      }
    },
  },
};
</script>
