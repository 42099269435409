<!-- Top and sidebar template -->
<template>
  <nav>
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      max-heigth="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Tietoa sivustosta
        </v-card-title>
        <v-card-text>
          <embed
            src="pdf/info.pdf"
            frameborder="0"
            width="100%"
            height="600px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeModal">Sulje</v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar color="#45a1e6" class="white--text" app>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="white--text"
        large
      />
      <!-- <h3>
        {{
          `${user && user.Organization && user.Organization.organizationName}`
        }}
      </h3> -->

      <!-- <h3 v-if="showTargetName()">Linnainaan koulu</h3> -->
      <v-spacer />
      <v-btn
        text
        color="red lighten-3"
        @click="showAlarms"
        v-show="!activeAlarms.error && activeAlarms.data.length"
      >
        <alarm-amount-indicator :active-alarms="activeAlarms" />
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon left class="white--text" large>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="border"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"/></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text color="#95e600" @click="showInfo">
        <v-icon large> mdi-information-outline</v-icon>
      </v-btn>
      <v-btn text @click="logout">
        <v-icon right class="white--text" large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer v-model="drawer" dark app color="#45a1e6" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <p class="white--text subheading text-center">
            {{
              `${user &&
                user.Organization &&
                user.Organization.organizationName}`
            }}
          </p>
          <p class="white--text subheading text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-1 mb-2">
          <v-divider color="white" />
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <v-list-item to="/vainu/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Etusivu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group :value="false" prepend-icon="mdi-leak">
          <template v-slot:activator>
            <v-list-item-title>Mittarit</v-list-item-title>
          </template>
          <div
            v-for="([title, roles, route], i) in sensors"
            :key="i"
            class="div-list"
          >
            <v-list-item
              link
              :to="route"
              sub-group
              v-if="roles !== user.userRoles"
            >
              <v-list-item-content>
                <v-list-item-title v-text="title" />
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>

        <!--Alarms group -->
        <v-list-item to="/vainu/show-alarms">
          <v-list-item-icon>
            <v-icon>mdi-alarm-light</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Hälytykset</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/vainu/sensor-data">
          <v-list-item-icon>
            <v-icon>mdi-speedometer-slow</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mittaukset</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/vainu/show-materialbank-files-vainu">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Materiaalipankki</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider color="white" />
        <v-list class="bottom">
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sovellukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/tieto/dashboard"
            v-if="user.userPermissions.includes('1')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tieto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/materiaalipankki/dashboard"
            v-if="user.userPermissions.includes('3')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Materiaalipankki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/admin/dashboard"
            v-if="user.userPermissions.includes('4')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hallinta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider color="white" />
        </v-list>
      </template>
      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
import AlarmAmountIndicator from '../Alarms/AlarmAmountIndicator.vue';
//import Popup from './Popup.vue';
export default {
  components: { AlarmAmountIndicator },
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    infoDialog: false,
    users: [
      ['Vaihda salasana', 'mdi-lock', '/vainu/change-password'],
      ['Muokkaa tietoja', 'mdi-account', '/vainu/show-user'],
    ],

    alarms: [['Näytä hälytykset', 'mdi-alarm-ligth', '/vainu/show-alarms']],
    sensors: [
      ['Lisää uusi mittari', 'Käyttäjä', '/vainu/add-device'],
      ['Näytä mittarit', 'Käyttäjä', '/vainu/show-devices'],
      ['Lisää mittari käyttäjälle', 'Käyttäjä', '/vainu/add-user-device'],
      ['Näytä käyttäjien mittarit', 'Käyttäjä', '/vainu/show-user-devices'],
      ['Qr-koodi', '', '/vainu/qr-code'],
    ],
  }),
  computed: {
    activeAlarms() {
      return this.$store.getters.activeAlarms;
    },
    measurementsSelected() {
      const measurementPaths = this.measurements.map((it) => it[2]);

      for (let path of measurementPaths) {
        if (path === this.$route.path.substr(0, path.length)) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    showTargetName() {
      let url = window.location.href;
      let alarms = url.includes('/vainu/show-alarms');
      let measurements = url.includes('/vainu/sensor-data');
      if (alarms || measurements) {
        return false;
      } else {
        return true;
      }
    },

    showAlarms() {
      this.$router.push('/vainu/show-alarms');
    },
    showInfo() {
      this.infoDialog = true;
    },
    closeModal() {
      this.infoDialog = false;
    },
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item-title--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
.bottom {
  margin-bottom: 10%;
  width: 100%;
}
.div-list {
  padding-left: 55px;
}
</style>
