<!-- Add new measurement template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12><h2>{{ $t('measurement.measurementTitle') }}</h2></v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('measurement.measurementName')"
                  prepend-icon="mdi-domain"
                  :rules="[rules.required]"
                  v-model="measurementValues.measurementName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="measurementValues.measurementType"
                  :items="measurementType"
                  item-text="name"
                  item-value="value"
                  :label="$t('measurement.measurementType')"
                  placeholder="Valitse..."
                  required
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('measurement.measurementSizeInBytes')"
                  prepend-icon="mdi-home-city-outline"
                  :rules="[rules.required]"
                  v-model="measurementValues.measurementSizeInBytes"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('measurement.measurementBitNumber')"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required]"
                  v-model="measurementValues.measurementBitNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="measurementValues.measurementState"
                  :items="measurementState"
                  item-text="name"
                  item-value="value"
                  :label="$t('measurement.measurementState')"
                  placeholder="Valitse..."
                  required
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('measurement.measurementDescription')"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="measurementValues.measurementDescription"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewMeasurement"
                >
                  {{ $t('measurement.measurementRegisterButton') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MeasurementService from '@/services/Vainu/MeasurementService.js';
export default {
  data() {
    return {
      measurementState: [
        { name: this.$t('register.state-active'), value: 'active' },
        { name: this.$t('register.state-passive'), value: 'passive' },
      ],
      measurementType: [
        { name: this.$t('register.type-byte'), value: 'byte' },
        { name: this.$t('register.type-bit'), value: 'bit' },
      ],

      measurementValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('register.required'),
      },
    };
  },
  methods: {
    /**
     * Add new button is clicked
     */
    async addNewMeasurement() {
      try {
        const response = await MeasurementService.addNewMeasurement(
          this.measurementValues
        );

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
