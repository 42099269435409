<!-- Register template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12>
                <h2>{{ $t('targetToUser.addTargetToUser') }}</h2>
              </v-flex>
              <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="userTargetValues.organization"
                  :items="organizations"
                  :label="$t('targetToUser.organization')"
                  placeholder="Valitse..."
                  item-text="organizationName"
                  item-value="organizationId"
                  :rules="required"
                  @change="showUsers"
                  chips
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="userTargetValues.userId"
                  :items="users"
                  :label="$t('targetToUser.users')"
                  placeholder="Valitse..."
                  item-text="fullName"
                  item-value="uuid"
                  :rules="required"
                  chips
                  @change="showTargets"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="userTargetValues.targetId"
                  :items="targets"
                  :label="$t('targetToUser.targets')"
                  placeholder="Valitse..."
                  item-text="targetName"
                  item-value="targetId"
                  :rules="required"
                  chips
                  multiple
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addTargets"
                >
                  {{ $t('targetToUser.addTarget') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService.js';
import OrganizationService from '@/services/OrganizationService';
import TargetService from '@/services/TargetService';
import UserTargetService from '@/services/UserTargetService';

export default {
  data() {
    return {
      userTargetValues: {},
      message: null,
      organizations: [],
      organizationId: '',
      targets: [],
      user: {},
      users: [],
      error: null,
      required: [(v) => !!v || 'Pakollinen kenttä'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.user.userRoles === 'Pääylläpitäjä') {
      try {
        const response = await OrganizationService.getAllOrganizations();
        this.organizations = response.data.organizations;
        // this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    } else {
      try {
        let userData = await UserService.getAllOrganizationUsers({
          organizationId: this.user.Organization.organizationId,
        });

        this.users = userData.data.users;
        this.message = userData.data.message;
        this.error = userData.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    }
  },

  methods: {
    async showTargets() {
      let targetData = '';

      try {
        if (this.user.userRoles === 'Pääylläpitäjä') {
          targetData = await TargetService.getAllOrganizationTargets({
            targetOrganizationId: this.userTargetValues.organization,
          });
        } else {
          targetData = await TargetService.getAllOrganizationTargets({
            targetOrganizationId: this.user.Organization.organizationId,
          });
        }
        this.targets = targetData.data.targets;
        this.message = targetData.data.message;
        this.error = targetData.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async showUsers() {
      let userData = '';
      try {
        if (this.user.userRoles === 'Pääylläpitäjä') {
          userData = await UserService.getAllOrganizationUsers({
            organizationId: this.userTargetValues.organization,
          });
        } else {
          userData = await UserService.getAllOrganizationUsers({
            organizationId: this.user.Organization.organizationId,
          });
        }
        this.users = userData.data.users;
        this.message = userData.data.message;
        this.error = userData.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    //Register button is clicked
    async addTargets() {
      if (this.$refs.form.validate()) {
        try {
          const targets = await UserTargetService.addUserTargets(
            this.userTargetValues
          );
          this.userTargetValues = {};
          this.message = targets.data.message;
          this.error = targets.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 30000);
        }
      }
    },
  },
};
</script>
