import Api from '@/services/Api';

//Organization api cals
export default {
  addAlarmLimits(credintials) {
    return Api().post('/api/alarm-limits/', credintials, {});
  },
  getAlarmLimits(params) {
    return Api().get('/api/alarm-limits', { params });
  },
  updateAlarmLimits(credintials) {
    return Api().patch('/api/alarm-limits/', credintials, {});
  },
};
