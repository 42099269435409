import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuetify from 'vuetify';
import './plugins/vuetify.js';
import store from './store';
import i18n from './i18n';

import { sync } from 'vuex-router-sync';

Vue.config.productionTip = false;
sync(store, router); // TODO, this causes warning: [vuex] state field "route" was overridden by a module with the same name at "route"
// set axios auth header
//Vue.prototype.$http = axios;

new Vue({
  vuetify: new Vuetify({}),

  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
