<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="showDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      :value="value"
      @input="$emit('input', $event)"
      no-title
      scrollable
    >
      <v-spacer/>
      <!-- <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(value)"
      >
        {{ $t('common.ok') }}
      </v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  props: ['value', 'label'],
  data() {
    return {
      menu: false
    }
  },
  computed: {
    showDate() {
      const dt = moment(this.value);
      return dt.isValid() ? dt.format('DD.MM.YYYY') : ''
    }
  }
}
</script>