<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää ryhmä</h4></v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Ryhmän nimi"
                  prepend-icon="mdi-information-variant"
                  v-model="addQuestionGroupValues.queryQuestionGroupName"
                  autocomplete="off"
                  :rules="groupRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus ryhmästä"
                  prepend-icon="mdi-information-variant"
                  v-model="addQuestionGroupValues.queryQuestionGroupDescrption"
                  autocomplete="off"
                  :rules="infoRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQuestionGroupValues.queryQuestionGroupState"
                  :items="questionGroupState"
                  item-text="name"
                  item-value="value"
                  label="Ryhmän tila"
                  placeholder="Valitse"
                  :rules="stateRules"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewQuestionGroup"
                >
                  Lisää uusi ryhmä
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QuestionGroupService from '@/services/Tieto/QuestionGroupService.js';
export default {
  data() {
    return {
      questionGroupState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],
      addQuestionGroupValues: {},
      message: null,
      error: null,
      groupRules: [
        (v) => !!v || 'Ryhmän nimi on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Ryhmän nimen pitää olla ainakin 3 merkkiä',
      ],
      infoRules: [
        (v) => !!v || 'Kuvaus on pakollinen tieto',
        (v) => (v && v.length >= 3) || 'Kuvauksen pitää olla ainakin 3 merkkiä',
      ],
      stateRules: [(v) => !!v || 'Tila pitää valita'],
    };
  },

  methods: {
    async addNewQuestionGroup() {
      if (this.$refs.form.validate()) {
        try {
          const response = await QuestionGroupService.addNewQuestionGroup(
            this.addQuestionGroupValues
          );
          this.addQuestionGroupValues = {};
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
