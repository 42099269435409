<template>
  <v-app>
    <main>
      <router-view />
    </main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    console.log('app mounted');
    setInterval(() => {
      const token = this.$store.getters.getToken
      if (token && token !== null) {
        this.$store.dispatch('fetchActiveAlarms');
      }
    }, 60000);
    this.$store.dispatch('fetchActiveAlarms');
  },
};
</script>
<style></style>
