<!-- Show users data-table -->
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="showAlarms"
      sort-by="showAlarms"
      class="elevation-1"
      no-data-text="Tietoa ei saatavilla"
      :footer-props="{
        'items-per-page-text': 'Rivejä sivulla',
        'items-per-page-all-text': 'Kaikki',
      }"
    >
      <template v-slot:top>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>

        <div>
          <div class="d-flex align-center flex-wrap">
            <v-autocomplete
              multiple
              style="width: 300px"
              class="flex-grow-0 flex-shrink-0 ml-4"
              :items="targetNames"
              :value="selectedTargets"
              :return-object="true"
              label="Valitse kohde"
              no-data-text="Tietoa ei saatavilla"
              @input="onTargetChange"
            />
            <v-switch
              class="ml-4"
              :input-value="$route.query.st"
              label="Kuittaamattomat"
              @change="onStateFilterChange"
            />
            <v-switch
              class="ml-4"
              :input-value="$route.query.ac"
              label="Aktiiviset"
              @change="onActiveFilterChange"
            />
          </div>
          <date-range-picker
            :start-date="$route.query.sd || ''"
            :end-date="$route.query.ed || ''"
            @change="onDateRangeChange"
          />
        </div>

      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-btn
          v-if="!item.state"
          small
          color="general"
          @click="acknowledgeAlarm(item)"
        >Kuittaa</v-btn>
        <v-btn
          v-else
          color="general"
          small
          outlined
          @click="onUnacknowledgeAlarmClick(item)"
        >Kuitattu</v-btn>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div>{{ parseAlarmDate(item.createdAt) }}</div>
      </template>
      <template v-slot:[`item.alarmBreakThrough`]="{ item }">
        <div>{{ alarmEventName(item.alarmBreakThrough) }}</div>
      </template>
      <!-- Show success/error messages -->


    </v-data-table>
    <confirm-dialog
      :show="!!unacknowledgeAlarmId"
      :title="$t('showAlarms.unacknowledgeAlarm')"
      :text="$t('showAlarms.unacknowledgeAlarmText')"
      @close="onUnacknowledgeAlarmClose"
    />
  </div>
</template>
<script>
import AlarmService from '@/services/Vainu/AlarmService.js';
import moment from 'moment';
import Api from '@/services/Api';
const api = Api();
import DateRangePicker from '../../Common/DateRangePicker.vue';
import { arrayify } from '../../../utils/'
import ConfirmDialog from '../../Common/ConfirmDialog.vue';

export default {
  components: {
    DateRangePicker,
    ConfirmDialog
  },
  data() {
    return {
      targetNames: [],
      showAlarms: [],
      alarms: [],
      modal: false,
      menu1: false,
      menu2: false,
      startDate: '',
      endDate: '',
      dialog: false,
      error: null,
      message: null,
      unacknowledgeAlarmId: 0,
      formTitle: this.$t('showAlarms.alarmsTitle'),

      headers: [
        {
          text: this.$t('showAlarms.alarmState'),
          value: 'state',
        },
        {
          text: this.$t('showAlarms.alarmOrganizationName'),
          value: 'Device.Target.Organization.organizationName',
        },
        {
          text: this.$t('showAlarms.alarmTarget'),
          value: 'Device.Target.targetName',
        },
        {
          text: this.$t('showAlarms.alarmAddress'),
          value: 'Device.Target.address',
        },
        {
          text: this.$t('showAlarms.alarmLocation'),
          value: 'Device.deviceLocation',
        },
        {
          text: this.$t('showAlarms.alarmMeasurementName'),
          value: 'measurementName',
        },
        {
          text: this.$t('showAlarms.alarmBreakThrough'),
          value: 'alarmBreakThrough',
        },
        {
          text: this.$t('showAlarms.alarmCreationDateTime'),
          value: 'createdAt',
        },
        {
          text: this.$t('showAlarms.alarmDeviceId'),
          value: 'alarmDeviceId',
        },
        {
          text: this.$t('showAlarms.alarmDescription'),
          value: 'alarmDescription',
        },
      ],

      states: [
        {
          name: this.$t('showAlarms.alarmStateItems.active'),
          value: 'active',
        },
        {
          name: this.$t('showAlarms.alarmStateItems.passive'),
          value: 'passive',
        },
      ],
    };
  },
  computed: {
    selectedTargets() {
      let tid = this.$route.query.tid
      tid = arrayify(tid)
      console.log(tid)
      const arr = this.targetNames.filter(it => tid.includes(it.value.toString()))
      console.log(arr)
      return arr
    },
    activeAlarms() {
      return this.$store.getters.activeAlarms
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchData(to);
    });
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchData(to);
    next();
  },
  methods: {
    async fetchData(route) {
      try {
        const { tid, sd, ed, st, ac } = route.query

        const resArr = await Promise.all([
          api.get('/api/targets/names'),
          api.get('/api/alarms', { params: { tid, sd, ed, st, ac } })
        ])

        const targetNames = resArr[0].data
        this.targetNames = [
          ...targetNames.map(it => ({
            value: it.id,
            text: it.targetName,
          }))
        ]

        this.showAlarms = resArr[1].data.alarms;
      } catch (err) {
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 2000);
        console.error(err)
      }
    },
    parseAlarmDate(date) {
      return moment(date).utc().format('DD.MM.YYYY hh:mm')
    },
    alarmEventName(event) {
      if (event === 'up') {
        return this.$t('showAlarms.alarmEvents.up')
      }
      if (event === 'down') {
        return this.$t('showAlarms.alarmEvents.down')
      }
    },
    onDateRangeChange({ startDate, endDate }) {
      const query = { ...this.$route.query }
      if (endDate) {
        query.ed = endDate
      } else {
        delete query.ed
      }
      if (startDate) {
        query.sd = startDate
      } else {
        delete query.sd
      }
      this.$router.replace({ query })
    },
    async acknowledgeAlarm(alarm) {
      await AlarmService.acknowledgeAlarm(alarm.id);
      this.$store.dispatch('fetchActiveAlarms')
      this.fetchData(this.$route)
    },
    async onUnacknowledgeAlarmClick(alarm) {
      this.unacknowledgeAlarmId = alarm.id
    },
    async onUnacknowledgeAlarmClose(confirmStatus) {
      if (confirmStatus) {
        await AlarmService.unacknowledgeAlarm(this.unacknowledgeAlarmId);
        this.$store.dispatch('fetchActiveAlarms')
        this.fetchData(this.$route)
      }
      this.unacknowledgeAlarmId = 0
    },
    onTargetChange(items) {
      const query = { ...this.$route.query }
      query.tid = items.map(it => it.value)
      this.$router.replace({ query })
    },
    onStateFilterChange(value) {
      console.log('onStateFilterChange', value)
      const query = { ...this.$route.query }
      if (value) {
        query.st = 0
      } else {
        delete query.st;
      }
      this.$router.replace({ query })
    },
    onActiveFilterChange(value) {
      console.log('onActiveFilterChange', value)
      const query = { ...this.$route.query }
      if (value) {
        query.ac = 1
      } else {
        delete query.ac;
      }
      this.$router.replace({ query })
    },
  },
};
</script>
<style></style>
