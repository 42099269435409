import Api from '@/services/Api';

//Organization api cals
export default {
  getAllDevices() {
    return Api().get('/api/devices/', {});
  },
  getAllDevicesAdmin() {
    return Api().get('/api/devices-admin', {});
  },
  getAllOrganizationDevices(params) {
    return Api().get('/api/organization-devices', { params });
  },
  getAllTargetDevices(params) {
    return Api().get('/api/target-devices', { params });
  },
  saveDeviceModification(credintials) {
    return Api().patch('/api/devices', credintials, {});
  },
  addNewDevice(credintials) {
    return Api().post('/api/device/', credintials, {});
  },
};
