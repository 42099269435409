import { Doughnut, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
//import Chart from 'chart.js';

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],

  computed: {
    countTotal() {
      return this.data;
    },
  },
  mounted() {
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: function(chart) {
        var ctx = chart.ctx;
        var text = chart.options.text;
        var label = chart.options.label;
        let width = chart.chart.width;
        let height = chart.chart.height;
        ctx.font = '14px sans-serif';
        ctx.fillStyle = '';
        ctx.textAlign = 'center';
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 1.8;

        ctx.fillText(label + ' ' + text + ' kpl', textX, textY);
      },
    });
    this.renderChart(this.chartData, this.options);
    //this.textCenter(this.countTotal);
  },
};
