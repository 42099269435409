import Api from '@/services/Api';

//Auhetication api cals
export default {
  register(credintials) {
    return Api().post('/api/register', credintials, {});
  },

  login(credintials) {
    return Api().post('/', credintials);
  },
  isTokenValid() {
    return Api().get('/api/auth/', {});
  },
  registerUserAndSendConfirmEmail(credintials) {
    return Api().post('/api/register-user', credintials, {});
  },

  changePassword(credintials) {
    return Api().patch('api/change-password', credintials, {});
  },
  changeUserPassword(credintials) {
    return Api().post('api/change-user-password', credintials, {});
  },
  changePasswordAndSendConfirmEmail(credintials) {
    return Api().post('api/forgot-user-password', credintials, {});
  },
  changeUserForgotPassword(credintials) {
    return Api().post('api/change-user-forgot-password', credintials, {});
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
