<!-- Login template -->
<template>
  <v-app>
    <v-container text-center class="mt-10" v-if="!passwordChange">
      <h1>Tervetuloa VEPA verkkopalveluun!</h1>
      <h3>
        <p>VEPA yhdistää esineiden internetin (IoT) reaaliaikaisen tiedon ja</p>
        <p>
          interaktiivisen viestinnän helposti hallittavaksi kokonaisuudeksi.
        </p>
      </h3>
    </v-container>
    <v-container text-center class="mt-10" v-else>
      <h1>Vaihda salasana</h1>
    </v-container>
    <v-container>
      <v-layout class=" justify-center mt-5">
        <v-dialog
          v-model="infoDialog"
          max-width="800px"
          max-heigth="600px"
          persistent
        >
          <v-card>
            <v-card-title>
              Tietoa sivustosta
            </v-card-title>
            <v-card-text>
              <embed
                src="pdf/info.pdf"
                frameborder="0"
                width="100%"
                height="600px"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="closeModal"
                >Sulje</v-btn
              >

              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-flex xs12 sm6 md6 v-if="!passwordChange">
          <v-form>
            <v-alert :value="true" dense outlined type="error" v-if="error">
              {{ error }}
            </v-alert>
            <v-card class="elevation-5">
              <v-toolbar dark color="#45a1e6">
                <v-toolbar-title class="black--text" center>
                  Verkkopalvelu
                  <v-btn text color="#95e600" @click="showInfo">
                    <v-icon large> mdi-information-outline</v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer />
                <img
                  class="mr-3"
                  :src="require('@/assets/vepa.png')"
                  height="55"
                />
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  prepend-icon="mdi-account"
                  :label="$t('login.email')"
                  type="text"
                  v-model="email"
                />
                <v-text-field
                  prepend-icon="mdi-lock"
                  :label="$t('login.password')"
                  :type="show ? 'text' : 'password'"
                  v-model="current_password"
                  autocomplete="off"
                  @click:append="show = !show"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @keyup.enter="login"
                />
              </v-card-text>
              <v-divider light />
              <v-card-actions>
                <v-btn rounded color="#45a1e6" @click.prevent="registerUser">
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t('login.register') }}
                </v-btn>
                <v-spacer />
                <v-list v-if="forgotPassword" link>
                  <v-list-item @click.prevent="forgotUserPassword">
                    {{ $t('login.forgot-password') }}
                  </v-list-item>
                </v-list>
                <v-spacer v-if="forgotPassword" />
                <v-btn rounded color="#45a1e6" @click="login">
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t('login.login') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
        <v-flex xs12 sm6 md6 v-else>
          <v-form>
            <v-alert :value="true" dense outlined type="success" v-if="message">
              {{ message }}
            </v-alert>
            <v-alert :value="true" dense outlined type="error" v-if="error">
              {{ error }}
            </v-alert>
            <v-card class="elevation-5">
              <v-toolbar dark color="#45a1e6">
                <v-toolbar-title class="black--text" center>
                  Verkkopalvelu
                  <v-btn text color="#95e600" @click="showInfo">
                    <v-icon large> mdi-information-outline</v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer />
                <img
                  class="mr-3"
                  :src="require('@/assets/vepa.png')"
                  height="55"
                />
              </v-toolbar>
              <v-card-text>
                <v-flex xs12 md12>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :label="$t('register.password')"
                    :type="show ? 'text' : 'password'"
                    v-model="changePwd.userPassword"
                    autocomplete="off"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  />
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :label="$t('register.password_repeat')"
                    :type="show_c ? 'text' : 'password'"
                    v-model="changePwd.confirmPassword"
                    autocomplete="off"
                    @click:append="show_c = !show_c"
                    :append-icon="show_c ? 'mdi-eye' : 'mdi-eye-off'"
                  />
                </v-flex>
              </v-card-text>
              <v-divider light />
              <v-card-actions>
                <v-btn
                  rounded
                  color="#45a1e6"
                  @click.prevent="changeUserPassword"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  Vaihda salasana
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
export default {
  data() {
    return {
      email: '',
      changePwd: {},
      current_password: '',
      message: '',
      error: false,
      show: false,
      show_c: false,
      infoDialog: false,
      forgotPassword: false,
      passwordChange: false,
      code: '',
      rules: {
        required: (value) => !!value || 'Pakollinen kenttä',
      },
    };
  },

  mounted() {
    this.changePwd.code = this.$route.query.code;
    this.passwordChange = this.$route.query.isPasswordChange;
  },

  methods: {
    showInfo() {
      this.infoDialog = true;
    },
    getInfo() {
      return 'Kirjaudu sisään antamalla sähköpostiosoite ja salasana. Rekisteröitymistä varten tarvitset koodin tai useita koodeja riippuen kohteiden määrästä. Järjestelmänvalvoja tai pääkäyttäjä lähettää sähköpostiisi tarvittavat koodit.';
    },
    closeModal() {
      this.infoDialog = false;
    },
    forgotUserPassword() {
      this.$router.push('forgot-password');
    },
    async changeUserPassword() {
      try {
        if (this.changePwd.userPassword === this.changePwd.confirmPassword) {
          const response = await AuthenticationService.changeUserForgotPassword(
            this.changePwd
          );
          this.message = response.data.message;
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
            this.passwordChange = false;
            this.$router.push('/');
          }, 3000);
        } else {
          this.error = 'Salasanat eivät täsmänneet. Yritä uudelleen.';
        }
      } catch (error) {
        this.error = error.response.data.error;
        // setTimeout(() => {
        //   this.error = null;
        // }, 3000);
      }
    },
    //When login putton is pressed
    async login() {
      try {
        const credentials = {
          userEmail: this.email,
          userPassword: this.current_password,
        };
        const response = await AuthenticationService.login(credentials);
        this.message = response.data.message;
        //talleta arvot storeen
        this.$store.dispatch('setToken', response.data.token);
        this.$store.dispatch('setUser', response.data.user);
        this.$router.push('/main');
      } catch (error) {
        this.error = error.response.data.error;
        this.forgotPassword = true;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    registerUser() {
      this.$router.push('/user-register');
    },
  },
};
</script>
