<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Kysymykset</th>
          <th class="text-left">Täysin eri mieltä</th>
          <th class="text-left">Osittain eri mieltä</th>
          <th class="text-left">Ei samaa, eikä eri mieltä</th>
          <th class="text-left">Osittain samaa mieltä</th>
          <th class="text-left">Täysin samaa mieltä</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="q in questions" :key="q.id">
          <td>{{ q.questionDescription }}</td>

          <td>{{ q.first }}</td>
          <td>{{ q.second }}</td>
          <td>{{ q.third }}</td>
          <td>{{ q.fourth }}</td>
          <td>{{ q.fifth }}</td>
        </tr>
        <tr>
          Vastauksia yhteensä:
          {{
            answerCount
          }}
          kpl
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import QueryResultService from '@/services/Tieto/QueryResultService';
export default {
  data() {
    return {
      a1: null,
      a2: [],
      a3: [],
      a4: [],
      a5: [],
      answer1: '1',
      answer2: '2',
      answer3: '3',
      answer4: '4',
      answer5: '5',
      resultTable: [],
      test: [],
      q1_1: 0,
      answerCount: '',
      questions: null,
    };
  },
  async created() {
    const response = await QueryResultService.getQueryResultsByQuery({
      queryId: this.$store.getters.getQueryId,
    });
    this.a1 = response.data.a1;
    let arrA1 = Object.values(this.a1[0]);
    this.a2 = response.data.a2;
    let arrA2 = Object.values(this.a2[0]);
    this.a3 = response.data.a3;
    let arrA3 = Object.values(this.a3[0]);
    this.a4 = response.data.a4;
    let arrA4 = Object.values(this.a4[0]);
    this.a5 = response.data.a5;
    let arrA5 = Object.values(this.a5[0]);

    const resultCount = await QueryResultService.getAllResultsByQuery({
      queryId: this.$store.getters.getQueryId,
    });
    this.answerCount = resultCount.data.results.length;
    const questions = await QueryResultService.getAllQueriesQuestionsByQueryId({
      queryId: this.$store.getters.getQueryId,
    });
    this.questions = questions.data.questions;
    for (let i = 0; i < this.questions.length; i++) {
      this.questions[i].first =
        ((arrA1[this.questions[i].id - 1] / this.answerCount) * 100).toFixed(
          2
        ) + ' %';
      this.questions[i].second =
        ((arrA2[this.questions[i].id - 1] / this.answerCount) * 100).toFixed(
          2
        ) + ' %';
      this.questions[i].third =
        ((arrA3[this.questions[i].id - 1] / this.answerCount) * 100).toFixed(
          2
        ) + ' %';
      this.questions[i].fourth =
        ((arrA4[this.questions[i].id - 1] / this.answerCount) * 100).toFixed(
          2
        ) + ' %';
      this.questions[i].fifth =
        ((arrA5[this.questions[i].id - 1] / this.answerCount) * 100).toFixed(
          2
        ) + ' %';
    }
  },

  methods: {
    checkAnswers(item) {
      return item;
    },
  },
};
</script>

<style></style>
