import Api from '@/services/Api';

//User api cals
export default {
  getAllUsers() {
    return Api().get('/api/users', {});
  },

  saveUserModification(credintials) {
    return Api().patch('/api/users/', credintials, {});
  },
  saveUserModificationOwn(credintials) {
    return Api().patch('/api/users-own/', credintials, {});
  },
  getAllOrganizationUsers(params) {
    return Api().get('/api/organization-users/', { params });
  },
  getUser(params) {
    return Api().get('/api/user/', { params });
  },
};
