<!-- Footer template -->
<template>
  <v-footer dark padless app inset>
    <v-card class="flex" flat tile color="#45a1e6" :elevation="1">
      <v-card-text class="py-2  text-center white-text">
        {{ new Date().getFullYear() }} -
        <strong class="white--text">ITKonsultit</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
