var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.feedbacks,"sort-by":"feedbacks","no-data-text":"Tietoa ei saatavilla","footer-props":{
    'items-per-page-text': 'Rivejä sivulla',
    'items-per-page-all-text': 'Kaikki',
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.error)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.message)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"mt-1",attrs:{"items":_vm.targets,"item-text":"Target.targetName","item-value":"Target.targetId","label":"Valitse kohde:","props":{
              color: '#ffffff',
            }},on:{"change":_vm.changeTargets},model:{value:(_vm.userTargets),callback:function ($$v) {_vm.userTargets=$$v},expression:"userTargets"}})],1)],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Palautteet")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"750px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Otsikko","prepend-icon":"mdi-account","autocomplete":"off"},model:{value:(_vm.feedbackItems.feedbackTitle),callback:function ($$v) {_vm.$set(_vm.feedbackItems, "feedbackTitle", $$v)},expression:"feedbackItems.feedbackTitle"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Kyselyn kuvaus","prepend-icon":"mdi-information-variant","autocomplete":"off"},model:{value:(_vm.feedbackItems.feedbackMessage),callback:function ($$v) {_vm.$set(_vm.feedbackItems, "feedbackMessage", $$v)},expression:"feedbackItems.feedbackMessage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-account-supervisor","items":_vm.feedbackStates,"label":"Kyselyn tila","placeholder":"Valitse...","item-text":"name","item-value":"value"},model:{value:(_vm.feedbackItems.feedbackState),callback:function ($$v) {_vm.$set(_vm.feedbackItems, "feedbackState", $$v)},expression:"feedbackItems.feedbackState"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.results",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showResults(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }