var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.tieto)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"tieto/dashboard"}},[_c('v-card-text',[_c('div',[_c('h1',{staticClass:"text-color"},[_vm._v("TIETO")])]),_c('div',{staticClass:"text--primary"},[_c('h3',[_vm._v(" Klikkaa itsesi tietoon, pääset omassa käyttäjäprofiilissasi oleviin keskusteluihin, kyselyihin tai tiedostoihin. ")])])])],1)]}}],null,false,3377874817)})],1):_vm._e(),(_vm.vainu)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"vainu/dashboard"}},[_c('v-card-text',[_c('div',[_c('h1',{staticClass:"text-color"},[_vm._v("VAINU")])]),_c('div',{staticClass:"text--primary"},[_c('h3',[_vm._v(" Klikkaa itsesi vainuun, pääset katsomaan kiinteistö- ja laitetietojasi. ")])])])],1)]}}],null,false,3480698400)})],1):_vm._e(),(_vm.hallinta)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"admin/dashboard"}},[_c('v-card-text',[_c('div',[_c('h1',{staticClass:"text-color"},[_vm._v("HALLINTA")])]),_c('div',{staticClass:"text--primary"},[_c('h3',[_vm._v(" Klikkaa itsesi hallinnoimaan käyttäjiä, organisaatioita ja tietoja. ")])])])],1)]}}],null,false,747915718)})],1):_vm._e(),(_vm.materiaalipankki)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6,"link":"","to":"materiaalipankki/dashboard"}},[_c('v-card-text',[_c('div',[_c('h1',{staticClass:"text-color"},[_vm._v("MATERIAALIPANKKI")])]),_c('div',{staticClass:"text--primary"},[_c('h3',[_vm._v(" Klikkaa itsesi lisäämään materiaaleja ")])])])],1)]}}],null,false,2234347411)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }