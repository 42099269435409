<!-- Top and sidebar template -->
<template>
  <nav>
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      max-heigth="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Tietoa sivustosta
        </v-card-title>
        <v-card-text>
          <embed
            src="pdf/info.pdf"
            frameborder="0"
            width="100%"
            height="600px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeModal">Sulje</v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar color="#45a1e6" class="white--text" app>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="white--text"
        large
      />

      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"/></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text color="#95e600" @click="showInfo">
        <v-icon large> mdi-information-outline</v-icon>
      </v-btn>
      <v-btn text @click="logout">
        <v-icon right class="white--text" large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer v-model="drawer" dark app color="#45a1e6" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <p class="white--text subheading text-center">
            {{ user.Organization.organizationName }}
          </p>
          <p class="white--text subheading text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-1 mb-2">
          <v-divider color="white" />
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <v-list-item to="/materiaalipankki/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Etusivu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/materiaalipankki/add-new-materialbank-file">
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Lisää uusi tiedosto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/materiaalipankki/show-materialbank-files">
          <v-list-item-icon>
            <v-icon>mdi-file-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Näytä tiedostot</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider color="white" />
        <v-list class="bottom">
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sovellukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/vainu/dashboard"
            v-if="user.userPermissions.includes('2')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vainu</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/tieto/dashboard"
            v-if="user.userPermissions.includes('1')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tieto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/admin/dashboard"
            v-if="user.userPermissions.includes('4')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hallinta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider color="white" />
        </v-list>
      </template>

      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
export default {
  data: () => ({
    name: 'navbar',
    user: null,
    drawer: true,
    infoDialog: false,
    users: [
      ['Vaihda salasana', 'mdi-lock', '/materiaalipankki/change-password'],
      ['Muokkaa tietoja', 'mdi-account', '/materiaalipankki/show-user'],
    ],
    tools: [
      [
        'Lisää mittariluokka',
        'mdi-thermometer-lines',
        '/edit-user-information',
      ],
      ['Lisää mittariluokan enkoodaus', 'mdi-hexadecimal', '/change-password'],
      ['Lisää mittaus', 'mdi-hexadecimal', '/vainu/add-measurement'],
      ['Näytä mittaukset', 'mdi-hexadecimal', '/vainu/show-measurements'],
    ],
    targets: [
      ['Lisää uusi kohde', 'mdi-home-city', '/vainu/add-target'],
      ['Näytä kohteet', 'mdi-home-group', '/vainu/show-targets'],
    ],
    alarms: [['Näytä hälytykset', 'mdi-alarm-ligth', '/vainu/show-alarms']],

    userLinks: [
      ['Lisää uusi käyttäjä', '', '/vainu/add-user'],
      ['Näytä käyttäjät', '', '/vainu/show-users'],
    ],
    organizations: [
      ['Lisää uusi organisaatio', '', '/vainu/add-organization'],
      ['Näytä organisaatiot', '', '/vainu/show-organizations'],
    ],
    sensors: [
      ['Lisää uusi mittari', '', '/vainu/add-meter'],
      ['Näytä mittarit', '', '/vainu/show-meters'],
      ['Qr-koodi', '', '/vainu/qr-code'],
    ],
    reports: [['Näytä mittariraportti', '', '']],
    measurements: [
      ['Lisää mittariluokka', '', '/edit-user-information'],
      ['Näytä mittariluokka', '', '/edit-user-information'],

      ['Lisää mittaus', '', '/vainu/add-measurement'],
      ['Näytä mittaukset', '', '/vainu/show-measurements'],
      ['Lisää uusi anturi', '', '/vainu/add-sensor'],
      ['Näytä anturit', '', '/vainu/show-sensors'],
      ['Mittaustulokset', '', '/vainu/sensor-data'],
    ],
  }),
  computed: {
    activeAlarms() {
      return this.$store.getters.activeAlarms;
    },
    measurementsSelected() {
      const measurementPaths = this.measurements.map((it) => it[2]);

      for (let path of measurementPaths) {
        if (path === this.$route.path.substr(0, path.length)) {
          return true;
        }
      }
      return false;
    },
  },
  //When page is loaded
  created() {
    this.user = this.user = this.$store.getters.getUser;
  },
  methods: {
    showInfo() {
      this.infoDialog = true;
    },
    closeModal() {
      this.infoDialog = false;
    },
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item-title--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
.bottom {
  margin-bottom: 10%;
  width: 100%;
}
</style>
