<template>
  <div v-if="masterChartData">
    <!-- <v-btn color="general" @click="$router.go(-1)">
      <v-icon>mdi-arrow-left</v-icon>
      Takaisin
    </v-btn> -->
    <div>
      <div style="max-width:1500px">
        <h2 class="pt-4">{{ organizationName }} - {{ measurementName }}</h2>
        <div id>
          <line-chart
            :width="null"
            :height="null"
            :chart-data="masterChartData"
            :options="masterChartOptions"
            id="line-chart-main"
          />
        </div>
      </div>
      <div v-for="t in targets">
        <div style="max-width:1500px">
          <h2 class="pt-4" id="organization-info">{{ t.title }}</h2>
          <div v-for="d in t.Devices">
            <line-chart
              v-if="d.chartData"
              :width="null"
              :height="null"
              :chart-data="d.chartData"
              :options="d.chartOptions"
              :id="'line-chart-' + d.deviceId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/Api';
const api = Api();
import moment from 'moment';
import LineChart from './LineChart.js';
import { arrayify } from '../../../utils';

const colors = ['#00A1E6', '#ff6384', '#36a2eb', '#cc65fe', '#ffce56'];

export default {
  components: {
    LineChart,
  },
  //props: ['data', 'options'],
  data() {
    return {
      targets: [],
      measurementName: '',
      masterChartData: null,
      masterChartOptions: null,
    };
  },
  computed: {
    organizationName() {
      const user = this.$store.state.user;
      return user && user.Organization && user.Organization.organizationName;
    },
  },
  /*beforeRouteEnter(to, from, next) {
    console.log('beforeRouteUpdate sensorchart', )
    next(vm => {
      vm.fetchData(to);
    });
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate sensorchart', )
    this.fetchData(to);
    next();
  },*/
  methods: {
    async fetchData(route) {
      try {
        this.reset();
        const { tid, mid, did, sd, ed } = route.query;

        let targetIds = arrayify(tid);
        let deviceIds = arrayify(did);

        let dt = moment(sd);
        const endDt = moment(ed);
        const labels = [];
        while (dt.isBefore(endDt)) {
          labels.push(dt.toDate());
          dt = moment(dt).add(1, 'days');
        }

        const masterDataSets = [];
        const targets = [];
        let colorIndex = 0;

        for (let targetId of targetIds) {
          const targetRes = await api.get(`/api/targets/${targetId}`);
          const target = targetRes.data;
          const devices = target.Devices.filter((d) =>
            deviceIds.includes(d.deviceId)
          );

          target.title = `${this.organizationName}, ${target.targetName} ${target.targetAddress} ${target.targetZipCode} ${target.targetPostalDistrict}`;
          for (let device of devices) {
            colorIndex++;
            const res = await api.get(
              `/api/measurement-data/${device.deviceId}`,
              { params: { mid, sd, ed } }
            );
            const measData = res.data;

            if (device.DeviceType && device.DeviceType.Measurements) {
              const meas = device.DeviceType.Measurements.find(
                (me) => me.measurementId == mid
              );
              if (meas) {
                this.measurementName = meas.measurementName;
              }
            }

            const chartTitle = device.deviceLocation;
            const limits = device.AlarmLimits.find(
              (limit) => limit.measurementId === mid
            );
            // const data = dt.map(dt => ({

            //   x: dt.data
            // })
            const data = measData.map((md) => ({
              x: new Date(md.createdAt),
              y: md.data[mid],
            }));

            let bColor = colors[0];
            if (colors[colorIndex]) {
              bColor = colors[colorIndex];
            }

            masterDataSets.push({
              borderColor: bColor,
              fill: false,
              lineTension: 0,
              data: data,
            });

            device.chartData = {
              labels: labels, //dt.map(dt => dt.label),
              datasets: [
                {
                  borderColor: bColor,
                  fill: false,
                  lineTension: 0,
                  data: data,
                },
              ],
            };
            device.chartOptions = {
              responsive: true,
              aspectRatio: 2,
              animation: {
                duration: 0,
              },
              title: {
                display: true,
                text: chartTitle,
                fontSize: 18,
                position: 'top',
              },
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    type: 'time',
                    time: {
                      unit: 'day',
                    },
                    ticks: {
                      source: 'labels',
                    },
                  },
                ],
              },
              annotation: {
                annotations: [],
              },
            };
            if (limits) {
              device.chartOptions.annotation.annotations = [
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: limits.lowLimit,
                  borderColor: '#919191',
                  borderWidth: 2,
                },
                {
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: limits.highLimit,
                  borderColor: '#919191',
                  borderWidth: 2,
                },
              ];
            }
          }
          if (devices.length) {
            targets.push(target);
          }
        }
        if (targets.length) {
          this.targets = targets;

          this.masterChartData = {
            labels: [new Date(sd), new Date(ed)], //dt.map(dt => dt.label),
            datasets: masterDataSets,
          };
          this.masterChartOptions = {
            responsive: true,
            aspectRatio: 2,
            animation: {
              duration: 0,
            },
            title: {
              display: true,
              text: `${this.organizationName} - ${this.measurementName}`,
              fontSize: 18,
              position: 'top',
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                },
              ],
            },
          };
        }
      } catch (err) {
        console.error(err);
      }
    },
    reset() {
      this.targets = [];
      this.measurementName = '';
      this.masterChartData = null;
      this.masterChartOptions = null;
    },
  },
};
</script>

<style>
.w-200 {
  max-width: 200px;
  min-width: 200px;
}
.w-300 {
  max-width: 300px;
  min-width: 300px;
}
</style>
