<template>
  <div>
    <div class="d-flex align-center flex-wrap mx-4">
      <div class="flex-grow-0 flex-shrink-0 mr-4" style="flex-basis:315px">
        <v-btn color="primary" @click="setTimeRange('days')">1 pv</v-btn>
        <v-btn color="primary" class="ml-2" @click="setTimeRange('weeks')">1 vk</v-btn>
        <v-btn color="primary" class="ml-2" @click="setTimeRange('months')">1 kk</v-btn>
        <v-btn color="primary" class="ml-2" @click="setTimeRange('years')">1 v</v-btn>
      </div>
      <div class="mt-4 mt-md-auto">
        <div class="d-flex flex-wrap">
          <date-picker
            class="flex-grow-0 flex-shrink-0"
            style="flex-basis:200px;"
            :value="startDate"
            :label="$t('chart.startDate')"
            @input="onStartDateChange"
          />
          <date-picker
            class="flex-grow-0 flex-shrink-0"
            style="flex-basis:200px;"
            :value="endDate"
            :label="$t('chart.endDate')"
            @input="onEndDateChange"
          />
        </div>
      </div>
    <!-- <v-spacer/> -->
    </div>
  </div>
</template>

<script>
import DatePicker from './DatePicker'
import moment from 'moment'

export default {
  components: {
    DatePicker
  },
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    }
  },
  methods: {
    setTimeRange(range) {
      const startDate = moment().add(-1, range)
      const endDate = moment()

      this.$emit('change', {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
      })
      /*this.$emit('on-time-range-change', {
        startDate: this.startDate,
        endDate: this.endDate
      })*/
    },
    onEndDateChange(dt) {
      console.log(dt)
      this.$emit('change', {
        startDate: this.startDate,
        endDate: dt
      })
    },
    onStartDateChange(dt) {
      console.log(dt)
      this.$emit('change', {
        startDate: dt,
        endDate: this.endDate
      })
    }
  }

}
</script>
