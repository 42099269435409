import Api from '@/services/Api';
//import store from '@/store';
//User api cals
export default {
  getDiscussionSubCommentsByCommentsId(params) {
    return Api().get('/api/tieto/discussions-sub-comments', {
      params,
    });
  },
  saveDiscussionSubCommentModification(credintials) {
    return Api().patch(
      '/api/tieto/current-sub-comment-modification',
      credintials,
      {}
    );
  },
  addNewSubComment(credintials) {
    return Api().post('/api/tieto/discussion/sub-comment', credintials, {});
  },
};
