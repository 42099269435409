<!-- Top and sidebar template -->
<template>
  <nav>
    <v-dialog
      v-model="infoDialog"
      max-width="800px"
      max-heigth="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Tietoa sivustosta
        </v-card-title>
        <v-card-text>
          <embed
            src="pdf/info.pdf"
            frameborder="0"
            width="100%"
            height="600px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeModal">Sulje</v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar color="#45a1e6" app class="white--text">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="white--text"
        large
      />

      <v-spacer />
      <v-btn
        text
        color="red lighten-3"
        @click="showAlarms"
        v-show="!activeAlarms.error && activeAlarms.data.length"
      >
        <alarm-amount-indicator :active-alarms="activeAlarms" />
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on">
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="([title, icon, route], i) in userM"
            :key="i"
            :to="route"
            active-class="white"
            link
          >
            <v-list-item-icon><v-icon v-text="icon"/></v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text color="#95e600" @click="showInfo">
        <v-icon large> mdi-information-outline</v-icon>
      </v-btn>
      <v-btn text @click="logout">
        <v-icon right class="white--text" large>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <!--NAvigointi drawer alkaa -->
    <!-- expand-on-hover -->
    <v-navigation-drawer v-model="drawer" dark app color="#45a1e6" width="300">
      <v-layout column>
        <v-flex class="mt-5" align-left>
          <p class="white--text subheading  text-center">
            {{ user.Organization.organizationName }}
          </p>
          <p class="white--text subheading  text-center">
            {{ user.userEmail }}
          </p>
        </v-flex>
        <v-flex class="mt-1 mb-2">
          <v-divider color="white" />
        </v-flex>
      </v-layout>
      <!-- Drop-down -->
      <v-list class="text-left">
        <v-list-item to="/admin/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Etusivu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--Home link-->
        <!-- <v-list-item-group>
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('main.dashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group> -->
        <!--Home link end -->
        <!--Organization group -->
        <v-list-group
          :value="false"
          prepend-icon="mdi-home-city"
          v-if="user.userRoles === 'Pääylläpitäjä'"
        >
          <template v-slot:activator>
            <v-list-item-title>Organisaatiot</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in organizations"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--User group -->

        <v-list-group
          :value="false"
          prepend-icon="mdi-account-box-multiple"
          v-if="user.userRoles !== 'Käyttäjä'"
        >
          <template v-slot:activator>
            <v-list-item-title>Käyttäjät</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in users"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!--Targets group -->
        <v-list-group
          :value="false"
          prepend-icon="mdi-home-variant-outline"
          v-if="user.userRoles !== 'Käyttäjä'"
        >
          <template v-slot:activator>
            <v-list-item-title>Kohteet</v-list-item-title>
          </template>

          <v-list-item
            v-for="([title, icon, route], i) in targets"
            :key="i"
            link
            :to="route"
            sub-group
          >
            <v-list-item-icon>
              <v-icon v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--Alarms group end -->
      </v-list>
      <template v-slot:append>
        <v-divider color="white" />
        <v-list class="bottom">
          <v-list-item to="/main">
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sovellukset</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/tieto/dashboard"
            v-if="user.userPermissions.includes('1')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tieto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/vainu/dashboard"
            v-if="user.userPermissions.includes('2')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vainu</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/materiaalipankki/dashboard"
            v-if="user.userPermissions.includes('3')"
          >
            <v-list-item-icon>
              <v-icon>fa-chevron-circle-right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Materiaalipankki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider color="white" />
        </v-list>
      </template>

      <!-- Drop-down end -->
    </v-navigation-drawer>
    <!--NAvigointi drawer alkaa -->
  </nav>
</template>

<script>
//import Popup from './Popup.vue';
import AlarmAmountIndicator from '../../Vainu/Alarms/AlarmAmountIndicator.vue';
export default {
  components: { AlarmAmountIndicator },
  data: () => ({
    name: 'navbar',
    user: [],
    drawer: true,
    infoDialog: false,
    userM: [
      ['Vaihda salasana', 'mdi-lock', '/admin/change-password'],
      ['Muokkaa tietoja', 'mdi-account', '/admin/show-user'],
    ],
    users: [
      ['Lisää uusi käyttäjä', '', '/Admin/add-new-user'],
      ['Näytä käyttäjät', '', '/Admin/show-users'],
      ['Lisää käyttäjän kohteet', '', '/Admin/add-targets-to-user'],
      ['Muuta käyttäjän salasana', '', '/Admin/change-user-password'],
      [
        'Luo rekisteröinti koodi',
        'mdi-aplha-c',
        '/Admin/generate-registeration-code',
      ],
    ],
    organizations: [
      ['Lisää uusi organisaatio', '', '/Admin/add-new-organization'],
      ['Näytä organisaatiot', '', '/Admin/show-organizations'],
    ],
    targets: [
      ['Lisää uusi kohde', '', '/Admin/add-new-target'],
      ['Näytä kohteet', '', '/Admin/show-targets'],
    ],
  }),
  computed: {
    activeAlarms() {
      return this.$store.getters.activeAlarms;
    },
  },
  //When page is loaded
  created() {
    this.user = this.$store.state.user;
  },
  methods: {
    /**
     * User click logout button
     * Set token null
     * Set user null
     * */
    // getInfo() {
    //   let url = window.location.href;
    //   let addNewOrganization = url.includes('/Admin/add-new-organization');
    //   let showOrganizations = url.includes('/Admin/show-organizations');
    //   let addNewUser = url.includes('/Admin/add-new-user');
    //   let showUsers = url.includes('/Admin/show-users');
    //   let changeUserPassword = url.includes('/Admin/change-user-password');
    //   let addNewTarget = url.includes('/Admin/add-new-target');
    //   let showTargets = url.includes('/Admin/show-targets');

    //   if (addNewOrganization) {
    //     return `Sopimusnumero muodostuu vuosi, kuukausi, juokseva kolminumeroinen luku (esim 202104001). Alue määräytyy organisaation osoitteen maakunnan mukaan.`;
    //   } else if (showOrganizations) {
    //     return 'Voit järjestellä tietoja napattamalla otsikkoa. Ottaessasi passiivisen organisaation käyttöön varmista että kaikki tiedot ovat oikeita.';
    //   } else if (addNewUser) {
    //     return 'Sähköposti on käyttäjätunnus. Käyttäjä voi kuulua vain yhteen organisaatioon, kohteita voi olla useita. Rooli määrittää ….. Käyttöoikeudet määrittää …. En osaa vielä kirjoittaa mitä???';
    //   } else if (showUsers) {
    //     return 'Tällä sivulla näet kaikki dokumentit liittyn omaan organisaatioosi';
    //   } else if (changeUserPassword) {
    //     return '';
    //   } else if (addNewTarget) {
    //     return 'Tällä sivulla näet kaikki dokumentit liittyn omaan organisaatioosi';
    //   } else if (showTargets) {
    //     return 'Tällä sivulla näet kaikki dokumentit liittyn omaan organisaatioosi';
    //   } else {
    //     return 'Organisaatiot: Ennen uuden organisaation luontia tarkista ”näytä organisaatio” -listasta onko organisaatio jo luotu. Käytä tiedoissa aina sovittua kirjoitustapaa. Kohteet: Ennen uuden kohteen luontia tarkista ”näytä kohteet” -listasta onko kohde jo luotu. Käytä tiedoissa aina sovittua kirjoitustapaa.';
    //   }
    // },
    showAlarms() {
      this.$router.push('/vainu/show-alarms');
    },
    showInfo() {
      this.infoDialog = true;
    },
    closeModal() {
      this.infoDialog = false;
    },
    logout() {
      this.$store.dispatch('setToken', null);
      this.$store.dispatch('setUser', null);
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style scoped>
.v-list .v-list-item-title--active,
.v-list .v-list-item--active .v-icon {
  color: white;
}
.bottom {
  margin-bottom: 10%;
  width: 100%;
}
</style>
