<!-- Register template -->
<template>
  <v-app>
    <nav-bar-user-register />

    <v-main class="ma-4">
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex xs12 md8>
            <v-alert :value="true" dense outlined type="error" v-if="error">
              {{ error }}
            </v-alert>
            <v-alert :value="true" dense outlined type="success" v-if="message">
              {{ message }}
            </v-alert>
            <v-form ref="form" lazy-validation>
              <v-container py-0>
                <v-layout wrap>
                  <v-flex xs12 md12><h2>Vaihda uusi salasana</h2></v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      :label="$t('register.email')"
                      prepend-icon="mdi-email"
                      :rules="emailRules"
                      v-model="registerValues.userEmail"
                      autocomplete="off"
                    />
                  </v-flex>

                  <v-flex xs12 text-center>
                    <v-btn
                      class="mx-0 font-weight-light"
                      color="general"
                      @click.prevent="changePassword"
                    >
                      Vaihda salasana
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
import FooterBar from '@/components/Main/Footer';
import NavBarUserRegister from '@/components/Main/NavBarUserRegister';

export default {
  components: {
    NavBarUserRegister,
    FooterBar,
  },
  data() {
    return {
      reapeatPasswordShow: false,
      passwordShow: false,
      registerValues: {
        userEmail: '',
      },
      userPasswordRepeat: '',
      message: null,
      organizations: [],
      error: null,
      emailRules: [
        (v) => !!v || 'Sähköposti on pakollinen tieto',
        (v) => {
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(v) || 'Sähköposti ei ole kelvolline';
        },
      ],
      passwordRules: [
        (v) => !!v || 'Salasana on pakollinen',
        (v) => (v && v.length >= 8) || 'Salasanan oltava vähintään 8 merkkiä',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Salasana on pakollinen',
        (v) =>
          v === this.registerValues.userPassword || 'Salasanat eivät täsmää',
      ],
    };
  },
  methods: {
    //Check if given password and passwordRepeat are same

    //Register button is clicked
    async changePassword() {
      if (this.$refs.form.validate()) {
        try {
          const response = await AuthenticationService.changePasswordAndSendConfirmEmail(
            this.registerValues
          );
          this.$refs.form.reset();
          this.message = response.data.message;
          this.error = response.data.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      } else {
        this.error = 'Tarkasta annetut tiedot';
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      }
    },
  },
};
</script>
