import Api from '@/services/Api';

//User api cals
export default {
  getAllQuestionGroups() {
    return Api().get('/api/tieto/questiongroups', {});
  },
  saveQuestionGroupModification(credintials) {
    return Api().patch('/api/tieto/questiongroup', credintials, {});
  },
  addNewQuestionGroup(credintials) {
    return Api().post('/api/tieto/questiongroup', credintials, {});
  },
};
