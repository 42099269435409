<!-- Show users data-table -->
<template>
  <v-data-table
    :headers="headers"
    :items="organizations"
    sort-by="organizations"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <!-- Show success/error messages -->
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Organisaatiot</v-toolbar-title>

        <v-spacer />
        <!-- Edit organization modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationBusinessId"
                      :label="$t('showOrganizations.organizationBusinessId')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationName"
                      :label="$t('showOrganizations.organizationName')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationAddress"
                      :label="$t('showOrganizations.organizationAddress')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationPostalDistrict"
                      :label="
                        $t('showOrganizations.organizationPostalDistrict')
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationZipCode"
                      :label="$t('showOrganizations.organizationZipCode')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationArea"
                      :label="$t('showOrganizations.organizationArea')"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editOrganizations.organizationContractNumber"
                      :label="
                        $t('showOrganizations.organizationContractNumber')
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon=""
                      v-model="editOrganizations.OrganizationApplications"
                      :items="applications"
                      :label="$t('showOrganizations.applications')"
                      placeholder="Valitse..."
                      item-text="applicationName"
                      item-value="applicationId"
                      multiple
                      chips
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon=""
                      v-model="editOrganizations.organizationState"
                      :items="states"
                      :label="$t('showOrganizations.organizationState')"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="value"
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editOrganizations.organizationInformation"
                      :label="$t('showOrganizations.organizationInformation')"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      :label="$t('showOrganizations.organizationDescription')"
                      v-model="editOrganizations.organizationDescription"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                Peruuta
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Talleta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import OrganizationService from '@/services/OrganizationService.js';
import ApplicationService from '@/services/ApplicationService.js';

export default {
  data() {
    return {
      organizations: [],
      applications: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: this.$t('showOrganizations.organizationTitle'),
      headers: [
        {
          text: this.$t('showOrganizations.organizationBusinessId'),
          value: 'organizationBusinessId',
        },
        {
          text: this.$t('showOrganizations.organizationContractNumber'),
          value: 'organizationContractNumber',
        },
        {
          text: this.$t('showOrganizations.organizationName'),
          value: 'organizationName',
        },
        {
          text: this.$t('showOrganizations.organizationAddress'),
          value: 'organizationAddress',
        },
        {
          text: this.$t('showOrganizations.organizationPostalDistrict'),
          value: 'organizationPostalDistrict',
        },

        {
          text: this.$t('showOrganizations.organizationZipCode'),
          value: 'organizationZipCode',
        },
        {
          text: this.$t('showOrganizations.organizationArea'),
          value: 'organizationArea',
        },
        {
          text: this.$t('showOrganizations.organizationState'),
          value: 'organizationState',
        },

        {
          text: this.$t('showOrganizations.organizationActions'),
          value: 'actions',
          sortable: false,
        },
      ],

      states: [
        {
          name: this.$t('showOrganizations.organizationStateItems.active'),
          value: 'Aktiivinen',
        },
        {
          name: this.$t('showOrganizations.organizationStateItems.passive'),
          value: 'Passiivinen',
        },
      ],
      editOrganizations: {
        organizationBusinessId: '',
        organizationAddress: '',
        organizationPostalDistrict: '',
        organizationZipCode: '',
        organizationArea: '',
        organizationContractNumber: '',
        organizationDescription: '',
        organizationInformation: '',
        organizationState: '',
        organizationActions: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show organization when page is loaded
   */
  async created() {
    const response = await OrganizationService.getAllOrganizations();
    this.organizations = response.data.organizations;
  },

  methods: {
    // Add items to editOrganizations when pencil iscon is clicked
    async editItem(item) {
      this.editOrganizations = Object.assign({}, item);
      const applications = await ApplicationService.getAllApplications();
      this.applications = applications.data.applications;
      this.dialog = true;
    },
    // close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editOrganizations = Object.assign({}, this.organizations);
      });
    },
    /**
     * Save button is clicked from modal
     */
    async save() {
      try {
        let applicationArr = [];
        if (this.editOrganizations.OrganizationApplications[0].id) {
          for (
            let i = 0;
            i < this.editOrganizations.OrganizationApplications.length;
            i++
          ) {
            applicationArr.push(
              this.editOrganizations.OrganizationApplications[i].applicationId
            );
          }
          this.editOrganizations.OrganizationApplications = applicationArr;
        }
        const response = await OrganizationService.saveOrganizationModification(
          this.editOrganizations
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const organizations = await OrganizationService.getAllOrganizations();
        this.organizations = organizations.data.organizations;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
