<!-- Show users data-table -->
<template>
  <v-data-table
    :headers="headers"
    :items="targets"
    sort-by="targets"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <!-- Show success/error messages -->
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Kohteet</v-toolbar-title>

        <v-spacer />
        <!-- Edit organization modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetName"
                      label="Kohteen nimi"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetAddress"
                      label="Osoite"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetZipCode"
                      label="Postinumero"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetPostalDistrict"
                      label="
                       Postitoimipaikka
                      "
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.TargetContactUsers"
                      :items="organizationUsers"
                      label="Yhteyshenkilöt"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="userId"
                      multiple
                      chips
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetType"
                      label="Kohteen tyyppi"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetPropertyId"
                      label="
                        Kohteen tunnus
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetJobNumber"
                      label="
                        Kohteen työnumero
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editTargets.targetState"
                      :items="targetStates"
                      label="Kohteen tila"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="value"
                    />
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editTargets.targetInformation"
                      label="Info"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Kuvaus"
                      v-model="editTargets.targetDescription"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                Peruuta
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Talleta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import TargetService from '@/services/TargetService.js';
import UserService from '@/services/UserService.js';
import TargetContactUserService from '@/services/TargetContactUserService.js';

export default {
  data() {
    return {
      user: {},
      oldContactUsers: {},
      targets: [],
      contactUsers: [],
      organizationUsers: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: 'Kohteet',
      removeItem: '',
      headers: [
        {
          text: 'Kohteen nimi',
          value: 'targetName',
        },
        {
          text: 'Osoite',
          value: 'targetAddress',
        },
        {
          text: 'Postinumero',
          value: 'targetZipCode',
        },
        {
          text: 'Postitoimipaikka',
          value: 'targetPostalDistrict',
        },
        {
          text: 'Organisaatio',
          value: 'Organization.organizationName',
        },

        {
          text: 'Kohteen tila',
          value: 'targetState',
        },

        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],

      targetStates: [
        {
          name: 'Aktiivinen',
          value: 'Aktiivinen',
        },
        {
          name: 'Passiivinen',
          value: 'Passiivinen',
        },
      ],
      editTargets: {},
      organizations: [],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show organization when page is loaded
   */
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.user.userRoles === 'Pääylläpitäjä') {
      const targets = await TargetService.getAllTargets();
      this.targets = targets.data.targets;
    } else {
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.user.Organization.organizationId,
      });
      this.targets = targets.data.targets;
    }
  },

  methods: {
    // Add items to editTargets when pencil iscon is clicked
    async editItem(item) {
      this.removeItem = item.targetId;
      const organizationUsers = await UserService.getAllOrganizationUsers({
        organizationId: item.Organization.organizationId,
      });

      this.organizationUsers = organizationUsers.data.users;
      for (let i = 0; i < this.organizationUsers.length; i++) {
        this.organizationUsers[i].userId = this.organizationUsers[i].uuid;
        this.organizationUsers[i].name =
          this.organizationUsers[i].userFirstName +
          ' ' +
          this.organizationUsers[i].userLastName;
      }

      const Users = await TargetContactUserService.getAllUsersByTargets({
        targetId: item.targetId,
      });

      this.contactUsers = Users.data.users;
      this.editTargets = Object.assign({}, item);
      this.dialog = true;
    },
    // close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editTargets = Object.assign({}, this.targets);
      });
    },
    /**
     * Save button is clicked from modal
     */
    async save() {
      try {
        let userArr = [];
        if (this.editTargets.TargetContactUsers[0].id) {
          for (let i = 0; i < this.editTargets.TargetContactUser.length; i++) {
            userArr.push(this.this.editTargets.TargetContactUser[i].userId);
          }
          this.editTargets.TargetContactUser = userArr;
        }
        const removeOldValues = await TargetContactUserService.removeTargetModification(
          {
            targetId: this.removeItem,
          }
        );
        this.error = removeOldValues.error;
        const response = await TargetService.saveTargetModification(
          this.editTargets
        );

        this.message = response.data.message;
        this.error = response.data.error;
        const targets = await TargetService.getAllTargets();
        this.targets = targets.data.targets;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
