<!-- Add new organization template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12>
                <h3>{{ $t('deviceToUser.addNewDeviceToUser') }}</h3>
              </v-flex>
              <v-flex
                xs12
                md6
                class="margin-fix"
                v-if="user.userRoles === 'Pääylläpitäjä'"
              >
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="deviceToUserValues.organizationId"
                  :items="organizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  :label="$t('deviceToUser.organizationId')"
                  placeholder="Valitse..."
                  :rules="option"
                  @change="showUsers"
                />
              </v-flex>
              <v-flex xs12 md6 class="margin-fix">
                <v-autocomplete
                  prepend-icon="mdi-account"
                  v-model="deviceToUserValues.deviceUserId"
                  :items="users"
                  item-text="fullName"
                  item-value="uuid"
                  :label="$t('deviceToUser.userId')"
                  placeholder="Valitse..."
                  :rules="option"
                  @change="showTargets"
                />
              </v-flex>
              <v-flex xs12 md6 class="margin-fix">
                <v-autocomplete
                  prepend-icon="mdi-home-city"
                  v-model="deviceToUserValues.targetId"
                  :items="targets"
                  item-text="Target.targetName"
                  item-value="Target.targetId"
                  :label="$t('deviceToUser.targetId')"
                  placeholder="Valitse..."
                  :rules="option"
                  @change="showDevices"
                />
              </v-flex>
              <v-flex xs12 md6 class="margin-fix">
                <v-autocomplete
                  prepend-icon="mdi-gauge"
                  v-model="deviceToUserValues.deviceId"
                  :items="devices"
                  item-text="deviceId"
                  item-value="deviceId"
                  :label="$t('deviceToUser.deviceId')"
                  placeholder="Valitse..."
                  :rules="option"
                  @change="showMeasurements"
                />
              </v-flex>
              <v-flex xs12 md6 class="margin-fix">
                <v-autocomplete
                  prepend-icon="mdi-gauge"
                  v-model="deviceToUserValues.deviceMeasurements"
                  :items="measurements"
                  item-text="measurementName"
                  item-value="measurementID"
                  :label="$t('deviceToUser.measurements')"
                  placeholder="Valitse..."
                  :rules="option"
                  @change="showMeasurements"
                  multiple
                  chips
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="deviceToUserValues.measurementsState"
                  :items="measurementsState"
                  item-text="name"
                  item-value="value"
                  :label="$t('deviceToUser.state')"
                  placeholder="Valitse..."
                  :rules="option"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addDeviceToUser"
                >
                  {{ $t('deviceToUser.addDeviceToUser') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OrganizationService from '@/services/OrganizationService.js';
import TargetService from '@/services/TargetService';
import DeviceService from '@/services/Vainu/DeviceService';
import DeviceTypeService from '@/services/Vainu/DeviceTypeService';
import UserDeviceService from '@/services/Vainu/UserDeviceService';
import UserService from '@/services/UserService';

export default {
  data() {
    return {
      measurementsState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],

      deviceToUserValues: {},
      organizations: [],
      users: [],
      targets: [],
      user: {},
      devices: [],
      measurements: [],
      message: null,
      error: null,
      targetOrganizationId: null,
      organizationId: null,
      targetUserId: null,

      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const organizations = await OrganizationService.getAllOrganizations();
      this.organizations = organizations.data.organizations;
      const users = await UserService.getAllOrganizationUsers({
        organizationId: this.user.Organization.organizationId,
      });
      this.users = users.data.users;
      this.message = organizations.data.message;
      this.error = organizations.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },
  methods: {
    async showUsers() {
      try {
        const users = await UserService.getAllOrganizationUsers({
          organizationId: this.deviceToUserValues.organizationId,
        });
        this.users = users.data.users;
        this.message = users.data.message;
        this.error = users.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async showTargets() {
      try {
        this.targetUserId = this.deviceToUserValues.deviceUserId;
        const targets = await TargetService.getAllUserTargets({
          userId: this.targetUserId,
        });
        this.targets = targets.data.targets;

        this.message = targets.data.message;
        this.error = targets.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async showDevices() {
      try {
        const devices = await DeviceService.getAllTargetDevices({
          deviceTargetId: this.deviceToUserValues.targetId,
        });
        this.devices = devices.data.devices;
        this.message = devices.data.message;
        this.error = devices.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async showMeasurements() {
      const deviceId = this.devices.filter(
        (el) => el.deviceId === this.deviceToUserValues.deviceId
      );

      try {
        const measurement = await DeviceTypeService.getAllDeviceMeasurements({
          deviceTypeId: deviceId[0].deviceTypeId,
        });
        const measurements = measurement.data.deviceTypes;

        this.measurements = measurements[0].Measurements;
        this.message = measurement.data.message;
        this.error = measurement.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async addDeviceToUser() {
      let result = [];
      let measurements = Object.values(
        this.deviceToUserValues.deviceMeasurements
      );
      if (this.$refs.form.validate()) {
        if (this.user.userRoles !== 'Pääylläpitäjä') {
          this.deviceToUserValues.organizationId = this.user.Organization.organizationId;
        }
        for (let i = 0; i < measurements.length; i++) {
          for (let y = 0; y < this.measurements.length; y++) {
            if (this.measurements[y].measurementID === measurements[i]) {
              result.push({ [measurements[i]]: true });
              measurements.splice(i, 1);
            } else {
              result.push({ [this.measurements[y].measurementID]: false });
            }
          }
        }
        this.deviceToUserValues.deviceMeasurements = result;

        try {
          const response = await UserDeviceService.addNewUserDevice(
            this.deviceToUserValues
          );
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 100000000);
        }
      }
    },
  },
};
</script>
<style scoped>
.margin-fix {
  margin-top: -10px;
}
</style>
