import Api from '@/services/Api';

//User api cals
export default {
  getDeviceHistory(params) {
    return Api().get('/api/device-history', { params });
  },
  getDeviceHistoryLimits(params) {
    return Api().get('/api/device-history-limits', { params });
  },
  addNewDeviceHistory(credintials) {
    return Api().post('/api/device-history', credintials, {});
  },
};
