var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"mt-1",attrs:{"items":_vm.targets,"item-text":"Target.targetName","item-value":"Target.targetId","label":"Valitse kohde:","props":{
            color: '#ffffff',
          }},on:{"change":_vm.changeQuery},model:{value:(_vm.userTargets),callback:function ($$v) {_vm.userTargets=$$v},expression:"userTargets"}})],1)],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-card-text',[_c('h3',[_c('p',[_vm._v(" Tervetuloa kyselyyn! Vastaa kaikkiin kysymyksiin ja kirjoita kommenttisi tyhjiin kenttiin. Lopuksi lähetä kysely. ")]),_c('p',[_vm._v(" Sinua pyydetään vielä tarkistamaan vastauksesi. Jos olet varma vastauksistasi lähetä vastaukset. ")])])])],1)]}}])})],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.query.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.queryTitle)+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.queryDescription))])],1)]}}],null,false,723035481)})],1):_vm._e(),_vm._l((_vm.questionGroups),function(group,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-2 pa-2",attrs:{"elevation":hover ? 30 : 6}},[_c('v-card-title',[_c('h3',[_vm._v(" "+_vm._s(group.description)+" ")])]),_c('v-card-text',[_c('h4',[_vm._v(" "+_vm._s(group.group)+" ")])]),_vm._l((_vm.questions),function(question){return _c('div',{key:question.id},[(
                    group.group ===
                      question.TietoQuestionGroup.queryQuestionGroupDescrption
                  )?_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[_c('v-list-item-content',[_vm._v(" "+_vm._s(question.questionDescription)+" "),_c('v-list-item-title',[_c('v-radio-group',{model:{value:(_vm.answers[question.id]),callback:function ($$v) {_vm.$set(_vm.answers, question.id, $$v)},expression:"answers[question.id]"}},[_c('v-radio',{attrs:{"label":"Täysin eri mieltä","value":"1"}}),_c('v-radio',{attrs:{"label":"Osittain eri mieltä","value":"2"}}),_c('v-radio',{attrs:{"label":"Ei samaa, eikä eri mieltä","value":"3"}}),_c('v-radio',{attrs:{"label":"Osittain samaa mieltä","value":"4"}}),_c('v-radio',{attrs:{"label":"Täysin samaa mieltä","value":"5"}})],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"end"}})],1)],1):_vm._e()],1)})],2)]}}],null,true)})],1)}),(_vm.query.length > 0)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-2 pa-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-card-actions',[_c('v-textarea',{attrs:{"label":"Kommentti"},model:{value:(_vm.queryComment),callback:function ($$v) {_vm.queryComment=$$v},expression:"queryComment"}})],1)],1)]}}],null,false,4267390177)})],1):_vm._e(),[(_vm.query.length > 0)?_c('v-flex',{staticClass:"my-5",attrs:{"text-center":"","xs12":"","md12":""}},[_c('v-btn',{attrs:{"color":"grey lighten-2","dense":""},on:{"click":_vm.showDialog}},[_vm._v(" Lähetä kysely ")])],1):_vm._e()]],2),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Kiitos kyselyyn osallistumisesta! ")]),_c('v-card-text',[_c('p',[_vm._v("Olet nyt syöttänyt kaikki vastaukset.")]),_c('p',[_vm._v(" Mikäli haluat muuttaa vielä vastauksiasi niin paina Muokkaa vastauksiasi ")]),_c('p',[_vm._v(" Ole hyvä ja lähetä vastausket painamlla Lähetä vastaukset. ")]),_c('p',[_vm._v("Tämän jälkeen et voi enää muokata vastauksiasi")]),_vm._v(" . ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Muokkaa vastauksiasi ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveSurvey}},[_vm._v(" Lähetä vastaukset ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }