<!-- Main view when user is logged in -->
<template>
  <v-app>
    <vainu-nar-bar />

    <v-main class="ma-4">
      <router-view name="vainuview"/>
    </v-main>
    <footer-bar />
  </v-app>
</template>
<script>
import VainuNarBar from '@/components/Vainu/Menu/VainuNavBar';
import FooterBar from '@/components/Vainu/Menu/Footer';

export default {
  components: {
    VainuNarBar,
    FooterBar,
  },
  data() {
    return {
      username: '',
    };
  },
  mounted() {
  },
  async created() {
    this.username = this.$store.getters.getUser.username;
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
