<template>
  <v-app>
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex xs12 md8>
          <v-alert :value="true" dense outlined type="error" v-if="error">
            {{ error }}
          </v-alert>
          <v-alert :value="true" dense outlined type="success" v-if="message">
            {{ message }}
          </v-alert>

          <v-form ref="form" lazy-validation>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12 text-left>
                  <h4>Luo käyttäjän rekisteröinti koodi</h4>
                </v-flex>
                <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                  <v-autocomplete
                    prepend-icon="mdi-account-supervisor"
                    v-model="generateCode.userOrganization"
                    :items="organizations"
                    :label="$t('register.organization')"
                    placeholder="Valitse..."
                    item-text="organizationName"
                    item-value="organizationId"
                    @change="showTargets"
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-autocomplete
                    prepend-icon="mdi-home-city"
                    v-model="generateCode.userTargets"
                    :items="targets"
                    label="Kohde"
                    placeholder="Valitse..."
                    item-text="targetName"
                    item-value="targetId"
                    no-data-text="Tietoa ei saatavilla"
                    multiple
                  />
                </v-flex>
                <v-flex xs11 md11>
                  <v-text-field
                    v-if="showCodeInput"
                    label="Koodi"
                    v-model="code"
                    autocomplete="off"
                    @click="copyToClipBoard"
                  />
                </v-flex>
                <v-flex xs1 md1 v-if="showCodeInput">
                  <v-icon @click="copyToClipBoard">mdi-content-copy</v-icon>
                </v-flex>
                <v-flex xs12 text-xs-right>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="showCode"
                  >
                    Luo koodi
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import OrganizationService from '@/services/OrganizationService.js';
import TargetService from '@/services/TargetService.js';

export default {
  data() {
    return {
      message: null,
      error: null,
      generateCode: {},
      user: {},
      targets: [],
      organizations: [],
      code: '',
      showCodeInput: false,
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const organizations = await OrganizationService.getAllOrganizations();
      this.organizations = organizations.data.organizations;
      if (
        this.user.userRoles === 'Ylläpitäjä' ||
        this.user.userRoles === 'Pääylläpitäjä'
      ) {
        const targets = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.user.Organization.organizationId,
        });
        this.targets = targets.data.targets;
      }
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async showTargets() {
      try {
        if (this.user.userRoles === 'Pääylläpitäjä') {
          this.targetOrganizationId = this.generateCode.userOrganization;
        } else {
          this.targetOrganizationId = this.user.Organization.organizationId;
        }
        const targets = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.targetOrganizationId,
        });
        this.targets = targets.data.targets;
        this.message = targets.data.message;
        this.error = targets.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.code);
    },
    showCode() {
      this.showCodeInput = true;

      if (
        this.user.userRoles === 'Ylläpitäjä' ||
        this.user.userRoles === 'Pääkäyttäjä'
      ) {
        this.code =
          this.user.Organization.organizationId +
          '.' +
          this.generateCode.userTargets;
      } else {
        this.code =
          this.generateCode.userOrganization +
          '.' +
          this.generateCode.userTargets;
      }
    },
  },
};
</script>
