<!-- Register template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12>
                <h2>Lisää uusi mittari</h2>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.id')"
                  prepend-icon="mdi-gauge"
                  :rules="required"
                  v-model="deviceValues.deviceId"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.qrCode')"
                  prepend-icon="mdi-qrcode"
                  :rules="required"
                  v-model="deviceValues.deviceQrCode"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.name')"
                  prepend-icon="mdi-alphabetical"
                  :rules="required"
                  v-model="deviceValues.deviceName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-devices"
                  v-model="deviceValues.deviceTypeId"
                  :items="deviceTypes"
                  :label="$t('device.typeId')"
                  placeholder="Valitse..."
                  item-text="deviceTypeName"
                  item-value="deviceTypeId"
                  :rules="option"
                  @change="showDeviceMeasurementMinMax"
                />
              </v-flex>
              <v-flex
                xs6
                md6
                v-for="(meas, index) in measurements"
                :key="index"
              >
                <v-text-field
                  v-if="showMinMax"
                  :label="`${meas.measurementName} Min:`"
                  prepend-icon="mdi-alphabetical"
                  :rules="required"
                  v-model="deviceValuesMin[meas.measurementID]"
                  autocomplete="off"
                />
                <v-text-field
                  v-if="showMinMax"
                  :label="`${meas.measurementName} Max:`"
                  prepend-icon="mdi-alphabetical"
                  :rules="required"
                  v-model="deviceValuesMax[meas.measurementID]"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.serialnumber')"
                  prepend-icon="mdi-numeric"
                  :rules="required"
                  v-model="deviceValues.deviceSerialNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedComissioiningDay"
                      :label="$t('device.comissioiningDay')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      autocomplete="off"
                      :rules="required"
                    />
                  </template>
                  <v-date-picker
                    v-model="deviceValues.deviceComissioiningDay"
                    :first-day-of-week="1"
                    locale="fi-FI"
                    @input="menu1 = false"
                    no-title
                  />
                </v-menu>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.location')"
                  prepend-icon="mdi-home-variant "
                  :rules="required"
                  v-model="deviceValues.deviceLocation"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-home-city"
                  v-model="deviceValues.deviceOrganizationId"
                  :items="organizations"
                  :label="$t('device.organization')"
                  placeholder="Valitse..."
                  item-text="organizationName"
                  item-value="organizationId"
                  :rules="option"
                  @change="showTargets"
                  no-data-text="Tietoa ei saatavilla"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-home-outline"
                  v-model="deviceValues.deviceTargetId"
                  :items="targets"
                  :label="$t('device.target')"
                  placeholder="Valitse..."
                  item-text="targetName"
                  item-value="targetId"
                  :rules="option"
                  no-data-text="Tietoa ei saatavilla"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.geoInfo')"
                  prepend-icon="mdi-map-marker"
                  :rules="required"
                  v-model="deviceValues.deviceGeoInfo"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.swVersion')"
                  prepend-icon="mdi-counter"
                  :rules="required"
                  v-model="deviceValues.deviceSwVersion"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('device.productCode')"
                  prepend-icon="mdi-counter"
                  :rules="required"
                  v-model="deviceValues.deviceProductCode"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="deviceValues.deviceState"
                  :items="deviceState"
                  item-text="name"
                  item-value="value"
                  :label="$t('device.state')"
                  placeholder="Valitse..."
                  :rules="required"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('device.description')"
                  prepend-icon="mdi-information-variant"
                  v-model="deviceValues.deviceDescription"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addDevice"
                >
                  {{ $t('device.addDevice') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TargetService from '@/services/TargetService';
import OrganizationService from '@/services/OrganizationService';
import DeviceService from '@/services/Vainu/DeviceService';
import DeviceTypeService from '@/services/Vainu/DeviceTypeService';
import MeasurementService from '@/services/Vainu/MeasurementService';
import AlarmLimitService from '@/services/Vainu/AlarmLimitService.js';

export default {
  data() {
    return {
      deviceState: [
        { name: 'Käytössä', value: 'Käytössä' },
        { name: 'Pois käytöstä', value: 'Pois käytöstä' },
      ],
      showMinMax: false,
      deviceTypes: [],
      deviceValues: {},
      deviceValuesMax: [],
      deviceValuesMin: [],
      menu1: false,
      message: null,
      organizations: [],
      measurements: [],
      targets: [],
      user: {},
      error: null,
      required: [(v) => !!v || 'Pakollinen kenttä'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  computed: {
    computedComissioiningDay() {
      return this.formatDate(this.deviceValues.deviceComissioiningDay);
    },
  },
  async created() {
    this.user = this.$store.getters.getUser;
    this.deviceValues.deviceQrCode = this.$route.query.id;

    try {
      const organizations = await OrganizationService.getAllOrganizations();
      this.organizations = organizations.data.organizations;
      const deviceTypes = await DeviceTypeService.getAllDeviceTypes();
      this.deviceTypes = deviceTypes.data.deviceTypes;
      this.message = organizations.data.message;
      this.error = organizations.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.organizations.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },

  methods: {
    async showDeviceMeasurementMinMax() {
      this.showMinMax = true;

      const deviceId = this.deviceTypes.filter(
        (el) => el.deviceTypeId === this.deviceValues.deviceTypeId
      );

      try {
        this.deviceValuesMin = [];
        this.deviceValuesMax = [];
        const measurement = await MeasurementService.getAllTMeasurementsByDeviceId(
          {
            deviceTypeId: deviceId[0].id,
          }
        );
        this.measurements = measurement.data.measurements;
        for (let i = 0; i < this.measurements.length; i++) {
          this.deviceValuesMin[
            this.measurements[i].measurementID
          ] = this.measurements[i].measurementMinDefault;
          this.deviceValuesMax[
            this.measurements[i].measurementID
          ] = this.measurements[i].measurementMaxDefault;
        }
        console.log(this.deviceValuesMin);
        this.error = measurement.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.measurement.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    //Check if given password and passwordRepeat ase same
    async showTargets() {
      try {
        this.targetOrganizationId = this.deviceValues.deviceOrganizationId;
        const response = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.targetOrganizationId,
        });
        this.targets = response.data.targets;
        this.message = response.data.message;
        this.error = response.error;
        this.search = '';
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    //addDevice button is clicked
    async addDevice() {
      if (this.$refs.form.validate()) {
        try {
          this.deviceValues.comissioiningDay += ' 00:00:00';
          const response = await DeviceService.addNewDevice(this.deviceValues);
          this.error = response.error;
          let deviceId = response.data.lastInsertedId[0];

          let id = Object.values(deviceId);

          let obj2 = [];
          for (let key in this.deviceValuesMax) {
            for (let key2 in this.deviceValuesMin) {
              if (key === key2) {
                let obj = {};
                obj.measurementId = key;
                obj.highLimit = this.deviceValuesMax[key];
                obj.lowLimit = this.deviceValuesMin[key2];
                obj.deviceId = id;
                obj2.push(obj);
              }
            }
          }
          const alarmMin = await AlarmLimitService.addAlarmLimits(obj2);

          this.message = alarmMin.data.message;
          this.error = alarmMin.data.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
            this.measurements = [];
          }, 3000);
        } catch (error) {
          console.log(error.data);
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 30000);
        }
      }
    },
  },
};
</script>
