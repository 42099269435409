import Api from '@/services/Api';
//import store from '@/store';
//User api cals
export default {
  getAllFeedbacks(params) {
    return Api().get('/api/tieto/feedbacks/', { params });
  },

  getAllNewFeedbacks(params) {
    return Api().get('/api/tieto/new-feedbacks/', { params });
  },

  saveFeedbackModification(credintials) {
    return Api().patch('/api/tieto/feedback', credintials, {});
  },
  addNewFeedback(credintials) {
    return Api().post('/api/tieto/feedback/', credintials, {});
  },
  getQueryByCustomerId(credintials) {
    return Api().post('/api/tieto/query-customer-id/', credintials, {});
  },

  getAllOrganizationFeedbacks(params) {
    return Api().get('/api/tieto/organization-feedbacks/', { params });
  },
  getAllTargetFeedbacks(params) {
    return Api().get('/api/tieto/target-feedbacks/', { params });
  },
};
