<!-- Add new organization template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12>
                <h3>{{ $t('organizations.organizationTitle') }}</h3>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationBusinessId')"
                  prepend-icon="mdi-domain"
                  :rules="businesIdRules"
                  v-model="organizationValues.organizationBusinessId"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationContractNumber')"
                  prepend-icon="mdi-file-document-edit-outline"
                  :rules="contractNumberRules"
                  v-model="organizationValues.organizationContractNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationName')"
                  prepend-icon="mdi-home-city-outline"
                  :rules="organizationNameRules"
                  v-model="organizationValues.organizationName"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationAddress')"
                  prepend-icon="mdi-map-marker"
                  :rules="organizationAddressRules"
                  v-model="organizationValues.organizationAddress"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationZipCode')"
                  prepend-icon="mdi-alpha-z-box-outline"
                  :rules="organizationZipCodeRules"
                  v-model="organizationValues.organizationZipCode"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationPostalDistrict')"
                  prepend-icon="mdi-home-map-marker"
                  :rules="organizationPostalDistrictRules"
                  v-model="organizationValues.organizationPostalDistrict"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  :label="$t('organizations.organizationArea')"
                  prepend-icon="mdi-flag"
                  :rules="organizationAreaRules"
                  v-model="organizationValues.organizationArea"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6 class="margin-fix">
                <v-autocomplete
                  prepend-icon="mdi-application"
                  v-model="organizationValues.organizationApplications"
                  :items="applications"
                  item-text="applicationName"
                  item-value="applicationId"
                  label="Sovellusoikeudet"
                  placeholder="Valitse..."
                  required
                  multiple
                  chips
                  :rules="option"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="organizationValues.organizationState"
                  :items="organizationState"
                  item-text="name"
                  item-value="value"
                  :label="$t('organizations.organizationState')"
                  placeholder="Valitse..."
                  :rules="organizationStateRules"
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('organizations.organizationInformation')"
                  prepend-icon="mdi-information-variant"
                  v-model="organizationValues.organizationInformation"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('organizations.organizationDescription')"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="organizationValues.organizationDescription"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewOrganization"
                >
                  {{ $t('organizations.organizationRegisterButton') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import OrganizationService from '@/services/OrganizationService.js';
import ApplicationService from '@/services/ApplicationService.js';
export default {
  data() {
    return {
      organizationState: [
        { name: this.$t('register.state-active'), value: 'Aktiivinen' },
        { name: this.$t('register.state-passive'), value: 'Passiivinen' },
      ],

      organizationValues: {},
      applications: [],
      message: null,
      error: null,
      businesIdRules: [
        (v) => !!v || 'Y-tunnus on pakollinen tieto',
        (v) => (v && v.length == 9) || 'Y-tunnuksessa pitää olla 9 merkkiä',
      ],
      contractNumberRules: [
        (v) => !!v || 'Sopimusnumero on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) ||
          'Sopimusnumerossa pitää olla ainakin 3 merkkiä',
      ],
      organizationNameRules: [
        (v) => !!v || 'Organisaation nimi on pakollinen tieto',
        (v) =>
          (v && v.length >= 2) ||
          'Orhanisaation nimessä pitää olla ainakin 2 merkkiä',
      ],
      organizationAddressRules: [
        (v) => !!v || 'Osoite on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Osoitteessa pitää olla ainakin 3 merkkiä',
      ],
      organizationPostalDistrictRules: [
        (v) => !!v || 'Postitoimipaikka on pakollinen tieto',
        (v) =>
          (v && v.length >= 2) || 'Osoitteessa pitää olla ainakin 2 merkkiä',
      ],
      organizationZipCodeRules: [
        (v) => !!(v || '').match(/[0-9]/g) || 'Postinumero on pakollinen tieto',
        (v) => (v && v.length == 5) || 'Postinumerossa pitää olla 5 numeroa',
      ],
      organizationAreaRules: [
        (v) => !!v || 'Alue on pakollinen tieto',
        (v) => (v && v.length >= 2) || 'Alueessa pitää olla ainakin 2 merkkiä',
      ],
      organizationStateRules: [
        (v) => !!v || 'Organisaation tila on pakollinen tieto',
      ],
      organizationInformationRules: [
        (v) =>
          (v && v.length < 500) || 'Info kentän maksimi merkki määrä on 500',
      ],
      organizationDescriptionRules: [
        (v) =>
          (v && v.length < 500) || 'Kuvaus kentän maksimi merkki määrä on 500',
      ],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    const applications = await ApplicationService.getAllApplications();
    this.applications = applications.data.applications;
  },
  methods: {
    /**
     * Add new button is clicked
     */
    async addNewOrganization() {
      if (this.$refs.form.validate()) {
        try {
          const response = await OrganizationService.addNewOrganization(
            this.organizationValues
          );

          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 100000000);
        }
      }
    },
  },
};
</script>
<style scoped>
.margin-fix {
  margin-top: -10px;
}
</style>
