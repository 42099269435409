<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää kysely</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Otsikko"
                  prepend-icon="mdi-account"
                  v-model="addQueryValues.queryTitle"
                  autocomplete="off"
                  :rules="titleRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus kyselystä"
                  prepend-icon="mdi-information-variant"
                  v-model="addQueryValues.queryDescription"
                  autocomplete="off"
                  :rules="descriptionRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryOrganization"
                  :items="organizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  label="Organisaatio"
                  placeholder="Valitse"
                  :rules="organizationRules"
                  @change="showTargets"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-home-city"
                  v-model="addQueryValues.queryTargets"
                  :items="targets"
                  :label="$t('register.targets')"
                  placeholder="Valitse..."
                  item-text="targetName"
                  item-value="targetId"
                  :rules="option"
                  @change="showTargets"
                  no-data-text="Tietoa ei saatavilla"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryQuestions"
                  :items="questions"
                  item-text="questionDescription"
                  item-value="id"
                  label="Valitse kysymykset"
                  placeholder="Valitse"
                  multiple
                  chips
                  :rules="questionsRules"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addQueryValues.queryState"
                  :items="queryState"
                  item-text="name"
                  item-value="value"
                  label="Kyselyn tila"
                  placeholder="Valitse"
                  :rules="stateRules"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewQuery"
                >
                  Lisää uusi kysely
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import QueryService from '@/services/Tieto/QueryService.js';
import OrganizationService from '@/services/OrganizationService.js';
import QuestionService from '@/services/Tieto/QuestionService.js';
import TargetService from '@/services/TargetService';
export default {
  data() {
    return {
      queryState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],
      organizations: [],
      questions: [],
      addQueryValues: {},
      message: null,
      error: null,
      targets: [],
      titleRules: [
        (v) => !!v || 'Otsikko on pakollinen tieto',
        (v) => (v && v.length >= 3) || 'Otsikon pitää olla ainakin 3 merkkiä',
      ],
      descriptionRules: [
        (v) => !!v || 'Kuvaus on pakollinen tieto',
        (v) => (v && v.length >= 3) || 'Kuvauksen pitää olla ainakin 3 merkkiä',
      ],
      organizationRules: [(v) => !!v || 'Organisaatio pitää valita'],
      questionsRules: [(v) => !!v || 'Kysymykset pitää valita'],
      stateRules: [(v) => !!v || 'Tila pitää valita'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    try {
      const response = await OrganizationService.getAllOrganizations();
      this.organizations = response.data.organizations;
    } catch (error) {
      this.error = error;
    }
    try {
      const response = await QuestionService.getAllQuestions();
      this.questions = response.data.questions;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async showTargets() {
      try {
        const response = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.addQueryValues.queryOrganization,
        });
        this.targets = response.data.targets;
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async addNewQuery() {
      if (this.$refs.form.validate()) {
        try {
          let question = '';
          let editQuestion;
          for (let i = 0; i < this.addQueryValues.queryQuestions.length; i++) {
            question += this.addQueryValues.queryQuestions[i] + ',';
          }
          editQuestion = question.slice(0, -1);
          this.addQueryValues.queryQuestions = editQuestion;
          const response = await QueryService.addNewQuery(this.addQueryValues);

          this.message = response.data.message;
          this.$refs.form.reset();
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
