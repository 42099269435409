import Api from '@/services/Api';

//User api cals
export default {
  getAllQueryResult() {
    return Api().get('/api/tieto/queries/', {});
  },

  addNewQueryResult(credintials) {
    return Api().post('/api/tieto/result/', credintials, {});
  },
  getQueryResultsByQuery(params) {
    return Api().get('/api/tieto/results/', {
      params,
    });
  },
  getAllQueriesQuestionsByQueryId(params) {
    return Api().get('/api/tieto/query-questions/', {
      params,
    });
  },
  getAllResultsByQuery(params) {
    return Api().get('/api/tieto/all-results/', {
      params,
    });
  },
};
