<template>
  <v-data-table
    :headers="headers"
    :items="feedbacks"
    sort-by="feedbacks"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="userTargets"
              :items="targets"
              item-text="Target.targetName"
              item-value="Target.targetId"
              label="Valitse kohde:"
              class="mt-1"
              :props="{
                color: '#ffffff',
              }"
              @change="changeTargets"
            />
            <!-- <h3 v-else>{{ targets[0].Target.targetName }}</h3> -->
          </v-col>
        </v-row>
      </v-container>
      <v-toolbar flat>
        <v-toolbar-title>Palautteet</v-toolbar-title>

        <v-spacer />
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Otsikko"
                      prepend-icon="mdi-account"
                      v-model="feedbackItems.feedbackTitle"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Kyselyn kuvaus"
                      prepend-icon="mdi-information-variant"
                      v-model="feedbackItems.feedbackMessage"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="feedbackItems.feedbackState"
                      :items="feedbackStates"
                      label="Kyselyn tila"
                      placeholder="Valitse..."
                      item-text="name"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:[`item.results`]="{ item }">
      <v-icon small class="mr-2" @click="showResults(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import FeedbackService from '@/services/Tieto/FeedbackService.js';
import UserTargetService from '@/services/UserTargetService.js';

export default {
  data() {
    return {
      feedbacks: [],
      dialog: false,
      error: null,
      message: null,
      targets: [],
      user: {},
      userTargets: null,
      formTitle: 'Muokkaa käyttäjän tietoja',
      headers: [
        { text: 'Kohde', value: 'Targets[0].targetName' },
        { text: 'Otsikko', value: 'feedbackTitle' },
        { text: 'Kuvaus', value: 'feedbackMessage' },
        { text: 'Tila', value: 'feedbackState' },
        {
          text: 'Muuta',
          value: 'actions',
          sortable: false,
        },
      ],
      feedbackStates: [
        { name: 'Uusi', value: 'Uusi' },
        {
          name: 'Suljettu',
          value: 'Suljettu',
        },
      ],
      feedbackItems: {
        groupName: '',
        groupState: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    this.user = this.$store.getters.getUser;
    this.userTargets = this.$store.getters.getUserActiveTarget;
    const response = await FeedbackService.getAllFeedbacks({
      feedbackOrganizationId: this.user.Organization.organizationId,
      feedbackTargetId: this.userTargets,
    });
    this.feedbacks = response.data.feedbacks;
    try {
      const targets = await UserTargetService.getAllUserTargets({
        userId: this.user.uuid,
      });
      this.targets = targets.data.targets;
      this.message = targets.data.message;
      this.error = targets.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.targets.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },

  methods: {
    async changeTargets() {
      try {
        const feedback = await FeedbackService.getAllFeedbacks({
          feedbackOrganizationId: this.user.Organization.organizationId,
          feedbackTargetId: this.userTargets,
        });
        this.feedbacks = feedback.data.feedbacks;
        this.message = feedback.data.message;
        this.error = feedback.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.feedback.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    editItem(item) {
      this.feedbackItems = Object.assign({}, item);
      this.dialog = true;
    },

    showResults(item) {
      this.$router.push({
        name: 'show-survey-results',
        params: { queryId: item.queryId },
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.feedbackItems = Object.assign({}, this.groups);
      });
    },

    async save() {
      try {
        const response = await FeedbackService.saveFeedbackModification(
          this.feedbackItems
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const feedbacks = await FeedbackService.getAllFeedbacks();
        this.feedbacks = feedbacks.data.feedbacks;
        this.$store.dispatch('showFeedbackMessages');
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 100000);
      }
    },
  },
};
</script>
<style></style>
