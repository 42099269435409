<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="userTargets"
            :items="targets"
            item-text="Target.targetName"
            item-value="Target.targetId"
            label="Valitse kohde:"
            class="mt-1"
            :props="{
              color: '#ffffff',
            }"
            @change="changeQuery"
          />
          <!-- <h3 v-else>{{ targets[0].Target.targetName }}</h3> -->
        </v-col>
      </v-row>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
            <v-card-text>
              <h3>
                <p>
                  Tervetuloa kyselyyn! Vastaa kaikkiin kysymyksiin ja kirjoita
                  kommenttisi tyhjiin kenttiin. Lopuksi lähetä kysely.
                </p>
                <p>
                  Sinua pyydetään vielä tarkistamaan vastauksesi. Jos olet varma
                  vastauksistasi lähetä vastaukset.
                </p>
              </h3>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-container>
    <v-container>
      <v-row dense>
        <v-col cols="12" v-if="query.length > 0">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
                <v-card-title class="headline">
                  {{ queryTitle }}
                </v-card-title>

                <v-card-subtitle>{{ queryDescription }}</v-card-subtitle>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" v-for="(group, index) in questionGroups" :key="index">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 30 : 6" class="mb-2 pa-2">
                <v-card-title>
                  <h3>
                    {{ group.description }}
                  </h3>
                </v-card-title>

                <v-card-text>
                  <h4>
                    {{ group.group }}
                  </h4></v-card-text
                >
                <div v-for="question in questions" :key="question.id">
                  <v-card-actions
                    v-if="
                      group.group ===
                        question.TietoQuestionGroup.queryQuestionGroupDescrption
                    "
                  >
                    <v-list-item class="grow">
                      <v-list-item-content>
                        {{ question.questionDescription }}
                        <v-list-item-title>
                          <v-radio-group v-model="answers[question.id]">
                            <v-radio label="Täysin eri mieltä" value="1" />
                            <v-radio label="Osittain eri mieltä" value="2" />
                            <v-radio
                              label="Ei samaa, eikä eri mieltä"
                              value="3"
                            />
                            <v-radio label="Osittain samaa mieltä" value="4" />
                            <v-radio label="Täysin samaa mieltä" value="5" />
                          </v-radio-group>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-row align="center" justify="end" />
                    </v-list-item>
                  </v-card-actions>
                </div>
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="query.length > 0">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="mb-2 pa-2">
                <v-card-actions>
                  <v-textarea v-model="queryComment" label="Kommentti" />
                </v-card-actions>
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <template>
          <v-flex text-center xs12 md12 class="my-5" v-if="query.length > 0">
            <v-btn color="grey lighten-2" dense @click="showDialog">
              Lähetä kysely
            </v-btn>
          </v-flex>
        </template>
      </v-row>
      <v-dialog v-model="createDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            Kiitos kyselyyn osallistumisesta!
          </v-card-title>
          <v-card-text>
            <p>Olet nyt syöttänyt kaikki vastaukset.</p>
            <p>
              Mikäli haluat muuttaa vielä vastauksiasi niin paina Muokkaa
              vastauksiasi
            </p>

            <p>
              Ole hyvä ja lähetä vastausket painamlla Lähetä vastaukset.
            </p>
            <p>Tämän jälkeen et voi enää muokata vastauksiasi</p>
            .
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeModal">
              Muokkaa vastauksiasi
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveSurvey">
              Lähetä vastaukset
            </v-btn>

            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import QueryService from '@/services/Tieto/QueryService.js';
import QuerySResultService from '@/services/Tieto/QueryResultService.js';
import TargetService from '@/services/TargetService.js';

export default {
  data() {
    return {
      query: [],
      questions: [],
      message: null,
      error: null,
      answers: {},
      results: {},
      queryTitle: '',
      queryDescription: '',
      createDialog: false,
      queryComment: '',
      questionGroups: [],
      targets: [],
      userTargets: '',
    };
  },

  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const targets = await TargetService.getAllUserTargets({
        userId: this.user.uuid,
      });

      this.targets = targets.data.targets;
      await this.$store.dispatch(
        'setUserActiveTarget',
        this.targets[0].targetId
      );
      this.userTargets = this.$store.getters.getUserActiveTarget;
    } catch (error) {
      this.error = error;
    }
    try {
      const response = await QueryService.getAllQueriesByCustomerId({
        queryOrganization: this.user.Organization.organizationId,
        queryTargetId: this.$store.getters.getUserActiveTarget,
      });
      this.questions = response.data.questions;

      if (this.questions) {
        for (let i = 0; i < this.questions.length; i++) {
          if (i === 0) {
            this.questionGroups.push({
              group: this.questions[i].TietoQuestionGroup
                .queryQuestionGroupDescrption,
              description: this.questions[i].TietoQuestionGroup
                .queryQuestionGroupName,
            });
          }
          if (
            i > 0 &&
            this.questions[i].TietoQuestionGroup
              .queryQuestionGroupDescrption !==
              this.questions[i - 1].TietoQuestionGroup
                .queryQuestionGroupDescrption
          ) {
            this.questionGroups.push({
              group: this.questions[i].TietoQuestionGroup
                .queryQuestionGroupDescrption,
              description: this.questions[i].TietoQuestionGroup
                .queryQuestionGroupName,
            });
          }
        }
        this.query = response.data.query;
        this.queryTitle = this.query[0].queryTitle;
        this.queryDescription = this.query[0].queryDescription;
        this.results.queryId = this.query[0].queryId;
        this.results.queryUserId = this.$store.state.user.uuid;
      }
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async changeQuery() {
      try {
        this.questions = [];
        this.query = [];
        this.queryTitle = '';
        this.queryDescription = '';
        this.questionGroups = [];

        const response = await QueryService.getAllQueriesByCustomerId({
          queryOrganization: this.user.Organization.organizationId,
          queryTargetId: this.userTargets,
        });
        this.questions = response.data.questions;
        if (this.questions) {
          for (let i = 0; i < this.questions.length; i++) {
            if (i === 0) {
              this.questionGroups.push({
                group: this.questions[i].TietoQuestionGroup
                  .queryQuestionGroupDescrption,
                description: this.questions[i].TietoQuestionGroup
                  .queryQuestionGroupName,
              });
            }
            if (
              i > 0 &&
              this.questions[i].TietoQuestionGroup
                .queryQuestionGroupDescrption !==
                this.questions[i - 1].TietoQuestionGroup
                  .queryQuestionGroupDescrption
            ) {
              this.questionGroups.push({
                group: this.questions[i].TietoQuestionGroup
                  .queryQuestionGroupDescrption,
                description: this.questions[i].TietoQuestionGroup
                  .queryQuestionGroupName,
              });
            }
          }
          this.query = response.data.query;
          this.queryTitle = this.query[0].queryTitle;
          this.queryDescription = this.query[0].queryDescription;
          this.results.queryId = this.query[0].queryId;
          this.results.queryUserId = this.$store.state.user.uuid;
        }
      } catch (error) {
        this.error = error;
      }
    },
    showDialog() {
      this.createDialog = true;
    },
    closeModal() {
      this.createDialog = false;
    },
    async saveSurvey() {
      this.results.answers = this.answers;
      this.results.queryComment = this.queryComment;
      this.createDialog = false;

      try {
        const response = await QuerySResultService.addNewQueryResult(
          this.results
        );
        this.results = {};
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
          this.$router.push({
            name: 'tieto/dashboard',
          });
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style scoped></style>
