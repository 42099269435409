import Api from '@/services/Api';

//Organization api cals
export default {
  getAllUserDevices() {
    return Api().get('/api/user-devices/', {});
  },
  getAllUserDevicesAdmin() {
    return Api().get('/api/all-user-devices-admin/', {});
  },
  getAllOrganizationUserDevices(params) {
    return Api().get('/api/all-organization-user-devices', { params });
  },
  saveUserDeviceModification(credintials) {
    return Api().patch('/api/devices', credintials, {});
  },
  addNewUserDevice(credintials) {
    return Api().post('/api/user-device/', credintials, {});
  },
};
