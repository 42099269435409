import Api from '@/services/Api';

//User api cals
export default {
  getAllQuestions() {
    return Api().get('/api/tieto/questions', {});
  },
  saveQuestionModification(credintials) {
    return Api().patch('/api/tieto/question', credintials, {});
  },
  addNewQuestion(credintials) {
    return Api().post('/api/tieto/question', credintials, {});
  },
};
