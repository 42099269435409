<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="4" offset-md="4">
        <qrcode-stream @decode="onDecode" @init="onInit" class="qr-code" />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="4" offset-md="4">
        <p class="error">{{ error }}</p>

        <p class="decode-result">
          Koodi <b>{{ qrCode }}</b>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="4" offset-md="4">
        <v-autocomplete
          v-model="action"
          :items="values"
          item-text="name"
          item-value="value"
          label="Valitse toiminto"
          placeholder="Valitse..."
          :rules="required"
          @change="showButtonText"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="4" offset-md="4">
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="qrCodeAction"
          v-if="showButton"
        >
          {{ action }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
  components: { QrcodeStream },

  data() {
    return {
      qrCode: '',
      error: '',
      action: '',
      buttonText: '',
      showButton: false,

      required: [(v) => !!v || 'Pakollinen kenttä'],
      values: [
        {
          value: 'Lisää mittari',
          name: 'Lisää mittari',
        },
        { value: 'Näytä mittarin tiedot', name: 'Näytä mittarin tiedot' },
      ],
    };
  },

  methods: {
    onDecode(qrCode) {
      this.qrCode = qrCode;
    },
    showButtonText() {
      this.showButton = true;
      this.buttonText = this.action;
    },
    qrCodeAction() {
      if (this.action === 'Lisää mittari') {
        this.$router.push('/vainu/add-device?qrCode=' + this.qrCode);
      } else {
        this.$router.push('/vainu/show-devices?qrCode=' + this.qrCode);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      }
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
.qr-code {
  /* width: 300px;
  height: 300px; */
}
</style>
