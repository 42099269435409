import Api from '@/services/Api';

//Organization api cals
export default {
  getAllUsersByTargets(params) {
    return Api().get('/api/user-by-user-targets', { params });
  },
  getAllUserTargets(params) {
    return Api().get('/api/user-targets', { params });
  },
  addUserTargets(credintials) {
    return Api().post('/api/user-targets', credintials, {});
  },
  deleteUserTargets(id) {
    return Api().delete('/api/user-targets/', {
      data: { id: id },
    });
  },
  updatedUserTargets(credintials) {
    return Api().patch('/api/user-targets/', credintials, {});
  },
};
