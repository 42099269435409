<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Päivitä tiedot</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Sähköposti"
                  v-model="users[0].userEmail"
                  autocomplete="off"
                  disabled
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Etunimi"
                  :rules="required"
                  v-model="users[0].userFirstName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Sukunimi"
                  :rules="required"
                  v-model="users[0].userLastName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Puhelin"
                  v-model="users[0].userPhoneNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Tehtävä"
                  v-model="users[0].userJobTitle"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="save"
                >
                  Talleta tiedot
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService.js';

export default {
  data() {
    return {
      users: {},
      message: null,
      error: null,
      user: {},

      required: [(v) => !!v || 'Pakollinen kenttä'],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;

    const response = await UserService.getUser({
      uuid: this.user.uuid,
    });
    this.users = response.data.users;

    console.log(this.users.userEmail);
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        console.log(this.users);
        try {
          const response = await UserService.saveUserModificationOwn(
            this.users
          );
          this.message = response.data.message;
          this.error = response.data.error;
          await UserService.getUser({ uuid: this.user.uuid });

          setTimeout(() => {
            this.message = null;
            this.error = null;
            //this.$refs.form.reset();
          }, 3000);
        } catch (error) {
          this.error = 'Jokin meni vikaan';
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
