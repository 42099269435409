import axios from 'axios';
import store from '@/store';
import router from '@/router';
//export default () => {
//   return axios.create({
//     baseURL: `http://localhost:3000/vepa_backend/`,

//     //baseURL: `https://vepa.fi/vepa_backend/`,
//     headers: {
//       Authorization: `Bearer ${store.getters.getToken}`,
//     },
//   });

//};

const Api = axios.create({
  //baseURL: `http://localhost:3000/vepa_backend/`,
  baseURL: `https://vepa.fi/vepa_backend/`,
  timeout: 10000,
});
const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${store.getters.getToken}`;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  console.log(error.response.data.message);
  if (error.response.status === 401 || error.response.status === 403) {
    store.dispatch('logout');
    router.push({
      name: 'login',
    });
  }
  return Promise.reject(error);
};

Api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
Api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default () => Api;
