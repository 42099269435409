<template>
  <v-container>
    <v-card class="mb-3">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="chartdata"
            :options="options"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="organizationInfo.organizationName"
            >
              {{ organizationInfo.organizationName }}
            </p>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationAddress"
            >
              {{ organizationInfo.organizationAddress }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationZipCode"
            >
              {{ organizationInfo.organizationZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationPostalDistrict"
            >
              {{ organizationInfo.organizationPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
//import DoughnutChartMaster from './DoughnutChartsMaster.js';
import DoughnutChart from './DoughnutChart.js';
//import OrganizationService from '@/services/OrganizationService.js';
//import TargetService from '@/services/TargetService.js';
import MaterialbankService from '@/services/Materialbank/MaterialbankService.js';
// import DiscussionService from '@/services/Tieto/DiscussionService';
// import QueryService from '@/services/Tieto/QueryService';
// import FeedbackService from '@/services/Tieto/FeedbackService';

export default {
  components: {
    DoughnutChart,
  },
  data: () => ({
    user: {},
    chartdata: null,
    count: 0,

    targets: [],
    organizationInfo: {},
    options: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      rotation: 30,
      text: '',
      label: 'Dokumentit',
    },
  }),

  async mounted() {
    this.user = this.$store.getters.getUser;
    this.organizationInfo.organizationName = this.user.Organization.organizationName;
    this.organizationInfo.organizationAddress = this.user.Organization.organizationAddress;
    this.organizationInfo.organizationZipCode = this.user.Organization.organizationZipCode;
    this.organizationInfo.organizationPostalDistrict = this.user.Organization.organizationPostalDistrict;
    const masterDataSets = [];

    try {
      this.reset();

      let dataArr = [];
      const files = await MaterialbankService.getCountFilesByOrganization({
        organizationId: this.user.Organization.organizationId,
      });
      let fileTypes = files.data.files;
      dataArr.push(fileTypes[0].pdf);
      dataArr.push(fileTypes[0].jpg);
      this.options.text = fileTypes[0].totalFiles;
      masterDataSets.push({
        label: 'Data One',
        backgroundColor: ['green', 'red'],
        data: dataArr,
      });
      this.chartdata = {
        labels: ['Pdf', 'Jpg'],
        datasets: masterDataSets,
      };
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    reset() {
      this.chartdata = null;
    },
  },
};
</script>
