<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Vaihda salasana</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Sähköposti"
                  prepend-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="changePwd.userEmail"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Uusi salasana"
                  prepend-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="changePwd.userPassword"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Salasana uudelleen"
                  prepend-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="changePwd.confirmPassword"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="changeUserPassword"
                >
                  Vaihda salasana
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
export default {
  data() {
    return {
      changePwd: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen kenttä',
      },
    };
  },
  methods: {
    async changeUserPassword() {
      try {
        if (this.changePwd.userPassword === this.changePwd.confirmPassword) {
          const response = await AuthenticationService.changeUserPassword(
            this.changePwd
          );
          this.message = response.data.message;
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } else {
          this.error = 'Salasanat eivät täsmänneet. Yritä uudelleen.';
        }
      } catch (error) {
        this.error = error.response.data.error;
        // setTimeout(() => {
        //   this.error = null;
        // }, 3000);
      }
    },
  },
};
</script>
