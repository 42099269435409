<template>
  <v-data-table
    :headers="headers"
    :items="group"
    class="elevation-1"
  />
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      group: [],
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
      ],
    };
  },

  async created() {
    const response = await axios.get(
      'https://api.sigfox.com/v2/device-types/',
      {
        headers: {
          Authorization:
            'Basic ' +
            'NjAwN2UzOGJlODMzZDkyNjUzMzdhNGIxOjY1NTM4ZTQ2MDRmMTg3Yjc2OTg3MTc0ZmY2YzUyNjU0',
          'Content-Type': 'application/json',
        },
      }
    );
    console.log(response.data);
    this.group = response.data.data;

    console.log(this.group);
  },
};
</script>

<style></style>
