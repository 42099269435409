var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"max-width":"750px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Kommentti","prepend-icon":"mdi-information-variant","autocomplete":"off"},model:{value:(_vm.discussionComment.comment),callback:function ($$v) {_vm.$set(_vm.discussionComment, "comment", $$v)},expression:"discussionComment.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Peruuta ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Talleta ")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4 pa-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.discussion[0].discussionTitle)+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.discussion[0].discussionDescription))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.showDateAndTime(_vm.discussion[0].createdAt)))]),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.addNewDiscussionComment()}}},[_vm._v(" Lisää kommentti ")]),_c('v-btn',{on:{"click":function($event){return _vm.backToComments()}}},[_vm._v(" Takaisin ")])],1)],1)]}}])})],1),_vm._l((_vm.comments),function(comment,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-2 pa-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":_vm.showColor(comment.userFirstName),"size":"40"}},[_c('span',{staticClass:"white--text "},[_vm._v(_vm._s(_vm.showNameLetters(comment.userFirstName, comment.userLastName)))])]),_c('span',{staticClass:"subtitle-2 pl-2"},[_vm._v(_vm._s(_vm.showDateAndTime(comment.createdAt)))]),_c('v-spacer'),(
                  _vm.user.userRoles === 'Pääylläpitäjä' ||
                    _vm.user.userRoles === 'Ylläpitäjä' ||
                    _vm.user.userRoles === 'Pääkäyttäjä' ||
                    _vm.user.uuid === _vm.discussion[0].discussionModerator
                )?_c('v-card-actions',[_c('v-icon',{on:{"click":function($event){return _vm.modifyCurrentComment(
                      comment.discussionComment,
                      comment.discussionCommentId
                    )}}},[_vm._v(" mdi-pencil ")])],1):_vm._e()],1),(comment.discussionComment.length <= 500)?_c('v-card-text',[_vm._v(" "+_vm._s(comment.discussionComment)+" ")]):_vm._e(),(!comment.Flag && comment.discussionComment.length > 500)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm._f("summary")(comment.discussionComment))+" "),_c('a',{on:{"click":function($event){return _vm.toggler(comment, true)}}},[_vm._v("Lue lisää")])]):_vm._e(),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(comment.Flag),expression:"comment.Flag"}]},[_vm._v(" "+_vm._s(comment.discussionComment)+" "),_c('div',{staticClass:"button"},[_c('a',{on:{"click":function($event){return _vm.toggler(comment, false)}}},[_vm._v("Näytä vähemmän")])])]),_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.showFirtsNameLastName( comment.userFirstName, comment.userLastName ))+" ")])],1),_c('v-row',{attrs:{"align":"center","justify":"end"}},[(_vm.showSubCommentIcon(comment.count))?_c('v-btn',{attrs:{"text":"","color":"#45a1e6"},on:{"click":function($event){return _vm.showSubComments(comment.discussionCommentId)}}},[_vm._v(" Katso kommentit ("+_vm._s(comment.count)+") ")]):_c('v-btn',{attrs:{"text":"","color":"#45a1e6"},on:{"click":function($event){return _vm.addNewDiscussionSubComment(comment.discussionCommentId)}}},[_vm._v(" Lisää kommentti ")]),_c('span',{staticClass:"subheading"})],1)],1)],1)],1)]}}],null,true)})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }