import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '@/views/Main.vue';
import Store from '@/store';

import Login from '../views/Login';
import UserRegister from '../views/UserRegister';
import Admin from '../views/Admin';

import Tieto from '../views/Tieto';
import Vainu from '../views/Vainu';
import Materialbank from '@/views/Materialbank';
import ForgotPassword from '@/views/ForgotPassword';
import ChangeUserPasswordView from '@/views/ChangeUserPassword';
//import Main from '../views/Main'

import NotFound from '@/components/helpers/notFound';
//Admin
import AddUser from '@/components/Admin/Users/AddNewUser';
//import AddUser from '@/components/Admin/Users/AddNewUser';
import ShowUsers from '@/components/Admin/Users/ShowUsers';
import ChangeUserInformation from '@/components/Admin/Users/ChangeUserInformation';
import AddTarget from '@/components/Admin/Targets/AddTarget';
import AddTargetsToUser from '@/components/Admin/Users/AddTargetsToUser';
import ShowTargets from '@/components/Admin/Targets/ShowTargets';
import AddOrganization from '@/components/Admin/Organizations/AddOrganization';
import ShowOrganizations from '@/components/Admin/Organizations/ShowOrganizations';
import EditUserInformation from '@/components/Admin/Users/EditUserInformation';
import AddSensor from '@/components/Vainu/Sensors/AddSensor';
import GenerateRegisterationCode from '@/components/Admin/Users/GenerateRegisterationCode';
import ShowSensors from '@/components/Vainu/Sensors/ShowSensors';
import SensorData from '@/components/Vainu/Sensors/SensorData';
import SensorChart from '@/components/Vainu/Charts/SensorChart';
import ChangePassword from '@/components/Admin/Users/ChangePassword';
import ChangeUserPassword from '@/components/Admin/Users/ChangeUserPassword';
import ShowAlarms from '@/components/Vainu/Alarms/ShowAlarms';
import AddMeasurement from '@/components/Vainu/Measurements/AddMeasurement';
import ShowMeasurements from '@/components/Vainu/Measurements/ShowMeasurements';
import AddDevice from '@/components/Vainu/Devices/AddNewDevice';
import ShowDevices from '@/components/Vainu/Devices/ShowDevices';
import ShowMaterialbankFilesVainu from '@/components/Vainu/Materialbank/ShowMaterialbankFiles';
import AdminDashboard from '@/components/Admin/Dashboard/Dashboard';
import VainuDashboard from '@/components/Vainu/Dashboard/Dashboard';
//import DeviceChart from '@/components/Vainu/Charts/DeviceChart';
import ReadQrCode from '@/components/Vainu/QRcode/ReadQrCode';
import AddDeviceToUser from '@/components/Vainu/Devices/AddDeviceToUser';
import ShowUserDevices from '@/components/Vainu/Devices/ShowUserDevices';
//Tieto
import AddNewQuery from '@/components/Tieto/Query/AddNewQuery';
import ShowQueries from '@/components/Tieto/Query/ShowQueries';
import AddNewQuestion from '@/components/Tieto/Questions/AddNewQuestion';
import AddNewQuestionGroup from '@/components/Tieto/QuestionGroups/AddNewQuestionGroup';
import ShowQuestions from '@/components/Tieto/Questions/ShowQuestions';
import ShowQuestionGroups from '@/components/Tieto/QuestionGroups/ShowQuestionGroups';

import ShowSurveyForm from '@/components/Tieto/Survey/ShowSurveyForm';
import ShowSurveyResults from '@/components/Tieto/Survey/ShowSurveyResults';
import AddFeedback from '@/components/Tieto/Feedback/AddFeedback';
import ShowFeedbacks from '@/components/Tieto/Feedback/ShowFeedbacks';
import ShowMaterialBankFiles from '@/components/Tieto/MaterialBank/ShowMaterialBankFiles';
import Discussions from '@/components/Tieto/Discussions/Discussions';
import ShowCurrentDiscussion from '@/components/Tieto/Discussions/ShowCurrentDiscussion';
import AddNewDiscussion from '@/components/Tieto/Discussions/AddNewDiscussion';
import ShowDiscussions from '@/components/Tieto/Discussions/ShowDiscussions';
import AddNewComment from '@/components/Tieto/Discussions/AddNewComment';
import ShowDiscussionSubComments from '@/components/Tieto/Discussions/ShowDiscussionSubComments';
import AddNewSubComment from '@/components/Tieto/Discussions/AddNewSubComment';
import TietoDashboard from '@/components/Tieto/Dashboard/Dashboard';
//Materialbank
import AddNewMaterialbankFile from '@/components/Materiaalipankki/AddNewMaterialbankFile';
import ShowMaterialbankFiles from '@/components/Materiaalipankki/ShowMaterialbankFiles';
import Dashboard from '@/components/Materiaalipankki/Dashboard/Dashboard.vue';
Vue.use(VueRouter);
//Client side routes
let user = Store.getters.getUser;
console.log(user);
function checkUserPermissions(value) {
  let user = Store.getters.getUser;
  if (user.userPermissions.includes(value)) {
    return true;
  } else {
    return false;
  }
}
const routes = [
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/user-register',
    name: 'user-register',
    component: UserRegister,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangeUserPasswordView,
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter(to, from, next) {
      if (checkUserPermissions('4')) {
        next();
      } else {
        next('/not-found');
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-new-user',
        components: {
          admin: AddUser,
        },
      },

      {
        path: 'show-users',
        components: {
          admin: ShowUsers,
        },
      },
      {
        path: 'show-user',
        components: {
          admin: ChangeUserInformation,
        },
      },
      {
        path: 'change-user-password',
        components: {
          admin: ChangeUserPassword,
        },
      },
      {
        path: 'add-new-organization',
        components: {
          admin: AddOrganization,
        },
      },
      {
        path: 'show-organizations',
        components: {
          admin: ShowOrganizations,
        },
      },
      {
        path: 'add-new-target',
        components: {
          admin: AddTarget,
        },
      },
      {
        path: 'show-targets',
        components: {
          admin: ShowTargets,
        },
      },
      {
        path: 'generate-registeration-code',
        components: {
          admin: GenerateRegisterationCode,
        },
      },
      {
        path: 'dashboard',
        components: {
          admin: AdminDashboard,
        },
      },
      {
        path: 'add-targets-to-user',
        components: {
          admin: AddTargetsToUser,
        },
      },
      {
        path: 'change-password',
        name: 'admin/change-password',
        components: {
          admin: ChangePassword,
        },
      },
    ],
  },
  //Tieto routes
  {
    path: '/tieto',
    name: 'tieto',
    component: Tieto,
    beforeEnter(to, from, next) {
      if (checkUserPermissions('1')) {
        next();
      } else {
        next('/not-found');
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-new-query',
        components: {
          tietoview: AddNewQuery,
        },
      },
      {
        path: 'show-queries',
        components: {
          tietoview: ShowQueries,
        },
      },
      {
        path: 'add-new-question',
        components: {
          tietoview: AddNewQuestion,
        },
      },
      {
        path: 'show-questions',
        components: {
          tietoview: ShowQuestions,
        },
      },
      {
        path: 'add-new-question-group',
        components: {
          tietoview: AddNewQuestionGroup,
        },
      },
      {
        path: 'show-question-groups',
        components: {
          tietoview: ShowQuestionGroups,
        },
      },
      {
        path: 'show-survey-form',
        name: 'show-survey-form',
        components: {
          tietoview: ShowSurveyForm,
        },
      },
      {
        path: 'show-survey-results',
        name: 'show-survey-results',
        components: {
          tietoview: ShowSurveyResults,
        },
      },
      {
        path: 'add-feedback',
        components: {
          tietoview: AddFeedback,
        },
      },
      {
        path: 'show-feedbacks',
        name: 'show-feedbacks',
        components: {
          tietoview: ShowFeedbacks,
        },
      },

      {
        path: 'show-materialbank-files',
        components: {
          tietoview: ShowMaterialBankFiles,
        },
      },

      {
        path: 'show-discussions',
        components: {
          tietoview: ShowDiscussions,
        },
      },
      {
        path: 'discussions',
        name: 'tieto/main-discussions',
        components: {
          tietoview: Discussions,
        },
      },
      {
        path: 'add-discussion',
        components: {
          tietoview: AddNewDiscussion,
        },
      },
      {
        path: 'current-discussions',
        name: 'tieto/discussions',
        components: {
          tietoview: ShowCurrentDiscussion,
        },
      },
      {
        path: 'add-new-comment',
        name: 'tieto/add-new-comment',
        components: {
          tietoview: AddNewComment,
        },
      },
      {
        path: 'show-sub-comments',
        name: 'tieto/sub-comments',
        components: {
          tietoview: ShowDiscussionSubComments,
        },
      },
      {
        path: 'add-new-sub-comment',
        name: 'tieto/add-new-sub-comment',
        components: {
          tietoview: AddNewSubComment,
        },
      },
      {
        path: 'change-password',
        name: 'tieto/change-password',
        components: {
          tietoview: ChangePassword,
        },
      },
      {
        path: 'dashboard',
        name: 'tieto/dashboard',
        components: {
          tietoview: TietoDashboard,
        },
      },
      {
        path: 'show-user',
        components: {
          tietoview: ChangeUserInformation,
        },
      },
    ],
  },

  //Powerguard routes end

  //Indoor air routes

  {
    path: '/vainu',
    name: 'vainu',
    component: Vainu,
    beforeEnter(to, from, next) {
      if (checkUserPermissions('2')) {
        next();
      } else {
        next('/not-found');
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-sensor',
        components: {
          vainuview: AddSensor,
        },
      },
      {
        path: 'show-sensors',
        components: {
          vainuview: ShowSensors,
        },
      },
      {
        path: 'sensor-data',
        components: {
          vainuview: SensorData,
        },
      },
      {
        path: 'sensor-data/chart',
        components: {
          vainuview: SensorChart,
        },
      },

      {
        path: 'change-password',
        components: {
          vainuview: ChangePassword,
        },
      },
      {
        path: 'edit-user-information',
        components: {
          vainuview: EditUserInformation,
        },
      },
      {
        path: 'show-alarms',
        components: {
          vainuview: ShowAlarms,
        },
      },
      {
        path: 'add-measurement',
        components: {
          vainuview: AddMeasurement,
        },
      },
      {
        path: 'show-measurements',
        components: {
          vainuview: ShowMeasurements,
        },
      },
      {
        path: 'add-device/:id',
        components: {
          vainuview: AddDevice,
        },
      },
      {
        path: 'add-device/',
        components: {
          vainuview: AddDevice,
        },
      },
      {
        path: 'show-devices/:id',
        components: {
          vainuview: ShowDevices,
        },
      },
      {
        path: 'show-devices/',
        components: {
          vainuview: ShowDevices,
        },
      },
      {
        path: 'add-user-device',
        components: {
          vainuview: AddDeviceToUser,
        },
      },
      {
        path: 'show-user-devices',
        components: {
          vainuview: ShowUserDevices,
        },
      },
      {
        path: 'qr-code',
        components: {
          vainuview: ReadQrCode,
        },
      },
      {
        path: 'show-materialbank-files-vainu',
        components: {
          vainuview: ShowMaterialbankFilesVainu,
        },
      },
      {
        path: 'dashboard',
        components: {
          vainuview: VainuDashboard,
        },
      },
      {
        path: 'show-user',
        components: {
          vainuview: ChangeUserInformation,
        },
      },

      /* {
        path: 'sensors/:deviceId/chart',
        components: {
          vainuview: DeviceChart,
        },
      },*/
    ],
  },

  //Indoor air routes end

  {
    path: '/',
    name: 'main1',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'change-password',
        components: {
          dashboard: ChangePassword,
        },
      },
      {
        path: 'edit-user-information',
        components: {
          dashboard: EditUserInformation,
        },
      },
    ],
  },

  {
    path: '/materiaalipankki',
    name: 'materiaalipankki',
    component: Materialbank,
    beforeEnter(to, from, next) {
      if (checkUserPermissions('3')) {
        next();
      } else {
        next('/not-found');
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-new-materialbank-file',
        components: {
          materialbank: AddNewMaterialbankFile,
        },
      },
      {
        path: 'show-materialbank-files',
        components: {
          materialbank: ShowMaterialbankFiles,
        },
      },
      {
        path: 'dashboard',
        components: {
          materialbank: Dashboard,
        },
      },
      {
        path: 'change-password',
        name: 'materiaalipankki/change-password',
        components: {
          materialbank: ChangePassword,
        },
      },
      {
        path: 'show-user',
        components: {
          materialbank: ChangeUserInformation,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if (!Store.state.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
