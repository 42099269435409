<template>
  <v-container>
    <v-card class="card">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="chartdata"
            :options="options"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="organizationInfo.organizationName"
            >
              {{ organizationInfo.organizationName }}
            </p>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationAddress"
            >
              {{ organizationInfo.organizationAddress }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationZipCode"
            >
              {{ organizationInfo.organizationZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationPostalDistrict"
            >
              {{ organizationInfo.organizationPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card class=" card" v-for="(t, index) in chartData" :key="index">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="t"
            :options="chartOptions[index]"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="targetInfo[index].targetName"
            >
              {{ targetInfo[index].targetName }}
            </p>
            <div class="text--primary" v-if="targetInfo[index].targetAddress">
              {{ targetInfo[index].targetAddress }}
            </div>
            <div class="text--primary" v-if="targetInfo[index].targetZipCode">
              {{ targetInfo[index].targetZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="targetInfo[index].targetPostalDistrict"
            >
              {{ targetInfo[index].targetPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import DoughnutChart from './DoughnutChart.js';
import DeviceService from '@/services/Vainu/DeviceService.js';
import AlarmService from '@/services/Vainu/AlarmService.js';
import TargetService from '@/services/TargetService.js';

export default {
  components: {
    DoughnutChart,
  },
  data: () => ({
    user: {},
    chartdata: null,
    count: 0,
    organizationInfo: {},
    targetInfo: [],
    chartData: [],
    chartOptions: [],
    targetAlarms: [],

    targets: [],

    options: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      rotation: 30,
      text: '',
    },
  }),

  async mounted() {
    this.user = this.$store.getters.getUser;
    const masterDataSets = [];

    this.organizationInfo.organizationName = this.user.Organization.organizationName;
    this.organizationInfo.organizationAddress = this.user.Organization.organizationAddress;
    this.organizationInfo.organizationZipCode = this.user.Organization.organizationZipCode;
    this.organizationInfo.organizationPostalDistrict = this.user.Organization.organizationPostalDistrict;

    try {
      this.reset();

      let dataArr = [];
      let deviceArr = [];
      let targetDevices = [];

      const devices = await DeviceService.getAllOrganizationDevices({
        deviceOrganizationId: this.user.Organization.organizationId,
      });
      let deviceData = devices.data.devices;
      this.options.text = deviceData.length;
      for (let i = 0; i < deviceData.length; i++) {
        deviceArr.push(deviceData[i].deviceId);
      }

      let deviceIds = deviceArr.toString();

      const alarms = await AlarmService.getAllOrganizationAlarms({
        id: deviceIds,
      });

      let alarmCount = alarms.data.alarms.length;

      dataArr.push(deviceData.length - alarmCount);
      dataArr.push(alarmCount);

      masterDataSets.push({
        label: 'Data One',
        backgroundColor: ['green', 'red'],
        data: dataArr,
      });
      this.chartdata = {
        labels: ['Kunnossa', 'Hälytykset'],
        datasets: masterDataSets,
      };
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.user.Organization.organizationId,
      });
      this.targets = targets.data.targets;

      for (let i = 0; i < this.targets.length; i++) {
        const devices = await DeviceService.getAllTargetDevices({
          deviceTargetId: this.targets[i].targetId,
        });
        const targetDevice = devices.data.devices;

        if (targetDevice.length > 0) {
          targetDevices.push(targetDevice);
        }
      }
      for (let i = 0; i < targetDevices.length; i++) {
        let dData = [];
        for (let t = 0; t < targetDevices[i].length; t++) {
          dData.push(targetDevices[i][t].deviceId);
        }
        let Ids = deviceArr.toString();

        const alarms = await AlarmService.getAllOrganizationAlarms({
          id: Ids,
        });

        let alarmsCounts = alarms.data.alarms.length;
        let ok = dData.length - alarmsCounts;

        this.chartOptions.push({
          legend: {
            display: true,
          },
          responsive: true,
          maintainAspectRatio: false,
          rotation: 30,
          text: targetDevices[i].length,
        });

        this.targetInfo.push({
          targetName: targetDevices[i][0].Target.targetName,
          targetAddress: targetDevices[i][0].Target.targetAddress,
          targetZipCode: targetDevices[i][0].Target.targetZipCode,
          targetPostalDistrict: targetDevices[i][0].Target.targetPostalDistrict,
        });
        let data = [];
        data.push(ok, alarmsCounts);
        this.chartData.push({
          labels: ['Kunnossa', 'Hälytykset'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: ['green', 'red'],
              data: data,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    reset() {
      this.chartdata = null;
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 50px;
}
</style>
