<!-- Add new organization template -->
<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää uusi kohde</h4></v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Kohteen nimi"
                  prepend-icon="mdi-domain"
                  :rules="required"
                  v-model="targetValues.targetName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Osoite"
                  prepend-icon="mdi-file-document-edit-outline"
                  :rules="required"
                  v-model="targetValues.targetAddress"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Postinumero"
                  prepend-icon="mdi-home-city-outline"
                  :rules="targetZipCodeRules"
                  v-model="targetValues.targetZipCode"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  label="Postitoimipaikka"
                  prepend-icon="mdi-home-map-marker"
                  :rules="required"
                  v-model="targetValues.targetPostalDistrict"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="targetValues.targetOrganizationId"
                  :items="targetOrganizations"
                  item-text="organizationName"
                  item-value="organizationId"
                  label="Organisaatio"
                  placeholder="Valitse..."
                  :rules="required"
                  @change="getOrganizationUsers"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Kohteen tyyppi"
                  prepend-icon="mdi-alpha-z-box-outline"
                  :rules="required"
                  v-model="targetValues.targetType"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Kohteen tunnus"
                  prepend-icon="mdi-home-map-marker"
                  :rules="required"
                  v-model="targetValues.targetPropertyId"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-text-field
                  label="Kohteen työnumero"
                  prepend-icon="mdi-flag"
                  :rules="required"
                  v-model="targetValues.targetJobNumber"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-account-supervisor"
                  v-model="targetValues.targetContactUser"
                  :items="targetUsers"
                  item-text="name"
                  item-value="uuid"
                  label="Kohteen yhteyshenkilö"
                  placeholder="Valitse..."
                  :rules="option"
                  multiple
                />
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="targetValues.targetState"
                  :items="targetState"
                  item-text="name"
                  item-value="value"
                  label="Kohteen tila"
                  placeholder="Valitse..."
                  :rules="required"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Info"
                  prepend-icon="mdi-information-variant"
                  v-model="targetValues.organizationInformation"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  label="Kuvaus"
                  prepend-icon="mdi-comment-text-outline"
                  v-model="targetValues.organizationDescription"
                  autocomplete="off"
                  :counter="500"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewTarget"
                >
                  Lisää kohde
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TargetService from '@/services/TargetService.js';
import OrganizationController from '@/services/OrganizationService.js';
import UserService from '@/services/UserService.js';
export default {
  data() {
    return {
      targetState: [
        { name: this.$t('register.state-active'), value: 'Aktiivinen' },
        { name: this.$t('register.state-passive'), value: 'Passiivinen' },
      ],
      targetOrganizations: [],
      targetUsers: [],
      targetValues: {},
      message: null,
      error: null,
      targetZipCodeRules: [
        (v) => !!(v || '').match(/[0-9]/g) || 'Postinumero on pakollinen tieto',
        (v) => (v && v.length == 5) || 'Postinumerossa pitää olla 5 numeroa',
      ],
      required: [(v) => !!v || 'Pakollinen kenttä'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
    };
  },
  async created() {
    try {
      const response = await OrganizationController.getAllOrganizations();
      this.targetOrganizations = response.data.organizations;
      this.message = response.data.message;
      this.error = response.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.response.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },
  methods: {
    async getOrganizationUsers() {
      try {
        // if (this.user.userRoles === 'Pääylläpitäjä') {
        //   this.targetOrganizationId = this.targetValues.targetOrganizationId;
        // } else {
        //   this.targetOrganizationId = this.user.userOrganization;
        // }
        const response = await UserService.getAllOrganizationUsers({
          organizationId: this.targetValues.targetOrganizationId,
        });
        this.targetUsers = response.data.users;
        for (let i = 0; i < this.targetUsers.length; i++) {
          this.targetUsers[i].name =
            this.targetUsers[i].userFirstName +
            ' ' +
            this.targetUsers[i].userLastName;
        }
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    /**
     * Add new button is clicked
     */
    async addNewTarget() {
      if (this.$refs.form.validate()) {
        try {
          const response = await TargetService.addNewTarget(this.targetValues);

          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 30000);
        }
      }
    },
  },
};
</script>
