<template>
  <v-container>
    <v-card class="card">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="chartdata"
            :options="options"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="organizationInfo.organizationName"
            >
              {{ organizationInfo.organizationName }}
            </p>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationAddress"
            >
              {{ organizationInfo.organizationAddress }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationZipCode"
            >
              {{ organizationInfo.organizationZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationPostalDistrict"
            >
              {{ organizationInfo.organizationPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card class=" card" v-for="(t, index) in chartData" :key="index">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="t"
            :options="chartOptions[index]"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="targetInfo[index].targetName"
            >
              {{ targetInfo[index].targetName }}
            </p>
            <div class="text--primary" v-if="targetInfo[index].targetAddress">
              {{ targetInfo[index].targetAddress }}
            </div>
            <div class="text--primary" v-if="targetInfo[index].targetZipCode">
              {{ targetInfo[index].targetZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="targetInfo[index].targetPostalDistrict"
            >
              {{ targetInfo[index].targetPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import DoughnutChart from './DoughnutChart.js';

import TargetService from '@/services/TargetService.js';
import UserTargetService from '@/services/UserTargetService.js';
import UserService from '@/services/UserService.js';
import DiscussionService from '@/services/Tieto/DiscussionService.js';
import FeedbackService from '@/services/Tieto/FeedbackService.js';
import QueryService from '@/services/Tieto/QueryService.js';

export default {
  components: {
    DoughnutChart,
  },
  data: () => ({
    user: {},
    chartdata: null,
    count: 0,
    organizationInfo: {},
    targetInfo: [],
    chartData: [],
    chartOptions: [],
    targetAlarms: [],

    targets: [],

    options: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      rotation: 30,
      text: 0,
      label: 'Käyttäjät',
    },
  }),

  async mounted() {
    this.user = this.$store.getters.getUser;
    const masterDataSets = [];

    this.organizationInfo.organizationName = this.user.Organization.organizationName;
    this.organizationInfo.organizationAddress = this.user.Organization.organizationAddress;
    this.organizationInfo.organizationZipCode = this.user.Organization.organizationZipCode;
    this.organizationInfo.organizationPostalDistrict = this.user.Organization.organizationPostalDistrict;

    try {
      this.reset();

      let dataArr = [];

      let queryCount = 0;
      let feedbackCount = 0;

      let discussionData = [];
      let discussionCount = 0;

      const users = await UserService.getAllOrganizationUsers({
        organizationId: this.user.Organization.organizationId,
      });
      let userData = users.data.users;

      this.options.text = userData.length;
      const discussions = await DiscussionService.getAllDiscussionsByOrganizationDashboard(
        { organizationId: this.user.Organization.organizationId }
      );
      discussionData = discussions.data.discussions;
      if (discussionData) {
        discussionCount = discussionData.length;
      }
      const feedbacks = await FeedbackService.getAllOrganizationFeedbacks({
        organizationId: this.user.Organization.organizationId,
      });
      feedbackCount = feedbacks.data.feedbacks.length;
      const queries = await QueryService.getAllQueriesByOrganization({
        organizationId: this.user.Organization.organizationId,
      });
      queryCount = queries.data.query.length;

      dataArr.push(discussionCount);
      dataArr.push(feedbackCount);
      dataArr.push(queryCount);

      masterDataSets.push({
        label: 'Data One',
        backgroundColor: ['green', 'red', 'yellow'],
        data: dataArr,
      });
      this.chartdata = {
        labels: ['Keskustelut', 'Palautteet', 'Kyselyt'],
        datasets: masterDataSets,
      };

      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.user.Organization.organizationId,
      });
      this.targets = targets.data.targets;

      for (let i = 0; i < this.targets.length; i++) {
        const feedbacks = await FeedbackService.getAllTargetFeedbacks({
          feedbackTargetId: this.targets[i].targetId,
        });
        const targetFeedbacks = feedbacks.data.feedbacks.length;
        const discussions = await DiscussionService.getAllDiscussionsByTargets({
          discussionTargetId: this.targets[i].targetId,
        });
        const targetDiscussions = discussions.data.discussions.length;
        const queries = await QueryService.getAllQueriesByTargets({
          targetId: this.targets[i].targetId,
        });
        const targetQueries = queries.data.queries.length;
        const targetUsers = await UserTargetService.getAllUsersByTargets({
          targetId: this.targets[i].targetId,
        });
        const targetUsersCount = targetUsers.data.targets.length;
        this.chartOptions.push({
          legend: {
            display: true,
          },
          responsive: true,
          maintainAspectRatio: false,
          rotation: 30,
          text: targetUsersCount,
          label: 'Käyttäjät',
        });

        this.targetInfo.push({
          targetName: this.targets[i].targetName,
          targetAddress: this.targets[i].targetAddress,
          targetZipCode: this.targets[i].targetZipCode,
          targetPostalDistrict: this.targets[i].targetPostalDistrict,
        });
        let data = [];
        data.push(targetDiscussions, targetFeedbacks, targetQueries);
        this.chartData.push({
          labels: ['Keskustelut', 'Palautteet', 'Kyselyt'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: ['green', 'red', 'yellow'],
              data: data,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    reset() {
      this.chartdata = null;
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 50px;
}
</style>
