<!-- Show users data-table -->
<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="devices"
    sort-by="deviceState"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <!-- Show success/error messages -->
    <template v-slot:top>
      <v-text-field v-model="search" label="Hae..." class="mx-4" />
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>

      <v-toolbar flat>
        <v-toolbar-title>Näytä mittarit</v-toolbar-title>

        <v-spacer />

        <!-- Edit organization modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t('device.formTitle') }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceId"
                        :label="$t('device.id')"
                        prepend-icon="mdi-gauge"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceQrCode"
                        :label="$t('device.qrCode')"
                        prepend-icon="mdi-gauge"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceName"
                        :label="$t('device.name')"
                        prepend-icon="mdi-alphabetical"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-devices"
                        v-model="editDevices.deviceTypeId"
                        :items="deviceTypes"
                        :label="$t('device.typeId')"
                        placeholder="Valitse..."
                        item-text="deviceTypeName"
                        item-value="deviceTypeId"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceSerialnumber"
                        :label="$t('device.serialnumber')"
                        prepend-icon="mdi-numeric"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceLocation"
                        :label="$t('device.location')"
                        prepend-icon="mdi-home-variant "
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-home-city"
                        v-model="editDevices.deviceOrganizationId"
                        :items="organizations"
                        :label="$t('device.organization')"
                        placeholder="Valitse..."
                        item-text="organizationName"
                        item-value="organizationId"
                        no-data-text="Tietoa ei saatavilla"
                        @change="showTargets"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-home-outline"
                        v-model="editDevices.deviceTargetId"
                        :items="targets"
                        :label="$t('device.target')"
                        placeholder="Valitse..."
                        item-text="targetName"
                        item-value="targetId"
                        no-data-text="Tietoa ei saatavilla"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceGeoInfo"
                        :label="$t('device.geoInfo')"
                        prepend-icon="mdi-map-marker"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editDevices.deviceSwVersion"
                        :label="$t('device.swVersion')"
                        prepend-icon="mdi-counter"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-traffic-light"
                        v-model="editDevices.deviceState"
                        :items="states"
                        :label="$t('device.state')"
                        placeholder="Valitse..."
                        item-text="name"
                        item-value="value"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        :label="$t('device.description')"
                        v-model="editDevices.deviceDescription"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        :label="$t('device.action')"
                        v-model="editDevices.deviceText"
                        :rules="required"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-simple-table dense>
                        <thead>
                          <tr>
                            <th>Kohde</th>
                            <th>Historia</th>
                            <th>Tekijä</th>
                            <th>Päivämäärä</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(h, index) in deviceLimitHistory"
                            :key="index"
                          >
                            <td>{{ h.targetName }}</td>
                            <td>{{ h.deviceText }}</td>
                            <td>{{ h.fullName }}</td>
                            <td>{{ showDateAndTime(h.createdAt) }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Peruuta
                </v-btn>
                <v-btn color="blue darken-1" text @click="updateDevice">
                  Talleta
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog v-model="showHistories" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">Mittarin historia data</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-simple-table dense>
                      <thead>
                        <tr>
                          <th>Kohde</th>
                          <th>Historia</th>
                          <th>Tekijä</th>
                          <th>Päivämäärä</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(h, index) in deviceHistory" :key="index">
                          <td>{{ h.targetName }}</td>
                          <td>{{ h.deviceText }}</td>
                          <td>{{ h.fullName }}</td>
                          <td>{{ showDateAndTime(h.createdAt) }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="closeHistory">
                Sulje
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showAlarmLimitModal" max-width="750px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="headline">Hälytysrajat</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-flex
                    xs6
                    md6
                    v-for="(meas, index) in alarmLimits"
                    :key="index"
                  >
                    <v-text-field
                      :label="`${meas.measurementId} Min:`"
                      prepend-icon="mdi-alphabetical"
                      :rules="required"
                      v-model="deviceValuesMin[meas.measurementId]"
                      autocomplete="off"
                    />
                    <v-text-field
                      :label="`${meas.measurementId} Max:`"
                      prepend-icon="mdi-alphabetical"
                      :rules="required"
                      v-model="deviceValuesMax[meas.measurementId]"
                      autocomplete="off"
                    />
                  </v-flex>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeAlarmLimitsModal"
                >
                  Peruuta
                </v-btn>
                <v-btn color="blue darken-1" text @click="updateAlarmLimits">
                  Talleta muutokset
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Muokkaa mittarin tietoja</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mr-2"
            @click="showHistory(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>Näytä historia tiedot</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mr-2"
            @click="showAlarmLimits(item)"
            v-bind="attrs"
            v-on="on"
          >
            mdi-alarm-light
          </v-icon>
        </template>
        <span>Muuta hälytysrajat</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import DeviceService from '@/services/Vainu/DeviceService';
import OrganizationService from '@/services/OrganizationService';
import DeviceTypeService from '@/services/Vainu/DeviceTypeService';
import TargetService from '@/services/TargetService';
import DeviceHistoryService from '@/services/Vainu/DeviceHistoryService';
import AlarmLimitsService from '@/services/Vainu/AlarmLimitService';
import moment from 'moment';

export default {
  data() {
    return {
      devices: [],
      organizations: [],
      deviceTypes: [],
      targets: [],
      dialog: false,
      error: null,
      message: null,
      editDevices: {},
      search: '',
      showHistories: false,
      showAlarmLimitModal: false,
      histories: [],
      deviceLimitHistory: [],
      deviceHistory: [],
      alarmLimits: [],
      user: {},
      deviceValuesMax: [],
      deviceValuesMin: [],
      required: [(v) => !!v || 'Pakollinen kenttä'],
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
      headers: [
        {
          text: this.$t('device.id'),
          value: 'deviceId',
        },
        {
          text: this.$t('device.qrCode'),
          value: 'deviceQrCode',
        },
        {
          text: this.$t('device.name'),
          value: 'deviceName',
        },
        {
          text: this.$t('device.organization'),
          value: 'Organization.organizationName',
        },
        {
          text: this.$t('device.state'),
          value: 'deviceState',
        },

        {
          text: this.$t('showDevices.action'),
          value: 'actions',
          sortable: false,
        },
      ],

      states: [
        {
          name: this.$t('showDevices.device.active'),
          value: 'Käytössä',
        },
        {
          name: this.$t('showDevices.device.passive'),
          value: 'Pois käytöstä',
        },
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    showHistories(val) {
      val || this.closeHistory();
    },
    showAlarmLimitModal(val) {
      val || this.closeAlarmLimitsModal();
    },
  },
  /**
   * Show organization when page is loaded
   */
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.$route.query.qrCode) {
      this.search = this.$route.query.qrCode;
    }
    const devices = await DeviceService.getAllDevicesAdmin();
    this.devices = devices.data.devices;
  },

  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm');
    },
    // Add items to editOrganizations when pencil iscon is clicked
    async editItem(item) {
      this.editDevices = Object.assign({}, item);
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.editDevices.deviceOrganizationId,
      });
      this.targets = targets.data.targets;
      const organizations = await OrganizationService.getAllOrganizationsDevices();
      this.organizations = organizations.data.organizations;
      const deviceTypes = await DeviceTypeService.getAllDeviceTypes();
      this.deviceTypes = deviceTypes.data.deviceTypes;
      const deviceHistory = await DeviceHistoryService.getDeviceHistoryLimits({
        deviceId: item.deviceId,
      });
      this.deviceLimitHistory = deviceHistory.data.history;
      this.dialog = true;
    },
    async showHistory(item) {
      this.showHistories = true;
      this.editDevices = Object.assign({}, item);
      const deviceHistory = await DeviceHistoryService.getDeviceHistory({
        deviceId: item.deviceId,
      });
      this.deviceHistory = deviceHistory.data.history;
    },
    async showAlarmLimits(item) {
      this.showAlarmLimitModal = true;
      const alarmLimits = await AlarmLimitsService.getAlarmLimits({
        deviceId: item.id,
      });
      this.alarmLimits = alarmLimits.data.alarmLimits;

      for (let i = 0; i < this.alarmLimits.length; i++) {
        this.deviceValuesMin['id'] = this.alarmLimits[i].deviceId;
        this.deviceValuesMin[
          this.alarmLimits[i].measurementId
        ] = this.alarmLimits[i].lowLimit;

        this.deviceValuesMax[
          this.alarmLimits[i].measurementId
        ] = this.alarmLimits[i].highLimit;
      }
      console.log(this.deviceValuesMin);
    },
    // close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editDevices = Object.assign({}, this.organizations);
      });
    },
    closeHistory() {
      this.showHistories = false;
      this.$nextTick(() => {
        this.deviceHistory = Object.assign({}, this.histories);
      });
    },
    closeAlarmLimitsModal() {
      this.showAlarmLimitModal = false;
      this.$nextTick(() => {
        this.deviceHistory = Object.assign({}, this.histories);
      });
    },
    async showTargets() {
      try {
        const response = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.editDevices.deviceOrganizationId,
        });
        this.targets = response.data.targets;
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    /**
     * Save button is clicked from modal
     */
    async updateDevice() {
      if (this.$refs.form.validate()) {
        try {
          const device = await DeviceService.saveDeviceModification(
            this.editDevices
          );
          this.error = device.data.error;
          this.editDevices.userId = this.user.uuid;
          const history = await DeviceHistoryService.addNewDeviceHistory(
            this.editDevices
          );
          this.message = history.data.message;
          this.error = history.data.error;
          const updatedDevices = await DeviceService.getAllDevicesAdmin();
          this.devices = updatedDevices.data.devices;

          this.search = '';
          this.close();
          this.$refs.form.reset();
          setTimeout(() => {
            this.message = null;
            this.error = null;
          }, 3000);
        } catch (error) {
          this.close();
          this.error = 'Jokin meni vikaan';
          setTimeout(() => {
            this.error = null;
          });
        }
      }
    },
    async updateAlarmLimits() {
      let obj2 = [];
      for (let key in this.deviceValuesMax) {
        for (let key2 in this.deviceValuesMin) {
          if (key === key2) {
            let obj = {};
            obj.measurementId = key;
            obj.highLimit = this.deviceValuesMax[key];
            obj.lowLimit = this.deviceValuesMin[key2];
            obj.deviceId = this.deviceValuesMin['id'];
            obj2.push(obj);
          }
        }
      }
      try {
        const alarmLimits = await AlarmLimitsService.updateAlarmLimits({
          alarmLimits: obj2,
        });
        this.message = alarmLimits.data.message;
        this.error = alarmLimits.data.error;
        this.showAlarmLimitModal = false;
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        this.showAlarmLimitModal = false;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
