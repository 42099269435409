<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="750px">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Kommentti"
                  prepend-icon="mdi-information-variant"
                  v-model="discussionSubComment.comment"
                  autocomplete="off"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close">
            Peruuta
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Talleta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row dense>
      <v-col cols="12">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title class="headline">
                {{ subComments[0].TietoDiscussionComment.discussionComment }}
              </v-card-title>

              <v-card-subtitle />

              <v-card-actions>
                <v-btn @click="addNewDiscussionSubComment()">
                  Lisää kommentti
                </v-btn>

                <v-btn @click="backToComments()">
                  Takaisin
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" v-for="(comment, index) in subComments" :key="index">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
              <v-card-title>
                <v-avatar color="red" size="40">
                  <span class="white--text ">
                    {{
                      showNameLetters(
                        comment.User.userFirstName,
                        comment.User.userLastName
                      )
                    }}
                  </span>
                </v-avatar>
                <span class="subtitle-2 pl-2">{{
                  showDateAndTime(comment.createdAt)
                }}</span>
                <v-spacer />
                <v-card-actions
                  v-if="
                    user.userRoles === 'Pääkäyttäjä' ||
                      user.userRoles === 'Ylläpitäjä' ||
                      user.userRoles === 'Pääylläpitäjä' ||
                      user.uuid === discussion[0].discussionModerator
                  "
                >
                  <v-icon
                    @click="
                      modifySubComment(
                        comment.discussionSubComment,
                        comment.discussionCommentSubId
                      )
                    "
                  >
                    mdi-pencil
                  </v-icon>
                </v-card-actions>
              </v-card-title>
              <v-card-text v-show="comment.discussionSubComment.length <= 500">
                {{ comment.discussionSubComment }}
              </v-card-text>
              <v-card-text
                v-show="
                  !comment.Flag && comment.discussionSubComment.length > 500
                "
              >
                {{ comment.discussionSubComment | summary }}

                <a @click="toggler(comment, true)">Lue lisää</a>
              </v-card-text>
              <v-card-text v-show="comment.Flag">
                {{ comment.discussionSubComment }}

                <a @click="toggler(comment, false)">Näytä vähemmän</a>
              </v-card-text>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      showFirtsNameLastName(
                        comment.User.userFirstName,
                        comment.User.userLastName
                      )
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiscussionCommentSubService from '@/services/Tieto/DiscussionCommentSubService';
import DiscussionService from '@/services/Tieto/DiscussionService';
import moment from 'moment';
export default {
  filters: {
    summary: function(text) {
      return text.substring(0, 500) + ' ...';
    },
  },
  data() {
    return {
      subComments: [
        {
          TietoDiscussionComment: { discussionComment: '' },
        },
      ],
      discussionSubComment: {},
      dialog: false,
      item: '',
      user: {},
      discussion: [],
    };
  },

  async created() {
    this.user = this.$store.getters.getUser;

    try {
      const discussionSubComments = await DiscussionCommentSubService.getDiscussionSubCommentsByCommentsId(
        {
          discussionCommentId: this.$store.getters.getDiscussionCommentId,
        }
      );
      this.subComments = discussionSubComments.data.subComments;
      console.log(this.subComments);
    } catch (error) {
      this.error = error;
    }
    try {
      const discussion = await DiscussionService.getDiscussionByDiscussionId({
        discussionId: this.$store.getters.getDiscussionId,
      });

      this.discussion = discussion.data.discussion;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    toggler(obj, flag) {
      this.$set(obj, 'Flag', flag);
    },
    modifySubComment(comment, id) {
      this.discussionSubComment.comment = comment;
      this.discussionSubComment.id = id;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },

    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm');
    },
    showNameLetters(firstName, lastName) {
      if (firstName && lastName) {
        return firstName.substring(0, 1) + lastName.substring(0, 1);
      }
    },
    showFirtsNameLastName(firstName, lastName) {
      return firstName + ' ' + lastName;
    },
    showSubComments(id) {
      this.$store.dispatch('setDiscussionCommentId', id);
      this.$router.push({
        name: 'tieto/sub-comments',
      });
    },

    addNewDiscussionSubComment() {
      this.$router.push({
        name: 'tieto/add-new-sub-comment',
        // params: { discussionId: this.discussion.discussionId },
      });
    },
    backToComments() {
      this.$router.push({
        name: 'tieto/discussions',
      });
    },
    async save() {
      this.dialog = false;
      try {
        const response = await DiscussionCommentSubService.saveDiscussionSubCommentModification(
          this.discussionSubComment
        );
        this.discussionSubComment = {};
        this.message = response.data.message;
        this.error = response.error;

        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>

<style></style>
