import Api from '@/services/Api';

//Organization api cals
export default {
  getAllOrganizations() {
    return Api().get('/api/organizations', {});
  },

  saveOrganizationModification(credintials) {
    return Api().patch('/api/organization/', credintials, {});
  },
  addNewOrganization(credintials) {
    return Api().post('/api/organization', credintials, {});
  },
  getAllOrganizationsDevices() {
    return Api().get('/api/organizations', {});
  },
};
