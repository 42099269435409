<!-- Show users data-table -->
<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="devices"
    sort-by="deviceState"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <!-- <template v-slot:[`item.User.userFirstName`]="{ item }"
      >{{ item.User.userFirstName }} {{ item.User.userLastName }}</template
    > -->
    <!-- Show success/error messages -->
    <template v-slot:top>
      <v-text-field v-model="search" label="Hae..." class="mx-4" />
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Näytä mittarit</v-toolbar-title>

        <v-spacer />

        <!-- Edit organization modal -->
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('device.formTitle') }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editDevices.deviceId"
                      :label="$t('device.id')"
                      prepend-icon="mdi-gauge"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="deviceToUserValues.organizationId"
                      :items="organizations"
                      item-text="organizationName"
                      item-value="organizationId"
                      :label="$t('deviceToUser.organizationId')"
                      placeholder="Valitse..."
                      :rules="option"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account"
                      v-model="deviceToUserValues.deviceUserId"
                      :items="users"
                      item-text="userLastName"
                      item-value="uuid"
                      :label="$t('deviceToUser.userId')"
                      placeholder="Valitse..."
                      :rules="option"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-home-city"
                      v-model="deviceToUserValues.targetId"
                      :items="targets"
                      item-text="targetName"
                      item-value="targetId"
                      :label="$t('deviceToUser.targetId')"
                      placeholder="Valitse..."
                      :rules="option"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-gauge"
                      v-model="deviceToUserValues.deviceId"
                      :items="devices"
                      item-text="deviceId"
                      item-value="deviceId"
                      :label="$t('deviceToUser.deviceId')"
                      placeholder="Valitse..."
                      :rules="option"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-gauge"
                      v-model="deviceToUserValues.deviceMeasurements"
                      :items="measurements"
                      item-text="measurementName"
                      item-value="measurementID"
                      :label="$t('deviceToUser.measurements')"
                      placeholder="Valitse..."
                      :rules="option"
                      multiple
                      chips
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-traffic-light"
                      v-model="deviceToUserValues.measurementsState"
                      :items="measurementsState"
                      item-text="name"
                      item-value="value"
                      :label="$t('deviceToUser.state')"
                      placeholder="Valitse..."
                      :rules="option"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                Peruuta
              </v-btn>
              <v-btn color="blue darken-1" text @click="updateDevice">
                Talleta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template> -->
  </v-data-table>
</template>
<script>
import DeviceService from '@/services/Vainu/DeviceService';
import OrganizationService from '@/services/OrganizationService';
import DeviceTypeService from '@/services/Vainu/DeviceTypeService';
import TargetService from '@/services/TargetService';
import UserDeviceService from '@/services/Vainu/UserDeviceService';

export default {
  data() {
    return {
      option: [(v) => !!(v && v.length) || 'Pakollinen kenttä'],
      deviceToUserValues: {},
      devices: [],
      organizations: [],
      deviceTypes: [],
      targets: [],
      dialog: false,
      error: null,
      message: null,
      editDevices: {},
      search: '',
      users: [],
      user: {},
      measurements: [],

      targetOrganizationId: null,
      organizationId: null,
      targetUserId: null,

      headers: [
        {
          text: this.$t('device.id'),
          value: 'deviceId',
        },

        {
          text: this.$t('device.organization'),
          value: 'Organization.organizationName',
        },
        {
          text: this.$t('device.target'),
          value: 'Target.targetName',
        },
        {
          text: this.$t('device.userName'),
          value: 'fullName',
        },
        {
          text: this.$t('device.name'),
          value: 'Devices.deviceName',
        },
        {
          text: this.$t('device.state'),
          value: 'measurementsState',
        },

        // {
        //   text: this.$t('showDevices.action'),
        //   value: 'actions',
        //   sortable: false,
        // },
      ],

      measurementsState: [
        {
          name: this.$t('showDevices.device.active'),
          value: 'Käytössä',
        },
        {
          name: this.$t('showDevices.device.passive'),
          value: 'Pois käytöstä',
        },
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show organization when page is loaded
   */
  async created() {
    if (this.$route.query.id) {
      this.search = this.$route.query.id;
    }
    const devices = await UserDeviceService.getAllUserDevicesAdmin();
    this.devices = devices.data.devices;
  },

  methods: {
    // Add items to editOrganizations when pencil iscon is clicked
    async editItem(item) {
      this.editDevices = Object.assign({}, item);
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.editDevices.deviceOrganizationId,
      });
      this.targets = targets.data.targets;
      const organizations = await OrganizationService.getAllOrganizationsDevices();
      this.organizations = organizations.data.organizations;
      const deviceTypes = await DeviceTypeService.getAllDeviceTypes();
      this.deviceTypes = deviceTypes.data.deviceTypes;
      this.dialog = true;
    },
    // close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editDevices = Object.assign({}, this.organizations);
      });
    },
    async showTargets() {
      try {
        const response = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.editDevices.deviceOrganizationId,
        });
        this.targets = response.data.targets;
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    /**
     * Save button is clicked from modal
     */
    async updateDevice() {
      try {
        const device = await DeviceService.saveDeviceModification(
          this.editDevices
        );
        this.message = device.data.message;
        this.error = device.data.error;
        const updatedDevices = await DeviceService.getAllDevicesAdmin();
        this.devices = updatedDevices.data.devices;

        this.search = '';
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
