import Api from '@/services/Api';

//Measurement api cals
export default {
  getAllMeasurements() {
    return Api().get('/api/measurements', {});
  },

  saveMeasurementModification(credintials) {
    return Api().patch('/api/measurements/', credintials, {});
  },
  addNewMeasurement(credintials) {
    return Api().post('/api/measurements', credintials, {});
  },
  getAllTMeasurementsByDeviceId(params) {
    return Api().get('/api/measurements-by-deviceId', { params });
  },
};
