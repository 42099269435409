<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Vaihda salasana</h4></v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Vanha salasana"
                  prepend-icon=""
                  :rules="passwordRules"
                  v-model="changePwd.userCurrentPassword"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Uusi salasana"
                  prepend-icon=""
                  :rules="passwordRules"
                  v-model="changePwd.userPassword"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Salasana uudelleen"
                  prepend-icon=""
                  :rules="passwordRules"
                  v-model="changePwd.confirmPassword"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="changePassword"
                >
                  Vaihda salasana
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService.js';
export default {
  data() {
    return {
      changePwd: {},
      message: null,
      error: null,
      show1: false,
      show2: false,
      show3: false,
      passwordRules: [
        (v) => !!v || 'Salasana on pakollinen',
        (v) => (v && v.length >= 8) || 'Salasanan oltava vähintään 8 merkkiä',
      ],
    };
  },
  methods: {
    async changePassword() {
      if (this.$refs.form.validate()) {
        try {
          if (this.changePwd.userPassword === this.changePwd.confirmPassword) {
            this.changePwd.id = this.$store.state.user.id;

            const response = await AuthenticationService.changePassword(
              this.changePwd
            );

            this.message = response.data.message;
            this.error = response.error;
            setTimeout(() => {
              this.error = null;
              this.message = null;
              this.$refs.form.reset();
            }, 3000);
          } else {
            this.error = 'Salasanat eivät täsmänneet. Yritä uudelleen.';
          }
        } catch (error) {
          this.error = error.response.data.error;
          // setTimeout(() => {
          //   this.error = null;
          // }, 3000);
        }
      }
    },
  },
};
</script>
