<template>
  <v-app>
    <v-container class="mt-10">
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
            <v-card-text>
              <h3>
                <!-- <p>
                  Kerro mielipiteesi, anna palautetta, pyydä uutta keskustelun
                  aihetta tai kysy neuvoa palvelun käytöstä.
                </p> -->
                <p>
                  Kerro mielipiteesi, anna palautetta tai ilmoita ongelmasta.
                  Yhteydenotto välitetään ilman lähettäjän tietoja, jos haluat
                  vastauksen lisää viestiin yhteystietosi.
                </p>
                <p>
                  Jos haluat että viestisi välitetään tietylle henkilölle tai
                  osastolle mainitse siitä erikseen.
                </p>
                <p>Sinulle ei välttämättä lähetetä erillistä vastausta.</p>
              </h3>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-container>
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex xs12 md8>
          <v-alert :value="true" dense outlined type="error" v-if="error">
            {{ error }}
          </v-alert>
          <v-alert :value="true" dense outlined type="success" v-if="message">
            {{ message }}
          </v-alert>

          <v-form ref="form" lazy-validation>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12 text-left><h4>Palaute / Kysely</h4></v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Otsikko"
                    prepend-icon=""
                    :rules="titleRules"
                    v-model="addFeedbackValues.feedbackTitle"
                    autocomplete="off"
                  />
                </v-flex>
                <v-flex xs12 md12>
                  <v-autocomplete
                    v-model="addFeedbackValues.feedbackTargetId"
                    :items="targets"
                    item-text="Target.targetName"
                    item-value="Target.targetId"
                    label="Valitse kohde:"
                    class="mt-1"
                    :props="{
                      color: '#ffffff',
                    }"
                  />
                </v-flex>
                <v-flex xs12 md12>
                  <v-textarea
                    label="Palaute / Kysymys"
                    prepend-icon=""
                    v-model="addFeedbackValues.feedbackMessage"
                    autocomplete="off"
                    :rules="feedbackRules"
                    required
                    counter="500"
                  />
                </v-flex>

                <v-flex xs12 text-xs-right>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="addNewFeedback"
                  >
                    Lähetä palaute
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import FeedbackService from '@/services/Tieto/FeedbackService.js';
import TargetService from '@/services/TargetService.js';

export default {
  data() {
    return {
      feedbackState: [
        { name: 'Uusi', value: 'Uusi' },
        { name: 'Suljettu', value: 'Suljettu' },
      ],
      addFeedbackValues: {},
      message: null,
      error: null,
      targets: [],
      user: {},
      titleRules: [
        (v) => !!v || 'Otsikko on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Otsikon pitää olla 3 merkkiä tai enemmän',
      ],
      feedbackRules: [
        (v) => !!v || 'Viesti on pakollinen tieto',
        (v) =>
          (v && v.length >= 3 && v.length < 501) ||
          'Viestin pitää olla vähintään 3 merkkiä tai enintään 500 merkkiä',
      ],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
    try {
      const targets = await TargetService.getAllUserTargets({
        userId: this.user.uuid,
      });

      this.targets = targets.data.targets;
      await this.$store.dispatch(
        'setUserActiveTarget',
        this.targets[0].targetId
      );
      this.userTargets = this.$store.getters.getUserActiveTarget;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    async addNewFeedback() {
      if (this.$refs.form.validate()) {
        try {
          this.addFeedbackValues.organizationId = this.user.Organization.organizationId;
          const response = await FeedbackService.addNewFeedback(
            this.addFeedbackValues
          );
          this.addFeedbackValues = {};
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
            this.$router.push({
              name: 'tieto/dashboard',
            });
          }, 3000);
        } catch (error) {
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
