<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="deviceId"
      class="elevation-1"
      show-select
      :value="selectedItems"
      @input="onSelectedItemsChange"
      loading-text="Ladataan..."
      no-data-text="Tietoa ei saatavilla"
      :footer-props="{
        'items-per-page-text': 'Rivejä sivulla',
        'items-per-page-all-text': 'Kaikki',
      }"
    >
      <template v-slot:top>
        <!-- <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert> -->

        <div>
          <div class="d-flex align-center flex-wrap">
            <v-autocomplete
              multiple
              style="width: 300px"
              class="flex-grow-0 flex-shrink-0 ml-4"
              :items="targetNames"
              :value="selectedTargets"
              :return-object="true"
              label="Valitse kohde"
              no-data-text="Tietoa ei saatavilla"
              @input="onTargetChange"
            />
            <!-- <v-select
              style="width: 200px"
              class="flex-grow-0 flex-shrink-0 ml-4"
              :items="targetNames"
              :value="selectedTarget"
              :return-object="true"
              label="Valitse kohde"
              no-data-text="Tietoa ei saatavilla"
              @change="onTargetChange"
            /> -->
            <v-select
              style="width: 200px"
              class="flex-grow-0 flex-shrink-0 mx-4"
              :items="measurementNames"
              :value="selectedMeasurement"
              :return-object="true"
              label="Valitse mittaus"
              no-data-text="Tietoa ei saatavilla"
              @change="onMeasurementChange"
            />
          </div>
          <date-range-picker
            :start-date="$route.query.sd || ''"
            :end-date="$route.query.ed || ''"
            @change="onDateRangeChange"
          />
          <v-btn color="primary" class="ml-4" @click="createPDF">
            Luo pdf raportti
          </v-btn>
          <!-- <div class="d-flex align-center flex-wrap mx-4">
            <v-btn :disabled="!someSelected" color="primary" :to="chartViewPath">Näytä kuvaajat</v-btn>
          </div> -->
        </div>
      </template>

      <!-- <template v-slot:[`item.state`]="{ item }">
        <v-btn
          v-if="!item.state"
          small
          color="general"
          @click="acknowledgeAlarm(item)"
        >Kuittaa</v-btn>
      </template> -->
    </v-data-table>

    <sensor-chart ref="chartRef" />

    <v-overlay :value="overlay">
      <v-progress-circular
        :rotate="360"
        :size="150"
        :width="15"
        :value="value + `%`"
        color="white"
      >
        {{ value }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Api from '@/services/Api';
const api = Api();
import DateRangePicker from '../../Common/DateRangePicker.vue';
import SensorChart from '../Charts/SensorChart.vue';
import { arrayify } from '../../../utils';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import moment from 'moment';
import logo from '@/assets/vepa.png';
import cells from '@/assets/kennot.png';
import footer from '@/assets/footer.png';
import table from '@/assets/kaavio.png';
import TargetService from '@/services/TargetService';

export default {
  components: { DateRangePicker, SensorChart },
  data() {
    return {
      logo: logo,
      cells: cells,
      footer: footer,
      table: table,
      overlay: false,
      value: null,
      currentTarget: [],
      targetNames: [],
      measurementNames: [],
      items: [],
      headers: [
        { text: 'Kohde', value: 'targetName' },
        { text: 'Mittarin ID', value: 'deviceId' },
        { text: 'Mittarin sijainti', value: 'deviceLocation' },
        { text: 'Uusin arvo', value: 'value' },
        { text: 'Min', value: 'min_value' },
        { text: 'Max', value: 'max_value' },
        { text: 'Keskiarvo', value: 'avg_value' },
      ],
    };
  },
  computed: {
    selectedTargets() {
      let tid = this.$route.query.tid;
      tid = arrayify(tid);
      const arr = this.targetNames.filter((it) =>
        tid.includes(it.value.toString())
      );
      return arr;
    },
    selectedMeasurement() {
      if (this.$route.query.mid) {
        return this.measurementNames.find(
          (it) => it.value == this.$route.query.mid
        );
      }
      return null;
    },
    someSelected() {
      for (let it of this.items) {
        if (this.selectedItems.find((sit) => sit.id == it.id)) {
          return true;
        }
      }
      return false;
    },
    selectedItems() {
      let { did } = this.$route.query;
      did = arrayify(did);
      return this.items.filter((sit) => did.includes(sit.deviceId));
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData(to);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData(to);
    next();
  },
  methods: {
    async fetchData(route) {
      try {
        const { tid, mid, sd, ed } = route.query;

        const promises = [api.get('/api/targets/names')];

        if (tid) {
          promises.push(
            api.get('/api/measurements-by-target', {
              params: { tid },
            })
          );
        }

        if (mid) {
          promises.push(
            api.get('/api/target-measurements', {
              params: { tid, mid, sd, ed },
            })
          );
        }

        const resArr = await Promise.all(promises);
        const targetNames = resArr[0].data;
        this.targetNames = [
          //{ value: null, text: 'Kaikki kohteet' },
          ...targetNames.map((it) => ({
            value: it.id,
            text: it.targetName,
          })),
        ];
        if (tid) {
          const measurementNames = resArr[1].data;
          this.measurementNames = measurementNames.map((it) => ({
            value: it.measurementID,
            text: it.measurementName,
          }));
        }
        if (mid) {
          const measurementData = resArr[2].data;
          this.items = measurementData.map((dt) => ({
            ...dt,
            value: Number(Number(dt.value).toFixed(3)),
            min_value: Number(Number(dt.min_value).toFixed(3)),
            max_value: Number(Number(dt.max_value).toFixed(3)),
            avg_value: Number(Number(dt.avg_value).toFixed(3)),
          }));
        }
        this.$refs.chartRef.fetchData(route);
      } catch (err) {
        console.error(err);
      }
    },
    onDateRangeChange({ startDate, endDate }) {
      const query = { ...this.$route.query };
      if (endDate) {
        query.ed = endDate;
      } else {
        delete query.ed;
      }
      if (startDate) {
        query.sd = startDate;
      } else {
        delete query.sd;
      }
      this.$router.replace({ query });
    },
    onTargetChange(items) {
      const query = { ...this.$route.query };
      query.tid = items.map((it) => it.value);
      this.$router.replace({ query });
    },
    onMeasurementChange(e) {
      const query = { ...this.$route.query };
      query.mid = e.value;
      this.$router.replace({ query });
    },
    onAllSelectChange(value) {
      const query = { ...this.$route.query };
      if (value) {
        query.did = this.items.map((it) => it.deviceId);
      } else {
        delete query.did;
      }
      this.$router.replace({ query });
    },
    onSelectedItemsChange(items) {
      const query = { ...this.$route.query };
      query.did = items.map((it) => it.deviceId);
      this.$router.replace({ query });
    },
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },

    async createPDF() {
      this.overlay = true;
      let deviceArr = this.selectedItems;
      let values = deviceArr.length + 1;
      let targetId = this.$route.query.tid;

      try {
        const target = await TargetService.getTargetsById({
          targetId: targetId,
        });
        this.currentTarget = target.data.targets;
      } catch (error) {
        console.log(error);
      }
      console.log(this.currentTarget);
      // const organization = document.getElementById('organization-info')
      //   .textContent;
      //let o = organization.split(',');
      let zipPost =
        this.currentTarget[0].targetAddress +
        ', ' +
        this.currentTarget[0].targetZipCode +
        ' ' +
        this.currentTarget[0].targetPostalDistrict;

      // const columns = [
      //   { title: 'Kohde', dataKey: 'targetName' },
      //   { title: 'Mittarin ID', dataKey: 'deviceId' },
      //   { title: 'Mittarin sijainti', dataKey: 'deviceLocation' },
      //   { title: 'Uusin arvo', dataKey: 'value' },
      //   { title: 'Min', dataKey: 'min_value' },
      //   { title: 'Max', dataKey: 'max_value' },
      //   { title: 'Keskiarvo', dataKey: 'avg_value' },
      // ];

      let pdf = new jsPDF('landscape', 'mm');
      let startDay = this.$route.query.sd;
      let endDay = this.$route.query.ed;
      pdf.setFont('PTSans');

      let title = 'Sisäympäristön olosuhdeseuranta raportti';

      let time =
        this.showDateAndTime(startDay) + '-' + this.showDateAndTime(endDay);
      pdf.setFontSize(16);
      pdf.text(title, 20, 20);
      pdf.setFontSize(12);
      pdf.text(time, 20, 30);
      pdf.text(this.currentTarget[0].targetName, 20, 40);
      pdf.text(zipPost, 20, 50);
      pdf.addImage(this.logo, 260, 15, 20, 20, '', 'FAST');
      pdf.addImage(this.cells, 100, 70, 87, 87, '', 'FAST');
      pdf.addImage(this.footer, 20, 180, 100, 10, '', 'FAST');
      pdf.text('Vallikallionkatu 1, 02650 Espoo', 20, 195);
      pdf.text('0207288400', 20, 200);

      // pdf.autoTable({
      //   columns,
      //   body: this.items,
      //   startY: 220,
      // });
      this.value = 100 / values;

      await html2canvas(document.getElementById('line-chart-main'), {
        height: 1800,
        width: window.innerWidth * 2,
        scale: 1,
        dpi: 300,
      }).then((canvas) => {
        var dataURL = canvas.toDataURL();
        pdf.addPage('A4', 'landscape');
        pdf.addImage(dataURL, 'PNG', 10, 10, 700, 600, '', 'FAST'); //addImage(image, format, x-coordinate, y-coordinate, width, height)
      });

      //creates PDF from img

      for (let i = 0; i < deviceArr.length; i++) {
        let chart = 'line-chart-' + deviceArr[i].deviceId;
        this.value = parseInt(100 / (values - (i + 1)));

        await html2canvas(document.getElementById(chart), {
          allowTaint: true,
          height: 1800,
          width: window.innerWidth * 2,
          scale: 1,
          dpi: 300,
        }).then((canvas) => {
          canvas.getContext('2d');
          var dataURL = canvas.toDataURL();
          pdf.addPage();
          pdf.addImage(dataURL, 'PNG', 10, 10, 700, 600, '', 'FAST'); //addImage(image, format, x-coordinate, y-coordinate, width, height)
        });
      }

      pdf.addPage();
      pdf.text(
        'Vepan Vainu-sovelluksessa voidaan käyttää useita erilaisia olosuhdeseurantamittareita. Raportti muodostuu',
        20,
        20
      );
      pdf.text(
        'valitulla aikavälillä ja valituista mittauksista. Sovelluksessa käytettäviä raja-arvoja voidaan muokata eri',
        20,
        25
      );
      pdf.text(
        'käyttötarkoituksiin sopiviksi, joten ne eivät välttämättä ole samat kuin alla mainitut asumisterveysasetuksesta',
        20,
        30
      );
      pdf.text(
        'ja soveltamisohjeesta poimitut toimenpiderajat tai muut suositukset.',
        20,
        35
      );
      pdf.text('ILMAN LÄMPÖTILA', 20, 45);
      pdf.text(
        'Palvelutaloissa, vanhainkodeissa, lasten päivähoitopaikoissa,',
        20,
        55
      );
      pdf.text('oppilaitoksissa ja vastaavissa tiloissa', 20, 60);
      pdf.text('Lämpötila °C', 200, 55);
      pdf.text('Huoneilman lämpötila lämmityskaudella', 20, 65);
      pdf.text('+ 20 °C – + 26 °C', 200, 65);
      pdf.text(
        'Huoneilman lämpötila lämmityskauden ulkopuolella lasten',
        20,
        70
      );
      pdf.text('+ 20 °C – + 32 °C', 200, 70);
      pdf.text(
        'päivähoitopaikat, oppilaitokset ja muut vastaavat tilat',
        20,
        75
      );
      pdf.text(
        'Huoneilman lämpötila lämmityskauden ulkopuolella, palvelutalot,',
        20,
        80
      );
      pdf.text('+ 20 °C – + 32 °C', 200, 80);
      pdf.text('vanhainkodit ja muut vastaavat tilat', 20, 85);

      pdf.textWithLink(
        `https://www.finlex.fi/fi/laki/alkup/2015/20150545?search%5Btype%5D=pika&search%5Bpika%5D=545%2F2015`,
        20,
        95,
        {
          url: `https://www.finlex.fi/fi/laki/alkup/2015/20150545?search%5Btype%5D=pika&search%5Bpika%5D=545%2F2015`,
        }
      );
      pdf.text('ILMANKOSTEUS', 20, 105);
      pdf.text(
        'Pykälässä ei säädetä tarkkoja suhteellisen kosteuden rajoja, joiden välillä ilman suhteellinen kosteus (RH %) voi vaihdella,',
        20,
        115
      );
      pdf.text(
        'Huoneilman kosteus voi vaihdella lyhytkestoisesti ulkoilman kosteudesta ja rakennuksessa harjoitetusta toiminnasta',
        20,
        120
      );
      pdf.text(
        'riippuen hyvin paljon ja tällöin voi syntyä tarve kostuttaa tai kuivattaa huoneilmaa, vaikka se ei olisi huoneilmaa,',
        20,
        125
      );
      pdf.text(
        'vaikka se ei olisi terveydensuojelun näkökulmasta tarpeellista. Huoneilman suhteellisen kosteuden suosituksena ',
        20,
        130
      );
      pdf.text('on aiemmin ollut 20–60 % ', 20, 135);
      pdf.textWithLink(
        `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje/ac8d5e16-97be-456c-9c9c-ce8560f2092e`,
        20,
        145,
        {
          url: `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje/ac8d5e16-97be-456c-9c9c-ce8560f2092e`,
        }
      );
      pdf.text(
        'Huom! Olosuhdeseuranta mittauksen tuloksia seurattaessa toimenpideraja-arvona voidaan pitää lukua 1550 ppm',
        20,
        155
      );
      pdf.text('(1150 ppm + 400 pp = 1550 ppm).', 20, 160);
      pdf.text(
        'Sisäilmastoluokitus 2018:n mukaan sisäilman hiilidioksidipitoisuuden enimmäisarvot ovat sisäilmastoluokittain seuraavat:',
        20,
        165
      );
      pdf.text(
        'Luvuissa on on huomioitu ulkoilman pitoisuus 400 ppm.',
        20,
        170
      );
      pdf.text(
        'Sisäilmastoluokka S1, yksilöllinen sisäilmasto, 750 ppm',
        45,
        175
      );
      pdf.text('Sisäilmastoluokka S2, hyvä sisäilmasto, 950 ppm', 45, 180);
      pdf.text(
        'Sisäilmastoluokka S3, tyydyttävä sisäilmasto, 1200 ppm',
        45,
        185
      );
      pdf.addPage();
      pdf.text('HIUKKASET', 20, 20);
      pdf.text(
        'Hengitettävien hiukkasten (PM10) pitoisuus sisäilmassa 24 tunnin mittauksen aikana saa olla enintään 50 \u00B5g/m3.',
        20,
        40
      );
      pdf.text(
        'Pienhiukkasten (PM2,5) pitoisuus sisäilmassa 24 tunnin mittauksen aikana saa olla enintään 25 \u00B5g/m3..',
        20,
        45
      );
      pdf.text(
        'Teollisten mineraalikuitujen toimenpideraja kahden viikon aikana pinnoille laskeutuneessa pölyssä on 0,2 kuitua/cm2 .',
        20,
        50
      );
      pdf.text(
        'Asbestikuitujen esiintymistä pinnoille laskeutuneessa pölyssä pidetään toimenpiderajan ylittymisenä.',
        20,
        55
      );
      pdf.text(
        'Sisäilman asbestikuitujen pitoisuus ei saa ylittää 0,01 kuitua/cm3.',
        20,
        60
      );

      pdf.textWithLink(
        `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje+osa+III.pdf/997eeca1-53f7-4d4e-bb7a-df6ef7ee0e9c`,
        20,
        70,
        {
          url: `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje+osa+III.pdf/997eeca1-53f7-4d4e-bb7a-df6ef7ee0e9c`,
        }
      );

      pdf.text(
        'Huom! Hiukkasmittareiden ja keräävien menetelmien (näihin perustuvat ylläolevat rajaarvot)tuloksia ei voida suoraan verrata toisiinsa.',
        20,
        80
      );
      pdf.text(
        'Jatkuvien hiukkasmittausten tulokset ovat suuntaanantavia.',
        20,
        85
      );
      pdf.text('PAINE-EROT', 20, 95);
      pdf.text(
        'Paine-eroa mitataan tavallisimmin rakennuksen ulkovaipan yli. Paine-eroille ei ole asetettu tavoitearvoja.',
        20,
        105
      );
      pdf.text(
        'Sopivaksi paine-eroksi katsotaan lievä alipaine eli 0 - 5 Pa. Rakennuksen paine-erot voivat vaihdella rajustikin,',
        20,
        110
      );
      pdf.text(
        'minkä takia jatkuvalla pidempiaikaisella seurannalla saadaan hyvää kuvaa rakennuksen painesuhteista päätöksenteon perusteeksi.',
        20,
        115
      );
      pdf.text('Paineeroseurannassa tulee huomioida myös säätiedot.', 20, 120);
      pdf.text(
        'Paine-eroseurannan avulla voidaan varmentaa ilmanvaihdon toimivuutta ja havainnollistaa sen mahdollisia puutteita tai virheitä.',
        20,
        130
      );
      pdf.text(
        'Paine-eroja tulisi tällöin seurata pidemmällä yli kahden viikon tarkastelujaksolla.',
        20,
        135
      );
      pdf.addPage();
      pdf.text('TVOC', 20, 20);
      pdf.text(
        'Haihtuvien orgaanisten yhdisteiden tolueenivasteella lasketun kokonaispitoisuuden toimenpideraja huoneilmassa on 400 \u00B5g/m³.',
        20,
        30
      );
      pdf.text(
        'Yksittäisen haihtuvan orgaanisen yhdisteentolueenivasteella lasketun pitoisuuden toimenpideraja huoneilmassa on 50 \u00B5g/m³.',
        20,
        35
      );
      pdf.textWithLink(
        `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje+osa+III.pdf/997eeca1-53f7-4d4e-bb7a-df6ef7ee0e9c`,
        20,
        45,
        {
          url: `https://www.valvira.fi/documents/14444/261239/Asumisterveysasetuksen+soveltamisohje+osa+III.pdf/997eeca1-53f7-4d4e-bb7a-df6ef7ee0e9c`,
        }
      );
      pdf.text(
        'Suomessa ei ole määritelty raja-arvoja jatkuvalle tvoc-mittaukselle. Alla oleva taulukko on mukaeltu dokumentista',
        20,
        55
      );
      pdf.text(
        '”Tvoc guidelines by the Germany Federal Environmental Agency”.',
        20,
        60
      );
      pdf.text(
        'Tvoc tason katsotaan olevan hyvä, eikä toimenpiteitä tarvita, jos arvot asettuvat välillä 0-220 ppb (parts per billon).',
        20,
        65
      );
      pdf.addImage(this.table, 40, 80, 225, 120, '', 'FAST');
      await setTimeout(() => {
        pdf.save('Testi.pdf');
        this.overlay = false;
      }, 0);
    },
  },
};
</script>

<style></style>
