import Api from '@/services/Api';

//Organization api cals
export default {
  getAllUsersByTargets(params) {
    return Api().get('/api/user-by-targets', { params });
  },
  removeTargetModification(credintials) {
    return Api().patch('/api/target-contact-user-data-remove', credintials, {});
  },
};
