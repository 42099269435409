<template>
  <!-- Show user data-table -->
  <v-data-table
    :headers="headers"
    :items="users"
    sort-by="users"
    class="elevation-1"
    no-data-text="Tietoa ei saatavilla"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <!-- Display success and error messages -->
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Käyttäjät</v-toolbar-title>
      </v-toolbar>
      <v-spacer />
      <!-- Edit user modal -->
      <v-dialog v-model="dialog" max-width="750px">
        <v-card>
          <v-card-title flat>
            {{ formTitle }}
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userEmail"
                    :label="$t('editUser.userEmail')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userFirstName"
                    :label="$t('editUser.userFirstName')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userLastName"
                    :label="$t('editUser.userLastName')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userPhoneNumber"
                    :label="$t('editUser.userPhoneNumber')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="editedItem.userJobTitle"
                    :label="$t('editUser.userJobTitle')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="user.userRoles === 'Pääylläpitäjä'"
                >
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userOrganization"
                    :items="organizations"
                    :label="$t('editUser.userOrganization')"
                    placeholder="Valitse..."
                    item-text="organizationName"
                    item-value="organizationId"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="editedItem.UserTargets"
                    :items="targets"
                    :label="$t('editUser.userTargets')"
                    placeholder="Valitse..."
                    item-text="targetName"
                    item-value="targetId"
                    multiple
                    chips
                  />
                </v-col>

                <v-flex xs12 md6 v-if="user.userRoles === 'Pääylläpitäjä'">
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userPermissions"
                    :items="applications"
                    item-text="applicationName"
                    item-value="applicationId"
                    :label="$t('register.userPermissions')"
                    placeholder="Valitse..."
                    required
                    multiple
                    chips
                  />
                </v-flex>
                <v-flex xs12 md6 v-else>
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userPermissions"
                    :items="applications"
                    item-text="Application.applicationName"
                    item-value="Application.applicationId"
                    :label="$t('register.userPermissions')"
                    placeholder="Valitse..."
                    required
                    multiple
                    chips
                  />
                </v-flex>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="user.userRoles != 'Pääylläpitäjä'"
                >
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userRoles"
                    :items="roles"
                    :label="$t('editUser.userRoles')"
                    placeholder="Valitse..."
                    item-text="name"
                    item-value="value"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6" v-else>
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userRoles"
                    :items="rolesAdmin"
                    :label="$t('editUser.userRoles')"
                    placeholder="Valitse..."
                    item-text="name"
                    item-value="value"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    prepend-icon=""
                    v-model="editedItem.userState"
                    :items="states"
                    :label="$t('editUser.userState')"
                    placeholder="Valitse..."
                    item-text="name"
                    item-value="value"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="editedItem.userInformation"
                    :label="$t('editUser.userInformation')"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    :label="$t('editUser.userDescription')"
                    v-model="editedItem.userDescription"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close">
              Sulje
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Talleta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import UserService from '@/services/UserService.js';
import OrganizationService from '@/services/OrganizationService.js';
import UserTargetService from '@/services/UserTargetService.js';
import ApplicationService from '@/services/ApplicationService.js';
import TargetService from '@/services/TargetService.js';

export default {
  data() {
    return {
      applications: [],
      users: [],
      organizations: [],
      userTargetData: [],
      dialog: false,
      targets: [],
      error: null,
      message: null,
      formTitle: 'Muokkaa käyttäjän tietoja',
      user: [],
      tar: [
        '5e4cd9ea-4cb7-4179-bfd7-5acfef420c9c',
        '09a93997-84ed-4e9c-a66c-048111392f93',
      ],
      headers: [
        { text: this.$t('showUsers.userEmail'), value: 'userEmail' },
        { text: this.$t('showUsers.userFirstName'), value: 'userFirstName' },
        { text: this.$t('showUsers.userLastName'), value: 'userLastName' },
        {
          text: this.$t('showUsers.userPhoneNumber'),
          value: 'userPhoneNumber',
        },
        { text: this.$t('showUsers.userJobTitle'), value: 'userJobTitle' },
        { text: this.$t('showUsers.userRoles'), value: 'userRoles' },
        {
          text: this.$t('showUsers.userOrganization'),
          value: 'Organization.organizationName',
        },
        { text: this.$t('showUsers.userState'), value: 'userState' },
        {
          text: this.$t('showUsers.userActions'),
          value: 'actions',
          sortable: false,
        },
      ],
      roles: [
        { name: 'Käyttäjä', value: 'Käyttäjä' },
        { name: 'Pääkäyttäjä', value: 'Pääkäyttäjä' },
        { name: 'Ylläpitäjä', value: 'Ylläpitäjä' },
      ],
      rolesAdmin: [
        { name: 'Käyttäjä', value: 'Käyttäjä' },
        { name: 'Pääkäyttäjä', value: 'Pääkäyttäjä' },
        { name: 'Ylläpitäjä', value: 'Ylläpitäjä' },
        { name: 'Pääylläpitäjä', value: 'Pääylläpitäjä' },
      ],

      states: [
        {
          name: this.$t('showUsers.userStateItems.active'),
          value: 'Aktiivinen',
        },
        {
          name: this.$t('showUsers.userStateItems.passive'),
          value: 'Passiivinen',
        },
      ],

      editedItem: {
        userEmail: '',
        userFirstName: '',
        userLastName: '',
        userPhoneNumber: '',
        userJobTitle: '',
        userRoles: '',
        userState: '',
        userOrganization: '',
        userInformation: '',
        userId: '',
        userPermissions: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  /**
   * Show user when page is loaded
   */
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.user.userRoles === 'Pääylläpitäjä') {
      const users = await UserService.getAllUsers();
      this.users = users.data.users;
    } else {
      const users = await UserService.getAllOrganizationUsers({
        organizationId: this.user.Organization.organizationId,
      });
      this.users = users.data.users;
    }
    for (let i = 0; i < this.users.length; i++) {
      let targetItems = [];
      for (let y = 0; y < this.users[i].UserTargets.length; y++) {
        targetItems.push(this.users[i].UserTargets[y].targetId);
      }
      this.users[i].UserTargets = targetItems;
    }
    for (let i = 0; i < this.users.length; i++) {
      this.users[i].userPermissions = this.users[i].userPermissions.split(',');
    }
    const organizations = await OrganizationService.getAllOrganizations();
    this.organizations = organizations.data.organizations;
  },
  //Edit pencil is pressed
  methods: {
    async editItem(item) {
      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: item.userOrganization,
      });
      this.targets = targets.data.targets;
      try {
        if (this.user.userRoles === 'Pääylläpitäjä') {
          const applications = await ApplicationService.getAllApplications();
          this.applications = applications.data.applications;
        } else {
          const applications = await ApplicationService.getAllOrganizationApplications(
            { organizationId: this.user.Organization.organizationId }
          );
          this.applications = applications.data.applications;
        }
        // this.applications = applications.data.applications;

        this.error = this.applications.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    // Close modal
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.users);
      });
    },
    /**
     * Is save button is pressed. Save modification information of users to database
     */
    async save() {
      try {
        const removeUser = await UserTargetService.deleteUserTargets(
          this.editedItem.uuid
        );
        this.error = removeUser.data.error;

        const addUserTargets = await UserTargetService.updatedUserTargets(
          // targetId: this.editedItem.UserTargets,
          // userId: this.editedItem.uuid,
          this.editedItem
        );
        this.error = addUserTargets.data.error;

        if (this.editedItem.userPermissions) {
          let permissions = '';
          let permissionString = '';
          for (let i = 0; i < this.editedItem.userPermissions.length; i++) {
            permissions += this.editedItem.userPermissions[i] + ',';
          }
          permissionString = permissions.slice(0, -1);
          this.editedItem.userPermissions = permissionString;
        }
        const response = await UserService.saveUserModification(
          this.editedItem
        );
        this.message = response.data.message;
        this.error = response.data.error;
        if (this.user.userRoles === 'Pääylläpitäjä') {
          const users = await UserService.getAllUsers();
          this.users = users.data.users;
        } else {
          const users = await UserService.getAllOrganizationUsers({
            organizationId: this.user.Organization.organizationId,
          });
          this.users = users.data.users;
        }
        for (let i = 0; i < this.users.length; i++) {
          this.users[i].userPermissions = this.users[i].userPermissions.split(
            ','
          );
        }
        for (let i = 0; i < this.users.length; i++) {
          let targetItems = [];
          for (let y = 0; y < this.users[i].UserTargets.length; y++) {
            targetItems.push(this.users[i].UserTargets[y].targetId);
          }
          this.users[i].UserTargets = targetItems;
        }

        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
