<template>
  <v-data-table
    :headers="headers"
    :items="questionGroups"
    sort-by="queries"
    class="elevation-1"
    :footer-props="{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Kysymysryhmät</v-toolbar-title>

        <v-spacer />
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Ryhmän nimi"
                      prepend-icon="mdi-information-variant"
                      v-model="questionGroupItems.queryQuestionGroupName"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Ryhmän kuvaus"
                      prepend-icon="mdi-information-variant"
                      v-model="questionGroupItems.queryQuestionGroupDescrption"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="questionGroupItems.queryQuestionGroupState"
                      :items="questionStates"
                      label="Kyselyn tila"
                      placeholder="Valitse"
                      item-text="name"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                Peruuta
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Talleta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import QuestionGroupService from '@/services/Tieto/QuestionGroupService.js';

export default {
  data() {
    return {
      questionGroups: [],
      dialog: false,
      error: null,
      message: null,
      formTitle: 'Muokkaa käyttäjän tietoja',
      headers: [
        { text: 'Ryhmän nimi', value: 'queryQuestionGroupName' },
        { text: 'Ryhmän kuvaus', value: 'queryQuestionGroupDescrption' },
        { text: 'Tila', value: 'queryQuestionGroupState' },
        {
          text: 'Muuta',
          value: 'actions',
          sortable: false,
        },
      ],
      questionStates: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        {
          name: 'Aktiivinen',
          value: 'Aktiivinen',
        },
      ],
      questionGroupItems: {
        queryQuestionGroupName: '',
        queryQuestionGroupDescrption: '',
        queryQuestionGroupState: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const response = await QuestionGroupService.getAllQuestionGroups();
    this.questionGroups = response.data.groups;
  },

  methods: {
    editItem(item) {
      this.questionGroupItems = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.questionGroupItems = Object.assign({}, this.questionGroups);
      });
    },

    async save() {
      try {
        const response = await QuestionGroupService.saveQuestionGroupModification(
          this.questionGroupItems
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const questions = await QuestionGroupService.getAllQuestionGroups();
        this.questionGroups = questions.data.groups;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        }, 5000);
      }
    },
  },
};
</script>
<style></style>
