import Api from '@/services/Api';

//Organization api cals
export default {
  getAllTargets() {
    return Api().get('/api/targets/', {});
  },
  getAllOrganizationTargets(params) {
    return Api().get('/api/organization-targets/', { params });
  },
  getAllUserTargets(params) {
    return Api().get('/api/user-targets/', { params });
  },
  saveTargetModification(credintials) {
    return Api().patch('/api/target/', credintials, {});
  },
  addNewTarget(credintials) {
    return Api().post('/api/target/', credintials, {});
  },
  getTargetsByIds(params) {
    return Api().get('/api/targets-by-id/', { params });
  },
  getTargetsById(params) {
    return Api().get('/api/target-by-id/', { params });
  },
};
