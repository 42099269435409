<template>
  <v-dialog
    :value="show"
    max-width="400"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn
          color="green darken-1"
          text
          @click="$emit('close', false)"
        >
          {{ $t('common.no') }}
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="$emit('close', true)"
        >
          {{ $t('common.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>