import Api from '@/services/Api';

//Application api calls
export default {
  getAllApplications() {
    return Api().get('/api/applications', {});
  },
  getAllOrganizationApplications(params) {
    return Api().get('/api/organization-applications', { params });
  },
};
