<template>
  <v-container>
    <v-card class="card">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="chartdata"
            :options="options"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="organizationInfo.organizationName"
            >
              {{ organizationInfo.organizationName }}
            </p>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationAddress"
            >
              {{ organizationInfo.organizationAddress }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationZipCode"
            >
              {{ organizationInfo.organizationZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="organizationInfo.organizationPostalDistrict"
            >
              {{ organizationInfo.organizationPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card class=" card" v-for="(t, index) in chartData" :key="index">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <doughnut-chart
            :width="300"
            :height="300"
            :chart-data="t"
            :options="chartOptions[index]"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card-text>
            <p
              class="text-h4 text--primary"
              v-if="targetInfo[index].targetName"
            >
              {{ targetInfo[index].targetName }}
            </p>
            <div class="text--primary" v-if="targetInfo[index].targetAddress">
              {{ targetInfo[index].targetAddress }}
            </div>
            <div class="text--primary" v-if="targetInfo[index].targetZipCode">
              {{ targetInfo[index].targetZipCode }}
            </div>
            <div
              class="text--primary"
              v-if="targetInfo[index].targetPostalDistrict"
            >
              {{ targetInfo[index].targetPostalDistrict }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
//import DoughnutChartMaster from './DoughnutChartsMaster.js';
import DoughnutChart from './DoughnutChart.js';
import UserService from '@/services/UserService.js';
import TargetService from '@/services/TargetService.js';
import UserTargetService from '@/services/UserTargetService.js';

export default {
  components: {
    DoughnutChart,
  },
  data: () => ({
    user: {},
    chartdata: null,
    count: 0,
    organizationInfo: {},
    targetInfo: [],
    chartData: [],
    chartOptions: [],
    targets: [],

    options: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      rotation: 40,
      text: '',
      label: 'Kohteet',
    },
  }),

  async mounted() {
    this.user = this.$store.getters.getUser;
    const masterDataSets = [];
    this.organizationInfo.organizationName = this.user.Organization.organizationName;
    this.organizationInfo.organizationAddress = this.user.Organization.organizationAddress;
    this.organizationInfo.organizationZipCode = this.user.Organization.organizationZipCode;
    this.organizationInfo.organizationPostalDistrict = this.user.Organization.organizationPostalDistrict;
    try {
      this.reset();

      const targets = await TargetService.getAllOrganizationTargets({
        targetOrganizationId: this.user.Organization.organizationId,
      });
      let targetData = targets.data.targets;
      this.options.text = targetData.length;
      const users = await UserService.getAllOrganizationUsers({
        organizationId: this.user.Organization.organizationId,
      });
      let userData = users.data.users;

      let u = userData.filter((el) => el.userRoles.includes('Käyttäjä')).length;
      let mainUser = userData.filter((el) =>
        el.userRoles.includes('Pääkäyttäjä')
      ).length;
      let admin = userData.filter((el) => el.userRoles === 'Ylläpitäjä');

      masterDataSets.push({
        label: 'Data One',
        backgroundColor: ['green', 'red', 'yellow'],
        data: [u, mainUser, admin.length],
      });
      this.chartdata = {
        labels: ['Käyttäjät', 'Pääkäytäjät', 'Ylläpitäjät'],
        datasets: masterDataSets,
      };

      //Get all users by targets
      for (let i = 0; i < targetData.length; i++) {
        const userTargets = await UserTargetService.getAllUsersByTargets({
          targetId: targetData[i].targetId,
        });
        let userTargetsData = userTargets.data.targets;
        let u = 0;
        let a = 0;
        let m = 0;
        for (let t = 0; t < userTargetsData.length; t++) {
          if (userTargetsData[t].Users[0].userRoles === 'Käyttäjä') {
            u += 1;
          }
          if (userTargetsData[t].Users[0].userRoles === 'Pääkäyttäjä') {
            m += 1;
          }
          if (userTargetsData[t].Users[0].userRoles === 'Ylläpitäjä') {
            a += 1;
          }
        }
        this.chartOptions.push({
          legend: {
            display: true,
          },
          responsive: true,
          maintainAspectRatio: false,
          rotation: 30,
          text: userTargetsData.length,
          label: 'Käyttäjät',
        });

        this.targetInfo.push({
          targetName: targetData[i].targetName,
          targetAddress: targetData[i].targetAddress,
          targetZipCode: targetData[i].targetZipCode,
          targetPostalDistrict: targetData[i].targetPostalDistrict,
        });
        let data = [];
        data.push(u, m, a);
        this.chartData.push({
          labels: ['Käyttäjät', 'Pääkäyttäjät', 'Ylläpitäjät'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: ['green', 'red', 'yellow'],
              data: data,
            },
          ],
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    reset() {
      this.chartdata = null;
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 50px;
}
</style>
