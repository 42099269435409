<template>
  <div>
    <v-badge
      color="error"
      overlap
      :content="activeAlarmsText"
    >
      <v-icon large> mdi-alert-outline</v-icon>
    </v-badge>
    <!-- <v-btn
      v-show="hasActiveAlarms"
      color="red"
      x-small
      rounded
      depressed
    >
    </v-btn> -->
  </div>
</template>
<script>

export default {
  props: {
    activeAlarms: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeAlarmsText() {
      let text = '-'
      if (!this.activeAlarms.error) {
        text = this.activeAlarms.data.length > 10 ? '10+' : this.activeAlarms.data.length
      }
      return text;
    },
    hasActiveAlarms() {
      return !this.activeAlarms.error && this.activeAlarms.data.length
    }
  }
}
</script>
