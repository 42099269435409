<template>
  <div>
    <v-flex xs12 md12 text-left>
      <!-- <h4>Näytä materiaalipankki tiedostot</h4> -->
    </v-flex>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="showMaterialbankFiles"
      sort-by="Organization"
      class="elevation-1"
      no-data-text="Tietoa ei saatavilla"
      :footer-props="{
        'items-per-page-text': 'Rivejä sivulla',
        'items-per-page-all-text': 'Kaikki',
      }"
    >
      <template v-slot:top>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>

        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h3>Materiaalipankki tiedostot</h3>
            </v-col>
            <v-col cols="4" sm="12" md="4">
              <v-autocomplete
                v-model="userTargets"
                :items="targets"
                item-text="Target.targetName"
                item-value="Target.targetId"
                label="Kohteet"
                placeholder="Valitse..."
                auto-select-first
                @change="changeTargets"
              />
            </v-col>
            <v-col cols="4" sm="12" md="4">
              <v-text-field v-model="search" label="Hae..." class="mx-4" />
            </v-col>
          </v-row>
        </v-container>

        <v-spacer />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="downloadFile(item.file)">
          mdi-download
        </v-icon>
        <v-icon small class="mr-2" @click="showloadFile(item.file)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import MaterialbankService from '@/services/Materialbank/MaterialbankService';
import TargetService from '@/services/Tieto/TargetService';

export default {
  data() {
    return {
      showMaterialbankFiles: [],
      search: '',
      targets: [],
      userTargets: '',
      user: {},
      //organizationId: null,
      error: null,

      headers: [
        {
          text: 'Otsikko',
          value: 'title',
        },
        {
          text: 'Kuvaus',
          value: 'info',
        },

        {
          text: 'Tiedosto',
          value: 'file',
        },
        {
          text: 'Tila',
          value: 'state',
        },
        {
          text: 'Lataa / Katso',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },

  async created() {
    this.user = this.$store.getters.getUser;
    const organizationId = this.$store.state.user.Organization.organizationId;
    const userPermission = this.$store.state.user.userRoles;

    const targets = await TargetService.getAllUserTargets({
      userId: this.user.uuid,
    });

    this.targets = targets.data.targets;

    await this.$store.dispatch(
      'setUserActiveTarget',
      this.targets[0].Target.targetId
    );
    const targetId = this.$store.state.userActiveTarget;
    this.userTargets = targetId;
    const materialbankFiles = await MaterialbankService.getAllMaterialbankFilesByUser(
      {
        organizationId: organizationId,
        permissions: userPermission,
        targetId: targetId,
        appId: '1',
      }
    );
    this.showMaterialbankFiles = materialbankFiles.data.materialbankFiles;
  },

  methods: {
    async changeTargets() {
      const organizationId = this.$store.state.user.Organization.organizationId;
      const userPermission = this.$store.state.user.userRoles;
      const targetId = this.$store.state.userActiveTarget;

      await this.$store.dispatch('setUserActiveTarget', this.userTargets);
      const materialbankFiles = await MaterialbankService.getAllMaterialbankFilesByUser(
        {
          organizationId: organizationId,
          permissions: userPermission,
          targetId: targetId,
          appId: '1',
        }
      );
      this.showMaterialbankFiles = materialbankFiles.data.materialbankFiles;
    },
    async downloadFile(downloadFile) {
      try {
        const response = await MaterialbankService.downloadMaterialbankFile({
          file: downloadFile,
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', downloadFile);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        console.log(error);
      }
    },
    async showloadFile(downloadFile) {
      try {
        const response = await MaterialbankService.downloadMaterialbankFile({
          file: downloadFile,
        });

        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
