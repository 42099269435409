import Api from '@/services/Api';

//Organization api cals
export default {
  acknowledgeAlarm(id) {
    return Api().patch('/api/alarms/ack', { id, state: 1 });
  },
  unacknowledgeAlarm(id) {
    return Api().patch('/api/alarms/ack', { id, state: 0 });
  },
  getActiveAlarms() {
    return Api().get('/api/alarms/active');
  },
  getAllOrganizationAlarms(params) {
    return Api().get('/api/organization-alarms', { params });
  },
};
