import Api from '@/services/Api';

//Organization api cals
export default {
  getAllMaterialbankFiles() {
    return Api().get('/api/materialbank-files/', {});
  },
  getAllMaterialbankFilesByOrganization(params) {
    return Api().get('/api/materialbank-files-organization', { params });
  },
  getMaterialBankFileTargets(params) {
    return Api().get('/api/materialbank-file-targets', { params });
  },
  saveMaterialbankFileModification(credintials) {
    return Api().patch('/api/materialbank-file', credintials, {});
  },
  addNewMaterialbankFile(credintials) {
    return Api().post('/api/materialbank-file/', credintials, {});
  },
  deleteMaterialbankFile(id) {
    return Api().delete('/api/materialbank-file/', {
      data: { id: id },
    });
  },
  downloadMaterialbankFile(params) {
    return Api().get('/api/materialbank-get-file', {
      params,
      responseType: 'blob',
    });
  },
  removeMaterialbankFileModification(credintials) {
    return Api().patch('/api/materialbank-data-remove', credintials, {});
  },
  getAllMaterialbankFilesByUser(params) {
    return Api().get('/api/materialbank-files-by-user', { params });
  },
  getCountFilesByOrganization(params) {
    return Api().get('/api/materialbank-pdf-files', { params });
  },
  getCountAllTargetsByOrganization(params) {
    return Api().get('/api/materialbank-count-targets', { params });
  },
};
