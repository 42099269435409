var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.showAlarms,"sort-by":"showAlarms","no-data-text":"Tietoa ei saatavilla","footer-props":{
      'items-per-page-text': 'Rivejä sivulla',
      'items-per-page-all-text': 'Kaikki',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.error)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.message)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-autocomplete',{staticClass:"flex-grow-0 flex-shrink-0 ml-4",staticStyle:{"width":"300px"},attrs:{"multiple":"","items":_vm.targetNames,"value":_vm.selectedTargets,"return-object":true,"label":"Valitse kohde","no-data-text":"Tietoa ei saatavilla"},on:{"input":_vm.onTargetChange}}),_c('v-switch',{staticClass:"ml-4",attrs:{"input-value":_vm.$route.query.st,"label":"Kuittaamattomat"},on:{"change":_vm.onStateFilterChange}}),_c('v-switch',{staticClass:"ml-4",attrs:{"input-value":_vm.$route.query.ac,"label":"Aktiiviset"},on:{"change":_vm.onActiveFilterChange}})],1),_c('date-range-picker',{attrs:{"start-date":_vm.$route.query.sd || '',"end-date":_vm.$route.query.ed || ''},on:{"change":_vm.onDateRangeChange}})],1)]},proxy:true},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [(!item.state)?_c('v-btn',{attrs:{"small":"","color":"general"},on:{"click":function($event){return _vm.acknowledgeAlarm(item)}}},[_vm._v("Kuittaa")]):_c('v-btn',{attrs:{"color":"general","small":"","outlined":""},on:{"click":function($event){return _vm.onUnacknowledgeAlarmClick(item)}}},[_vm._v("Kuitattu")])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.parseAlarmDate(item.createdAt)))])]}},{key:"item.alarmBreakThrough",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.alarmEventName(item.alarmBreakThrough)))])]}}],null,true)}),_c('confirm-dialog',{attrs:{"show":!!_vm.unacknowledgeAlarmId,"title":_vm.$t('showAlarms.unacknowledgeAlarm'),"text":_vm.$t('showAlarms.unacknowledgeAlarmText')},on:{"close":_vm.onUnacknowledgeAlarmClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }