<template>
  <div>
    <v-flex xs12 md12 text-left>
      <!-- <h4>Näytä materiaalipankki tiedostot</h4> -->
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="showMaterialbankFiles"
      sort-by="Organization"
      class="elevation-1"
      :search="search"
      no-data-text="Tietoa ei saatavilla"
      :footer-props="{
        'items-per-page-text': 'Rivejä sivulla',
        'items-per-page-all-text': 'Kaikki',
      }"
    >
      <template v-slot:top>
        <v-text-field v-model="search" label="Hae..." class="mx-4" />
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-toolbar flat>
          <v-toolbar-title>Materialipankki tiedostot</v-toolbar-title>

          <v-spacer />
          <v-dialog v-model="dialog" max-width="750px">
            <v-card>
              <v-card-title>
                <span class="headline">Muokkaa tietoja</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="Otsikko"
                        prepend-icon="mdi-account"
                        v-model="editMaterialbankFile.title"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-supervisor"
                        v-model="editMaterialbankFile.organizationId"
                        :items="organizations"
                        label="Organisaatio"
                        placeholder="Organisaatio"
                        item-text="organizationName"
                        item-value="organizationId"
                        chips
                        @change="showTargets"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-supervisor"
                        v-model="editMaterialbankFile.MaterialbankTargets"
                        :items="targets"
                        label="Kohteet"
                        placeholder="Kohteet"
                        item-text="targetName"
                        item-value="targetId"
                        chips
                        multiple
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-supervisor"
                        v-model="editMaterialbankFile.MaterialbankPermissions"
                        :items="permissions"
                        label="Oikeudet"
                        placeholder="Oikeudet"
                        item-text="name"
                        item-value="permission"
                        chips
                        multiple
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-supervisor"
                        v-model="editMaterialbankFile.MaterialbankApplications"
                        :items="applications"
                        label="Sovellukset"
                        placeholder="Sovellukset"
                        item-text="Application.applicationName"
                        item-value="applicationId"
                        chips
                        multiple
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        prepend-icon="mdi-account-supervisor"
                        v-model="editMaterialbankFile.state"
                        :items="states"
                        label="Keskustelun tila"
                        placeholder="Valitse..."
                        item-text="name"
                        item-value="value"
                        chips
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editMaterialbankFile.info"
                        label="Info"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close">
                  Peruuta
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Talleta
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="user.userRoles !== 'Käyttäjä'"
        >
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="downloadFile(item.file)">
          mdi-download
        </v-icon>
        <v-icon small class="mr-2" @click="showloadFile(item.file)">
          mdi-eye
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
          v-if="
            user.userRoles === 'Pääylläpitäjä' ||
              user.userRoles === 'Ylläpitäjä'
          "
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import MaterialbankService from '@/services/Materialbank/MaterialbankService';
import OrganizationService from '@/services/OrganizationService.js';
import TargetService from '@/services/TargetService';
import ApplicationService from '@/services/ApplicationService';
export default {
  data() {
    return {
      search: '',
      editMaterialbankFile: {},
      showMaterialbankFiles: [],
      organizations: [],
      targets: [],
      applications: [],
      oldMaterialbankFiles: {},
      user: {},
      dialog: false,
      message: null,
      error: null,
      required: [(v) => !!v || 'Pakollinen kenttä'],
      fileRules: [(v) => !!v || 'Tiedosto pitää valita'],
      permissions: [
        { name: 'Käyttäjä', value: 'Käyttäjä' },
        { name: 'Pääkäyttäjä', value: 'Pääkäyttäjä' },
        { name: 'Ylläpitäjä', value: 'Ylläpitäjä' },
        { name: 'Pääylläpitäjä', value: 'Pääylläpitäjä' },
      ],
      states: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        {
          name: 'Passiivinen',
          value: 'Passiivinen',
        },
      ],
      headers: [
        {
          text: 'Otsikko',
          value: 'title',
        },
        {
          text: 'Organisaatio',
          value: 'Organization.organizationName',
        },
        // {
        //   text: 'Kohteet',
        //   value: 'Target.targetName',
        // },
        // {
        //   text: 'Oikeudet',
        //   value: 'permissions',
        // },
        {
          text: 'Tiedosto',
          value: 'file',
        },
        {
          text: 'Tila',
          value: 'state',
        },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  async created() {
    this.user = this.$store.getters.getUser;
    if (this.user.userRoles === 'Pääylläpitäjä') {
      const materialbankFiles = await MaterialbankService.getAllMaterialbankFiles();
      this.showMaterialbankFiles = materialbankFiles.data.materialbankFile;
    } else {
      const materialbankFiles = await MaterialbankService.getAllMaterialbankFilesByOrganization(
        {
          organizationId: this.user.Organization.organizationId,
        }
      );
      this.showMaterialbankFiles = materialbankFiles.data.materialbankFile;
    }
  },
  methods: {
    async editItem(item) {
      this.editMaterialbankFile = Object.assign({}, item);
      this.oldMaterialbankFiles = this.editMaterialbankFile;

      const organizations = await OrganizationService.getAllOrganizations();
      this.organizations = organizations.data.organizations;
      const organizationTargets = await TargetService.getAllOrganizationTargets(
        {
          targetOrganizationId: this.editMaterialbankFile.organizationId,
        }
      );
      this.targets = organizationTargets.data.targets;

      const applications = await ApplicationService.getAllOrganizationApplications(
        {
          organizationId: item.Organization.organizationId,
        }
      );
      this.applications = applications.data.applications;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editMaterialbankFile = Object.assign(
          {},
          this.showMaterialbankFiles
        );
      });
    },

    async deleteItem(item) {
      try {
        let targetArr = [];
        let applicationArr = [];
        let permissionArr = [];
        if (item.MaterialbankTargets[0].id) {
          for (let i = 0; i < item.MaterialbankTargets.length; i++) {
            targetArr.push(item.MaterialbankTargets[i].targetId);
          }
          item.MaterialbankTargets = targetArr;
        }
        if (item.MaterialbankPermissions[0].id) {
          for (let i = 0; i < item.MaterialbankPermissions.length; i++) {
            permissionArr.push(item.MaterialbankPermissions[i].permission);
          }
          item.MaterialbankPermissions = permissionArr;
        }
        if (item.MaterialbankApplications[0].id) {
          for (let i = 0; i < item.MaterialbankApplications.length; i++) {
            applicationArr.push(item.MaterialbankApplications[i].applicationId);
          }
          item.MaterialbankApplications = applicationArr;
        }

        const deleteRelations = await MaterialbankService.removeMaterialbankFileModification(
          item
        );
        this.error = deleteRelations.data.errro;

        const deleteFile = await MaterialbankService.deleteMaterialbankFile(
          item.id
        );
        this.message = deleteFile.message;
        this.error = deleteFile.error;
        const materialbankFiles = await MaterialbankService.getAllMaterialbankFiles();
        this.showMaterialbankFiles = materialbankFiles.data.materialbankFile;
        this.close();
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 30000);
      }
    },

    async downloadFile(downloadFile) {
      try {
        const response = await MaterialbankService.downloadMaterialbankFile({
          file: downloadFile,
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', downloadFile);
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        console.log(error);
      }
    },
    async showloadFile(downloadFile) {
      try {
        const response = await MaterialbankService.downloadMaterialbankFile({
          file: downloadFile,
        });

        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.log(error);
      }
    },

    async showTargets() {
      try {
        const response = await TargetService.getAllOrganizationTargets({
          targetOrganizationId: this.editMaterialbankFile.organizationId,
        });
        this.targets = response.data.targets;
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    async save() {
      try {
        let targetArr = [];
        let applicationArr = [];
        let permissionArr = [];
        if (this.editMaterialbankFile.MaterialbankTargets[0].id) {
          for (
            let i = 0;
            i < this.editMaterialbankFile.MaterialbankTargets.length;
            i++
          ) {
            targetArr.push(
              this.editMaterialbankFile.MaterialbankTargets[i].targetId
            );
          }
          this.editMaterialbankFile.MaterialbankTargets = targetArr;
        }
        if (this.editMaterialbankFile.MaterialbankPermissions[0].id) {
          for (
            let i = 0;
            i < this.editMaterialbankFile.MaterialbankPermissions.length;
            i++
          ) {
            permissionArr.push(
              this.editMaterialbankFile.MaterialbankPermissions[i].permission
            );
          }
          this.editMaterialbankFile.MaterialbankPermissions = permissionArr;
        }
        if (this.editMaterialbankFile.MaterialbankApplications[0].id) {
          for (
            let i = 0;
            i < this.editMaterialbankFile.MaterialbankApplications.length;
            i++
          ) {
            applicationArr.push(
              this.editMaterialbankFile.MaterialbankApplications[i]
                .applicationId
            );
          }
          this.editMaterialbankFile.MaterialbankApplications = applicationArr;
        }
        const removeOldValues = await MaterialbankService.removeMaterialbankFileModification(
          this.oldMaterialbankFiles
        );
        this.error = removeOldValues.error;
        this.message = removeOldValues.data.response;
        const updateMaterialBank = await MaterialbankService.saveMaterialbankFileModification(
          this.editMaterialbankFile
        );
        this.message = updateMaterialBank.data.message;
        this.error = updateMaterialBank.error;
        const materialbankFiles = await MaterialbankService.getAllMaterialbankFiles();
        this.showMaterialbankFiles = materialbankFiles.data.materialbankFile;
        this.close();
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 30000);
      }
    },
  },
};
</script>
