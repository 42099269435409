import { Line, mixins } from 'vue-chartjs'
import chartjsPluginAnnotation from'chartjs-plugin-annotation';

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.addPlugin(chartjsPluginAnnotation)
    this.renderChart(this.chartData, {
      ...this.options,
      annotation: Object.assign({}, this.options.annotation)
    })
  }
}