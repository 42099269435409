<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            v-model="userTargets"
            :items="targets"
            item-text="Target.targetName"
            item-value="Target.targetId"
            label="Valitse kohde:"
            class="mt-1"
            :props="{
              color: '#ffffff',
            }"
            @change="changeTargets"
          />
          <!-- <h3 v-else>{{ targets[0].Target.targetName }}</h3> -->
        </v-col>
      </v-row>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
            <v-card-text>
              <h3>
                <p>Tervetuloa keskustelemaan!</p>
                <p>
                  Keskustelut ovat osa interaktiivista tietojen ja ajatusten
                  vaihtoa.
                </p>
                <p>
                  Osallistujat voivat vapaasti kertoa asianmukaisen
                  mielipiteensä ja tutustua muiden kommentteihin. Keskusteluja
                  moderoidaan, jotta ne pysyvät sekä aiheessa että asiallisena.
                  Omassa viestinnässään on syytä kiinnittää huomiota
                  asiallisuuteen ja loukkaamattomuuteen sekä kaikkien
                  mielipiteiden kunnioittamiseen.
                </p>
              </h3>
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-container>
    <v-container>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>

      <v-row dense v-if="discussions">
        <v-col
          cols="12"
          v-for="(discussion, index) in discussions"
          :key="index"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="mb-4 pa-2">
                <v-card-text>
                  <div>
                    <h2>{{ discussion.discussionTitle }}</h2>
                  </div>

                  <div class="text--primary">
                    <h3>{{ discussion.discussionDescription }}</h3>
                    <span class="subtitle-2 ">{{
                      showDateAndTime(discussion.createdAt)
                    }}</span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="#45a1e6"
                    @click="showDiscussion(discussion.discussionId)"
                  >
                    Liity keskusteluun
                  </v-btn>
                  <v-row align="center" justify="end" class="mr-5">
                    <v-btn
                      text
                      color="#45a1e6"
                      @click="showDiscussion(discussion.discussionId)"
                    >
                      {{ `Kommentit (` + discussion.comments + `)` }}</v-btn
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12"> <h2>Ei avoimia keskusteluja</h2></v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import DiscussionService from '@/services/Tieto/DiscussionService.js';
import UserTargetService from '@/services/UserTargetService.js';
import moment from 'moment';
export default {
  data() {
    return {
      discussions: null,
      message: null,
      error: null,
      target: {},
      data: [],
      targets: [],
      userTargets: '',
    };
  },

  async mounted() {
    this.userTargets = this.$store.getters.getUserActiveTarget;
    this.user = this.$store.getters.getUser;

    try {
      const discussions = await DiscussionService.getAllDiscussionsByOrganization(
        {
          organizationId: this.$store.state.user.Organization.organizationId,
          targetId: this.$store.getters.getUserActiveTarget,
        }
      );
      this.discussions = discussions.data.discussions;
      this.message = discussions.data.message;
      this.error = discussions.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.discussions.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
    try {
      const targets = await UserTargetService.getAllUserTargets({
        userId: this.user.uuid,
      });
      this.targets = targets.data.targets;
      this.message = targets.data.message;
      this.error = targets.error;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.targets.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },
  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY HH:mm');
    },
    async changeTargets() {
      try {
        const discussions = await DiscussionService.getAllDiscussionsByOrganization(
          {
            organizationId: this.$store.state.user.Organization.organizationId,
            targetId: this.userTargets,
          }
        );
        this.discussions = discussions.data.discussions;
        this.message = discussions.data.message;
        this.error = discussions.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.discussions.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    showDiscussion(discussionId) {
      this.$store.dispatch('setDiscussionId', discussionId);
      this.$router.push({
        name: 'tieto/discussions',
      });
    },
  },
};
</script>
